import React from "react";

import { cn } from "@/lib/cn";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const BodyLayout = ({ children, className }: Props) => {
  return (
    <div
      className={cn(
        "mx-auto flex max-w-5xl flex-col items-center py-12",
        className,
      )}
    >
      {children}
    </div>
  );
};
