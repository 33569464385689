export function formatDateToLocal(utcString: string, useHours = false) {
  const utcDate = new Date(utcString);

  // Convert UTC date to local date
  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
  );

  // Format the local date as a readable string
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: useHours ? "numeric" : undefined,
  };
  const readableLocalDate = localDate.toLocaleString(undefined, options);
  return readableLocalDate;
}
