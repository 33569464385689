import { cn } from "@/lib/cn";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export function MonoText({ className, children, ...props }: Props) {
  return (
    <div
      className={cn(
        "rounded bg-muted px-4 py-2.5 font-mono tabular-nums text-muted-foreground",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
