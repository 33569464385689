import { useEffect, useMemo } from "react";
import { ThumbsUp } from "lucide-react";
import { useCopyToClipboard } from "react-use";
import { toast } from "sonner";

import { Icons } from "@/components/Icons";
import { Spinner } from "@/components/Spinner";
import {
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
} from "@/components/ui/context-menu";
import { User } from "@/gql/graphql";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { LABELS_COL_ID, PredictionRowType } from "../PredictionTableColumns";
import {
  PredictionsTableActions,
  usePredictionsTableStore,
} from "../predictionTableStore";

const getLabelsFromRows = ({
  labelName,
  _rows,
}: {
  labelName: string;
  _rows: ReturnType<PredictionsTableActions["getRows"]>;
}) => {
  const labels: PredictionRowType["labels"][number][] = [];

  _rows.forEach((row) => {
    const prediction = row.original;
    const label = prediction.labels.find((label) => label.name === labelName);
    if (label) {
      labels.push(label);
    }
  });

  return labels;
};

const getSelectedLabels = ({
  labelName,
  _selectedRows,
}: {
  labelName: string;
  _selectedRows: ReturnType<PredictionsTableActions["getSelectedRows"]>;
}) => {
  return getLabelsFromRows({
    labelName,
    _rows: _selectedRows,
  });
};

export function PredictionContextMenuContents() {
  const { table, getSelectedRows, onSubmitFeedback, unselectAllRows } =
    usePredictionsTableStore();
  const { data: userData } = useCurrentUserQuery();
  const [state, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (state.error) {
      toast.error("Failed to copy to clipboard");
    } else if (state.value) {
      toast.success("Copied to clipboard");
    }
  }, [state]);

  const user = userData?.user;
  const labelColumns = useMemo(() => {
    return (
      table?.getAllColumns().find((col) => col.id === LABELS_COL_ID)?.columns ||
      []
    );
  }, [table]);
  const selectedRows = getSelectedRows();
  const someRowsSelected = selectedRows.length > 0;

  if (!table || !user || !onSubmitFeedback) {
    return (
      <ContextMenuItem>
        <Spinner />
      </ContextMenuItem>
    );
  }

  const onConfirmSelectedRowsCol = ({
    colId,
    _selectedRows,
    userId,
  }: {
    colId: string;
    _selectedRows: ReturnType<PredictionsTableActions["getSelectedRows"]>;
    userId: User["id"];
  }) => {
    const selectedLabels = getSelectedLabels({
      labelName: colId,
      _selectedRows,
    });
    const feedback = selectedLabels.map((label) => {
      return {
        labelId: label.id,
        content: label.value,
        upvote: true,
        userId,
      };
    });

    onSubmitFeedback(feedback);
    unselectAllRows();
  };

  // const onEditSelectedRowsCol = ({
  //   colId,
  //   content,
  //   _selectedRows,
  //   userId,
  // }: {
  //   colId: string;
  //   content: string;
  //   _selectedRows: typeof selectedRows;
  //   userId: User["id"];
  // }) => {
  //   const selectedLabels = getSelectedLabels({
  //     labelName: colId,
  //     _selectedRows,
  //   });
  //   const feedback = selectedLabels.map((label) => {
  //     return {
  //       labelId: label.id,
  //       content,
  //       upvote: false,
  //       userId,
  //     };
  //   });

  //   table.toggleAllRowsSelected(false);
  //   onSubmitFeedback(feedback);
  // };

  return (
    <>
      <ContextMenuLabel className="">
        <span className="ml-2 tabular-nums text-foreground">
          {selectedRows.length} selected
        </span>
      </ContextMenuLabel>

      <ContextMenuSeparator />
      <ContextMenuLabel className="text-muted-foreground">
        Edit
      </ContextMenuLabel>
      {/* <ContextMenuItem disabled>
        <span className="mr-2">Edit Values</span>
        <span>
          ({selectedRows.length} row
          {selectedRows.length !== 1 && "s"})
        </span>
      </ContextMenuItem> */}
      {someRowsSelected && (
        <>
          {labelColumns.map((col) => {
            return (
              <ContextMenuSub key={col.id}>
                <ContextMenuSubTrigger>{col.id}</ContextMenuSubTrigger>
                <ContextMenuSubContent>
                  <ContextMenuItem
                    inset
                    onClick={() => {
                      onConfirmSelectedRowsCol({
                        colId: col.id,
                        _selectedRows: selectedRows,
                        userId: user.id,
                      });
                    }}
                  >
                    <ThumbsUp className="mb-0.5 mr-2 h-4 w-4" />
                    Confirm All
                  </ContextMenuItem>
                  <ContextMenuSeparator />
                  <div className="whitespace-nowrap">Search {col.id}...</div>
                </ContextMenuSubContent>
              </ContextMenuSub>
            );
          })}
        </>
      )}
      <ContextMenuSeparator />

      <ContextMenuItem
        className="cursor-pointer gap-2"
        onClick={() => {
          const content = selectedRows.map((row) => {
            const rowObj: Record<string, unknown> = {};

            const rowCells = row.getVisibleCells().filter((cell) => {
              return cell.column.columnDef.id !== "Select";
            });

            rowCells.forEach((cell) => {
              const header = cell.getContext().column.columnDef.id;
              const value = cell.getValue();
              if (header !== undefined && value !== undefined) {
                rowObj[header] = cell.getValue();
              }
            });

            return rowObj;
          });

          copyToClipboard(JSON.stringify(content));
        }}
      >
        <ContextMenuShortcut className="ml-0">
          <Icons.copy className=" h-4 w-4" />
        </ContextMenuShortcut>
        Copy row
        {selectedRows.length !== 1 && "s"}
      </ContextMenuItem>
    </>
  );
}
