import { useState } from "react";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { cn } from "@/lib/cn";

import { BodyLayout } from "../submission/:submissionId/BodyLayout";
import { Header } from "../submission/:submissionId/Header";
import { VendorSubmissionFlowTabularHeader } from "../VendorSubmissionFlowTabularHeader";
import { SubmissionTypeUploadGrid } from "./SubmissionTypeUploadGrid";
import { VendorUploadToggleTables } from "./VendorUploadToggleTables";

export const VendorUploadPage2 = () => {
  const [showTypes, setShowTypes] = useState(true);

  return (
    <DashboardScreen className="max-h-screen min-h-screen">
      <VendorSubmissionFlowTabularHeader />
      <BodyLayout>
        <Header
          title="Quantix Microsystems Submissions Portal"
          description="Upload your inventory or spreadsheet files for processing and our advanced AI and validation tools will handle the rest. Simply drag and drop your files to get started."
        />
        <div className="flex w-full flex-col items-center pt-6 md:px-16">
          <div className="py-6">
            <HoverBorderGradient
              as="button"
              containerClassName=""
              onClick={() => setShowTypes(!showTypes)}
              className={cn(
                "min-w-[250px] hover:bg-accent hover:text-accent-foreground",
              )}
            >
              {showTypes
                ? "View In Progress and Past Submissions"
                : "Create New Submission"}
            </HoverBorderGradient>
          </div>

          {showTypes ? (
            <div className="pt-6">
              <SubmissionTypeUploadGrid />
            </div>
          ) : (
            <div className="w-full pt-6">
              <VendorUploadToggleTables />
            </div>
          )}
        </div>
      </BodyLayout>
    </DashboardScreen>
  );
};
