import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SubmissionStatus } from "@/gql/graphql";

import { SubmissionsTable } from "./SubmissionsTable";

export function VendorUploadToggleTables() {
  return (
    <Tabs defaultValue="In Progress" className="">
      <TabsList className="w-52">
        <TabsTrigger value="In Progress">In Progress</TabsTrigger>
        <TabsTrigger value="Submitted">Submitted</TabsTrigger>
      </TabsList>
      <TabsContent value="In Progress">
        <SubmissionsTable
          validStatuses={[
            SubmissionStatus.AwaitingPreprocessing,
            SubmissionStatus.Pending,
            SubmissionStatus.Processing,
            SubmissionStatus.Processed,
            SubmissionStatus.Failed,
          ]}
        />
      </TabsContent>
      <TabsContent value="Submitted">
        <SubmissionsTable
          validStatuses={[
            SubmissionStatus.VendorApproved,
            SubmissionStatus.OrgReview,
            SubmissionStatus.OrgRejected,
            SubmissionStatus.OrgApproved,
            SubmissionStatus.Delivered,
          ]}
        />
      </TabsContent>
    </Tabs>
  );
}
