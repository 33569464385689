type Props = {
  header: string | React.ReactNode;
  subHeader: string | React.ReactNode;
};

export const DocumentExtractHeader = ({ header, subHeader }: Props) => {
  return (
    <div className="mx-auto max-w-2xl text-center">
      <h1 className="text-3xl font-bold tracking-tight  sm:text-6xl">
        {header}
      </h1>
      <p className="text-md mt-3 text-primary/60 sm:mt-6 sm:text-lg sm:leading-8">
        {subHeader}
      </p>
    </div>
  );
};
