import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/cn";

type Props = { tableTitle: string };

export const SubmissionListHeaderSkeleton = ({ tableTitle }: Props) => {
  return (
    <CardHeader className="flex flex-row items-center justify-between">
      <CardTitle className="">{tableTitle}</CardTitle>
      <div className="flex items-center gap-2">
        <Button disabled variant="outline" size="sm">
          Prev
        </Button>
        <Button disabled variant="outline" size="sm">
          {"Next"}
        </Button>
        <Button
          disabled
          className={cn("w-fit px-2 ", "text-muted-foreground")}
          variant="outline"
          size="sm"
        >
          <Icons.filter className="h-5 w-5" />
        </Button>
        <Button
          disabled
          className="w-fit px-2 text-muted-foreground"
          variant="outline"
          size="sm"
        >
          <Icons.plus className="h-5 w-5" />
        </Button>
      </div>
    </CardHeader>
  );
};
