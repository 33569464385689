import { useParams } from "react-router-dom";

import { BackButton } from "@/components/BackButton";
import { Icons } from "@/components/Icons";

import { useGetVendorSummaryHeaderData } from "./useGetVendorSummaryHeaderData";

export const VendorSummaryHeader = () => {
  const { vendorId } = useParams();
  const { data } = useGetVendorSummaryHeaderData({
    vendorId: Number(vendorId),
  });
  const vendor = data?.vendor;

  return (
    <header className="flex w-full flex-row justify-between">
      <div className="flex flex-row items-center gap-2">
        <Icons.vendor className="mr-2 h-9 w-9" />
        <div>
          <h2 className="text-xl font-semibold">{vendor?.name}</h2>
          <h3 className="text-sm font-light">ID: {vendor?.vendorId}</h3>
        </div>
      </div>
      <BackButton relative="history" />
    </header>
  );
};
