import { RelativeRoutingType, useNavigate } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";

interface BackButtonProps extends React.ComponentProps<typeof Button> {
  relative?: RelativeRoutingType | "history";
}

export function BackButton({ relative = "path", ...props }: BackButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      variant="outline"
      onClick={() => {
        return relative === "history"
          ? navigate(-1)
          : navigate("..", { relative });
      }}
      {...props}
    >
      {props.children ? (
        // e.g. "Cancel"
        props.children
      ) : (
        <>
          Back
          <Icons.back className="ml-2 h-4" />
        </>
      )}
    </Button>
  );
}
