import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { TransformationTypeListQuery } from "@/gql/graphql";

export const TransformationTypeItem: React.FC<{
  transformationType: TransformationTypeListQuery["transformationTypes"][number];
}> = ({ transformationType }) => {
  return (
    <Card className="max-w-3xl">
      <CardContent className="px-5 py-4">
        <div className="flex items-start justify-between">
          <div className="flex gap-6">
            <div>
              <h2 className="text-md font-semibold">
                {transformationType.name}
              </h2>
              <p className="mt-1 text-xs text-muted-foreground">
                <span className="mr-1 font-semibold">
                  {transformationType.submissionTypes.length}
                </span>
                submission
                {transformationType.submissionTypes.length === 1
                  ? ""
                  : "s"}{" "}
                using
              </p>
              {/* <p className="mt-2 text-sm text-muted-foreground">
                {transformationType.description}
              </p> */}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-xs text-muted-foreground">
              Updated
              <span className="ml-1">{transformationType.updatedAt}</span>
            </p>
            {/* <Link
              to={`transformation-type/${transformationType.id}`}
              className={cn(
                buttonVariants({ variant: "outline" }),
                "h-10 w-10 rounded-full p-1 text-foreground/80",
              )}
            >
              <Icons.edit className="h-4" />
            </Link> */}
            <Button
              variant="outline"
              className="h-10 w-10 rounded-full p-1 text-foreground/80"
              disabled
            >
              <Icons.edit className="h-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const TransformationTypeItemSkeleton: React.FC = () => {
  return (
    <Card className="max-w-3xl animate-pulse">
      <CardContent className="px-5 py-4">
        <div className="flex items-start justify-between">
          <div className="flex gap-6">
            <div className="h-12 w-12 rounded-full bg-muted" />
            <div className="flex flex-col gap-1">
              <div className="h-4 w-32 rounded bg-muted" />
              <div className="h-3 w-20 rounded bg-muted" />
              <div className="h-3 w-24 rounded bg-muted" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-4 w-16 rounded bg-muted" />
            <div className="h-10 w-10 rounded-full bg-muted" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
