import { graphql } from "@/gql/gql";
import { OrganizationWhereUniqueInput } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

export const useOrganizationQuery = ({ id }: OrganizationWhereUniqueInput) => {
  return useGraphqlQuery({
    queryKey: ["organization", `organizationId=${id}`],
    query: graphql(`
      query Organization($where: OrganizationWhereUniqueInput!) {
        organization(where: $where) {
          id
          name
        }
      }
    `),
    variables: { where: { id } },
  });
};
