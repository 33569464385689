import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useCopyToClipboard } from "react-use";

import { cn } from "@/lib/cn";
import { easeSnappy } from "@/lib/motion";

import { Icons } from "./Icons";
import { Button, ButtonProps } from "./ui/button";

interface Props extends Omit<ButtonProps, "onClick"> {
  value: string;
  onCopyToCliboard?: (value: string) => void;
}

export function CopyToClipboard({
  value,
  children,
  className,
  onCopyToCliboard,
  ...props
}: Props) {
  const [_, copyToClipboard] = useCopyToClipboard();
  const [iconState, setIconState] = useState<"idle" | "copied">("idle");
  const [parent] = useAutoAnimate({
    easing: easeSnappy,
    duration: 100,
  });

  const handleCopyClick = () => {
    copyToClipboard(value);
    setIconState("copied");
    setTimeout(() => {
      setIconState("idle");
    }, 2000);
    onCopyToCliboard?.(value);
  };

  return (
    <Button
      onClick={handleCopyClick}
      ref={parent}
      size="icon"
      variant="outline"
      className={cn("h-8 w-8", className)}
      {...props}
    >
      {children ?? iconState === "copied" ? (
        <Icons.check className="h-4 w-4 shrink-0" />
      ) : (
        <Icons.copy className="h-4 w-4 shrink-0" />
      )}
    </Button>
  );
}
