import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Handle, Position } from "reactflow";

import { useHeaderMappingStore } from "@/components/header-mapping/HeaderMappingStore";
import {
  headerHandleStyle,
  HeaderNode,
  HeaderNodeProps,
} from "@/components/header-mapping/nodes/HeaderNode";
import { cn } from "@/lib/cn";

export const OUTPUT_HEADER_NODE_TYPE = "outputHeader";

export interface OutputHeaderNodeProps extends HeaderNodeProps {}

export function OutputHeaderNode({
  id,
  data,
  ...props
}: OutputHeaderNodeProps) {
  const { edges } = useHeaderMappingStore();
  const [parent] = useAutoAnimate(/* optional config */);

  //   find the edge with the target id
  const relevantSources = edges
    .filter((edge) => edge.target === id)
    .map((edge) => edge.source);

  const { inputNodes } = useHeaderMappingStore();

  const sourceExamples = inputNodes
    .filter((node) => {
      if (!node.id) return false;
      return relevantSources.includes(node.id);
    })
    .map(({ example, id }) => ({
      example,
      id,
    }));

  return (
    <HeaderNode data={data} id={id} {...props}>
      <Handle
        type="target"
        position={Position.Top}
        id="a"
        isConnectableStart={false}
        isConnectableEnd
        style={headerHandleStyle}
      />

      <div
        className="overflow-hidden transition-all duration-100"
        style={{
          maxHeight:
            sourceExamples.length > 0
              ? `${sourceExamples.length * 16 + 14}px !important`
              : "auto",
        }}
      >
        <ul
          className={cn(
            "border-t border-muted/20 p-2 text-muted-foreground",
            sourceExamples.length > 0 && "list-inside list-disc",
          )}
          ref={parent}
        >
          {sourceExamples.length > 0
            ? sourceExamples.map((example, index) => (
                <li key={`${id}-${example.id}`}>
                  {example.example || "No data"}
                </li>
              ))
            : "NULL"}
        </ul>
      </div>
    </HeaderNode>
  );
}
