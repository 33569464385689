import { SubmissionsKPMs } from "@/app/dashboard/submissions/SubmissionsKPMs";
import { PageHeader } from "@/components/PageHeader";

import { DashboardScreen } from "../DashboardScreen";
import { SubmissionsList } from "../home/(submissions-list)/SubmissionsList";

export function SubmissionsPage() {
  const outerPadding = 24;
  const gridGap = 16;
  const headerHeight = 36;
  const cardHeight = 142;

  const maxHeight = `calc(100vh - ${outerPadding * 2}px - ${
    gridGap * 2
  }px - ${headerHeight}px - ${cardHeight}px)`;

  return (
    <DashboardScreen
      className="grid h-full grid-rows-[auto_auto_1fr] gap-4"
      scrollable
    >
      <PageHeader title={"Submissions"} />
      <SubmissionsKPMs />
      <SubmissionsList
        maxHeight={maxHeight}
        className=""
        colsShown={{
          Download: true,
          Type: true,
          Partner: true,
          Uploaded: true,
          Status: true,
          "Input File": true,
        }}
      />
    </DashboardScreen>
  );
}
