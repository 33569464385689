import { WithSuspenseAndErrorBoundary } from "@/components/WithSuspenseAndErrorBoundary";
import { SubmissionStatus } from "@/gql/graphql";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { DashboardScreen } from "../DashboardScreen";
import { DashboardKPMs } from "./(KPMs)/DashboardKPMs";
import { DashboardSubmissionsChart } from "./(submission-chart)/DashboardSubmissionsChart";
import { SubmissionsList } from "./(submissions-list)/SubmissionsList";
import { HomeHeader } from "./DashHomeHeader";
import { useDashHomeStore } from "./dashHomeStore";

function _DashHomePage() {
  useDashHomeStore();

  // This is just to cache the user data (will move to a route loader later)
  // @ts-ignore
  const { data } = useCurrentUserQuery();

  const outerPadding = 24;
  const gridGap = 16;
  const headerSize = 64;
  const headerCard = 142;

  const extraHeight = outerPadding * 4 + gridGap + headerSize + headerCard;
  const maxHeight = `calc(100vh - ${extraHeight}px)`;

  return (
    <DashboardScreen scrollable={true} className="h-screen">
      <div className="grid h-full grid-rows-[auto_1fr]">
        <HomeHeader />

        <section className="grid w-full grid-rows-[auto_1fr] gap-4 pt-6">
          <DashboardKPMs className="" />

          <div className="grid grid-cols-2 gap-4">
            <DashboardSubmissionsChart />
            <SubmissionsList
              tableTitle="Submissions To Review"
              maxHeight={maxHeight}
              colsShown={{
                download: false,
                Partner: true,
                Uploaded: true,
                "Time to Complete": true,
              }}
              submissionStatus={SubmissionStatus.Processed}
            />
          </div>
        </section>
      </div>
    </DashboardScreen>
  );
}

export const DashHomePage = WithSuspenseAndErrorBoundary({
  Component: _DashHomePage,
});
