import { DashboardScreen } from "@/app/dashboard/DashboardScreen";

import { ExportDatabaseList } from "./ExportDatabaseList";

export function ExportPage() {
  return (
    <DashboardScreen className="flex flex-col gap-6" scrollable>
      <ExportDatabaseList />
    </DashboardScreen>
  );
}
