import { useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

import { BodyLayout } from "../BodyLayout";
import { Header } from "../Header";
import { getSubmissionTransformationsQueryOptions } from "./getSubmissionTransformationsQueryOptions";
import { TransformationPredictionsTable2 } from "./table/TransformationPredictionsTable2";

export const TransformationPage = () => {
  const navigate = useNavigate();
  const { submissionId: pId, transformationId: tId } = useParams();
  const submissionId = Number(pId);
  const transformationId = Number(tId);
  const { data } = useSuspenseQuery(
    getSubmissionTransformationsQueryOptions(submissionId),
  );

  // sort by id ascending
  const transformations = (data?.submission?.transformations ?? []).sort(
    (a, b) => a.id - b.id,
  );
  // get the current index of the transformation
  const currentIndex = transformations.findIndex(
    (t) => t.id === transformationId,
  );
  const currentTransformation = transformations[currentIndex];
  const nextTransformation = transformations[currentIndex + 1];

  const ButtonContent = () => {
    if (nextTransformation != null) {
      return (
        <Button>
          {nextTransformation.name}{" "}
          <Icons.chevronsRight className="ml-2 h-4 w-4" />
        </Button>
      );
    } else {
      return (
        <Button>
          <Icons.chevronsLeft className="mr-2 h-4 w-4" /> Back to Overview
        </Button>
      );
    }
  };

  return (
    <BodyLayout className="max-w-full">
      <Header
        title={currentTransformation.name}
        description="Click on the items below to review the extracted data. We've surfaced the most uncertain items for your review (corrections you make will apply to all future submissions)"
      />
      <div className="flex justify-between gap-6 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            navigate("..");
          }}
        >
          <Icons.chevronsLeft className="mr-2 h-4 w-4" />
          {"Back to Overview"}
        </Button>
        <AlertDialog>
          <AlertDialogTrigger>
            <ButtonContent />
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>Caution</AlertDialogHeader>
            <AlertDialogDescription>
              Some extractions have not been reviewed. You may be missing out on
              important information.
            </AlertDialogDescription>
            <AlertDialogFooter>
              <AlertDialogCancel>Go Back</AlertDialogCancel>
              <AlertDialogAction
                onClick={() => {
                  navigate(
                    nextTransformation != null
                      ? `../${nextTransformation.id}`
                      : `..`,
                  );
                }}
              >
                {nextTransformation != null
                  ? nextTransformation?.name
                  : "Back to Overview"}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>

      <div className="w-full py-12">
        <TransformationPredictionsTable2 />
      </div>
      <footer></footer>
    </BodyLayout>
  );
};
