import { useDraggable } from "@dnd-kit/core";

import { Card } from "@/components/ui/card";

type Props = {
  children: React.ReactNode;
  id: string;
  className?: string;
};

export function Draggable({ children, id, className }: Props) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
  });

  return (
    <Card className={className} ref={setNodeRef} {...listeners} {...attributes}>
      {children}
    </Card>
  );
}
