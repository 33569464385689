export function toSnakeCase(str: string) {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .trim() // Remove leading and trailing whitespace
    .split(/\s+/) // Split the string into an array of words based on one or more whitespace characters
    .join("_"); // Join the words with underscores
}

export function camelCaseToHeader(input: string): string {
  // Use a regular expression to split the camelCase string
  const words = input.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter of each word
  const capitalizedWords = words.replace(/\b\w/g, (match) =>
    match.toUpperCase(),
  );

  return capitalizedWords;
}

export function formatUSDRounded(amount: number): string {
  return "$" + Math.round(amount).toLocaleString();
}

export function formatUSD(amount: number): string {
  return "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function convertDecimaltoPercentageString(
  decimalNumber: number,
  decimalPlaces: number = 2,
) {
  return (decimalNumber * 100).toFixed(decimalPlaces) + "%";
}
