import { z } from "zod";

import { Field } from "../use-gen-custom-fields";

// Function to generate Zod schema from fields
export function generateZodSchemaFromFields(fields: Field[]): z.ZodTypeAny {
  const schemaProperties: Record<string, z.ZodTypeAny> = {};

  fields.forEach((field) => {
    let zodSchema: z.ZodTypeAny;
    switch (field.type) {
      case "boolean":
        zodSchema = z.boolean();
        break;
      case "text":
        zodSchema = z.string();
        break;
      case "number":
        zodSchema = z.number();
        break;
      case "date":
        zodSchema = z.string(); // Could be z.date() if you plan to handle date parsing properly
        break;
      case "address":
        zodSchema = z.string(); // Simplified; consider a more specific pattern if needed
        break;
      case "collection":
        if (field.fields) {
          // Recursively generate schema for nested fields
          zodSchema = z.array(generateZodSchemaFromFields(field.fields));
        } else {
          zodSchema = z.array(z.unknown());
        }
        break;
      case "thing":
        if (field.fields) {
          // Recursively generate schema for nested fields
          zodSchema = generateZodSchemaFromFields(field.fields);
        } else {
          zodSchema = z.object({});
        }
        break;
      case "enum":
        if (field.enum) {
          zodSchema = createZodEnum(field.enum);
        } else {
          zodSchema = z.unknown();
        }
        break;
      default:
        zodSchema = z.unknown();
    }
    zodSchema = zodSchema.nullable();

    schemaProperties[field.name] = zodSchema.describe(field.description);
  });

  return z.object(schemaProperties);
}

// Helper function to create Zod enum from string array
function createZodEnum(enumValues: string[]): z.ZodEnum<[string, ...string[]]> {
  return z.enum(enumValues as [string, ...string[]]);
}
