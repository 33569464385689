import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/cn";

// Skeleton component for a single row
const SkeletonRow = ({ index }: { index: number }) => (
  <TableRow className={cn("h-14 border-0", index % 2 && " bg-muted/40")}>
    {Array.from({ length: 5 }, (_, index) => (
      <TableCell key={index} className={cn("h-14 border-0 border-muted/40")}>
        {/* Empty div with width and height for the skeleton */}
        <div style={{ width: "100%", height: "1em" }}></div>
      </TableCell>
    ))}
  </TableRow>
);

export function ValidationExcelTableSkeleton() {
  return (
    <Table className="">
      <TableHeader className="">
        <TableRow
          style={{
            borderBottom: "0px",
          }}
          className=""
        >
          {Array.from({ length: 5 }, (_, index) => (
            <TableHead
              key={index}
              className="h-12 animate-pulse border-0 border-muted/40 bg-muted/40"
            >
              {/* Empty div with width and height for the skeleton header */}
              <div style={{ height: "1em" }}></div>
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody className="">
        {Array.from({ length: 10 }).map((_, index) => (
          <SkeletonRow key={index} index={index} />
        ))}
      </TableBody>
    </Table>
  );
}
