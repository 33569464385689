import { useInfiniteQuery } from "@tanstack/react-query";

import { graphql } from "@/gql";
import { ErrorStatus } from "@/gql/graphql";
import { getGraphqlClient } from "@/lib/hooks/graphql";

import { useVendorTableStoreInContext } from "./VendorTableContext";

const query = graphql(`
  query VendorsTable(
    $cursor: VendorWhereUniqueInput
    $where: VendorWhereInput
    $take: Int
    $lowAccuracySubmissions: SubmissionWhereInput
    $errorSubmissions: SubmissionWhereInput
  ) {
    vendors(cursor: $cursor, take: $take, where: $where) {
      id
      vendorId
      name
      totalSubmissions: _count {
        submissions
      }
    }
    lowAccuracyVendors: vendors {
      lowAccuracySubmissions: _count {
        submissions(where: $lowAccuracySubmissions)
      }
    }
    errorVendors: vendors {
      errorSubmissions: _count {
        submissions(where: $errorSubmissions)
      }
    }
  }
`);

export const useGetVendorTableData = () => {
  const { vendorName } = useVendorTableStoreInContext();

  const client = getGraphqlClient();

  return useInfiniteQuery({
    queryKey: ["GetVendorTableInfinite"],
    queryFn: async (data) => {
      const lastVendorId = data.pageParam + 1;

      const cursorObj =
        data.pageParam !== 0
          ? {
              id: lastVendorId,
            }
          : undefined;

      const variables = {
        where: {
          name: {
            contains: vendorName,
          },
        },
        take: 40,
        cursor: cursorObj,
        errorSubmissions: {
          errors: {
            some: {
              status: {
                in: [ErrorStatus.Unrecoverable, ErrorStatus.Unresolved],
              },
            },
          },
        },
        lowAccuracySubmissions: {
          metadata: {
            some: {
              key: {
                equals: "initial_estimated_accuracy",
              },
              AND: [
                {
                  value: {
                    lt: "0.6",
                  },
                },
              ],
            },
          },
        },
      };

      const vendorData = await client.request(query, variables);
      return vendorData;
    },
    initialPageParam: 0,
    getNextPageParam: (currentQuery, allQueriedData) => {
      const vendors = allQueriedData.flatMap((page) => page.vendors);
      if (vendors.length === 0) {
        return undefined;
      }
      return vendors[vendors.length - 1].id;
    },
  });
};
