import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ColumnFiltersState, Table } from "@tanstack/react-table";

import { Icons } from "@/components/Icons";
import {
  GlobalFilterOption,
  TableFilterButton,
} from "@/components/TableFilterButton";

import { PredictionRowType } from "../PredictionTableColumns";
import { getFilterOptionFromValue } from "../useGetPredictionTableFilterOptions";
import { FilterLabelButton } from "./FilterLabelButton";
import { FilterSelectionMenu } from "./FilterSelectionMenu";
import { PredictionTableAddFilterButton } from "./PredictionTableAddFilterButton";
import { PredictionTableClearFiltersButton } from "./PredictionTableClearFiltersButton";

type PredictionTableHeaderProps = {
  table: Table<PredictionRowType>;
  globalFilterOptions: GlobalFilterOption[];
  globalFilter: [number, number] | void;
  columnFilters: ColumnFiltersState;
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  setGlobalFilter: React.Dispatch<
    React.SetStateAction<[number, number] | void>
  >;
};

export function PredictionTableFilters({
  table,
  globalFilter,
  columnFilters,
  setColumnFilters,
  setGlobalFilter,
  globalFilterOptions,
}: PredictionTableHeaderProps) {
  const [parent] = useAutoAnimate();
  const [showFilterSelection, setShowFilterSelection] = useState(false);

  const globalFilterOption = getFilterOptionFromValue(
    globalFilter,
    globalFilterOptions,
  );

  const globalFilterName = globalFilterOption
    ? `${globalFilterOption?.name} (${globalFilterOption?.count})`
    : "Name Missing";

  const showClearFilterButton = globalFilter || columnFilters?.length > 0;

  return (
    <div className="flex flex-grow items-center gap-3" ref={parent}>
      <>
        {showClearFilterButton && (
          <PredictionTableClearFiltersButton
            onClick={() => {
              setGlobalFilter(undefined);
              setColumnFilters([]);
            }}
          />
        )}
        {globalFilter && (
          <FilterLabelButton
            filterName={globalFilterName}
            onRemove={() => setGlobalFilter(undefined)}
          />
        )}
        {columnFilters?.length > 0 &&
          columnFilters.map((colFilter) => {
            return (
              <FilterSelectionMenu
                table={table}
                setColumnFilters={setColumnFilters}
                setShowFilterSelection={setShowFilterSelection}
                initFilter={colFilter}
              />
            );
          })}
        {showFilterSelection ? (
          <FilterSelectionMenu
            table={table}
            setColumnFilters={setColumnFilters}
            setShowFilterSelection={setShowFilterSelection}
          />
        ) : (
          <PredictionTableAddFilterButton
            onClick={() => setShowFilterSelection(true)}
            hideFilterText={!(globalFilter || columnFilters?.length > 0)}
          />
        )}
        <TableFilterButton
          customIcon={<Icons.filter className="h-3.5 w-3.5" />}
          globalFilterOptions={globalFilterOptions}
          columnFilterOptions={[]}
          sortOptions={[]}
        />
      </>
    </div>
  );
}
