import { PredictionRowsQuery } from "@/gql/graphql";

type Props = {
  predictionRowData: PredictionRowsQuery;
};

type Label = PredictionRowsQuery["predictions"][0]["labels"][0];

export const getTransformationPredictionsTableHeadersAndRows = ({
  predictionRowData,
}: Props) => {
  const { predictions } = predictionRowData;

  // headers are going to start with the metadata keys: productSKU, productDescription
  // then the labels: name
  const headers: string[] = [];
  // rows are going to be the metadata values and the labels values associated with the headers
  const rows: { [key: string]: string | Label }[] = [];

  // iterate over the first prediction to get the headers
  const firstPrediction = predictions[0];
  if (firstPrediction) {
    const { metadata, labels } = firstPrediction;
    if (metadata) {
      metadata.forEach(({ key }) => {
        if (key === "productSKU" || key === "productDescription") {
          headers.push(key);
        }
      });
    }
    if (labels) {
      labels.forEach(({ name }) => {
        headers.push(name);
      });
    }
  }

  // to create rows iterate over all predictions and get the metadata and labels values associated with the headers
  predictions.forEach((prediction) => {
    const row: { [key: string]: string | Label } = {};
    const { metadata, labels } = prediction;
    if (metadata) {
      metadata.forEach(({ key, value }) => {
        if (key === "productSKU" || key === "productDescription") {
          row[key] = value;
        }
      });
    }
    if (labels) {
      labels.forEach((label) => {
        const name = label.name;
        row[name] = label;
      });
    }
    rows.push(row);
  });

  return { headers, rows };
};
