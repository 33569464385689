import { graphql } from "@/gql";
import { Vendor } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

export const getVendorQueryOptions = (vendorId: Vendor["id"]) => {
  return createQueryOptions({
    query: graphql(`
      query GetVendor($where: VendorWhereUniqueInput!) {
        vendor(where: $where) {
          id
          name
          email
          organization {
            name
          }
        }
      }
    `),
    variables: {
      where: {
        id: vendorId,
      },
    },
  });
};
