import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useCreateOrgExportDatabase = () => {
  return useGraphqlMutation({
    mutation: graphql(`
      mutation CreateOrgExportDatabaseMutation(
        $data: ExportDatabaseCreateInput!
      ) {
        createOneExportDatabase(data: $data) {
          id
        }
      }
    `),
  });
};
