import { PostHogProvider } from "posthog-js/react";

import { env } from "@/env";

interface PostHogProps {
  disabled?: boolean;
  children: React.ReactNode;
}

export function AnalyticsProvider({ children, disabled }: PostHogProps) {
  if (disabled) return <>{children}</>;

  return (
    <PostHogProvider
      apiKey={env.VITE_PUBLIC_POSTHOG_KEY}
      options={{
        // api_host: "https://webhook.site/cb4c10bd-c5a8-4167-80f9-f96c01d0314f",
        api_host: env.VITE_PUBLIC_POSTHOG_HOST,
        disable_session_recording: env.VITE_PUBLIC_NODE_ENV !== "production", // So we don't record ALL sessions
        session_recording: {
          // maskTextSelector: "*", // Masks all text elements (not including inputs)
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
            // color: false,
            // date: false,
            // 'datetime-local': false,
            // email: false,
            // month: false,
            // number: false,
            // range: false,
            // search: false,
            // tel: false,
            // text: false,
            // time: false,
            // url: false,
            // week: false,
            // textarea: false,
            // select: false,
          },
        },
      }}
    >
      {children}
    </PostHogProvider>
  );
}
