import { ColumnFilterOption } from "@/components/TableFilterButton";
import { Input } from "@/components/ui/input";

import { useVendorTableStoreInContext } from "./VendorTableContext";

export const useGetVendorTableFilters = () => {
  const { vendorName, setVendorName } = useVendorTableStoreInContext();

  const columnFilterOptions: ColumnFilterOption[] = [
    {
      name: "Partner Name",
      colId: "Partner",
      width: "col-span-4",
      inputNode: (
        <Input
          value={vendorName}
          placeholder="Search Partners..."
          onChange={(event) => setVendorName(event.target.value)}
          className="max-w-sm"
        />
      ),
    },
  ];

  return {
    columnFilterOptions,
  };
};
