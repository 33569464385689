import { useEffect, useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQuery } from "@tanstack/react-query";
import { useParams, useRevalidator } from "react-router-dom";
import { useInterval } from "react-use";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { Progress } from "@/components/ui/progress";
import { cn } from "@/lib/cn";
import { easeSwift } from "@/lib/motion";

import { getSubmissionStatusQueryOptions } from "./loader";

const ESTIMATED_LOADING_TIME = 60000; // Ideally this should be fetched from the server
const POLLING_INTERVAL = 1000;

export const LoadingPage = () => {
  const { submissionId: sId } = useParams();
  const [progress, setProgress] = useState(0);

  const revalidator = useRevalidator();

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: easeSwift,
  });

  const submissionId = Number(sId);

  const { data, isFetching } = useQuery({
    ...getSubmissionStatusQueryOptions(submissionId),
    refetchInterval: POLLING_INTERVAL,
    refetchIntervalInBackground: true,
    enabled: true,
    refetchOnWindowFocus: true,
  });
  const status = data?.submission?.status;

  useInterval(() => {
    setProgress((prev) => {
      const progress = prev + 100 / (ESTIMATED_LOADING_TIME / 110);
      return progress > 100 ? 100 : progress;
    });
  }, 110);

  useEffect(() => {
    if (status === "PROCESSED") setProgress(100);
  }, [status]);

  useEffect(() => {
    if (progress === 100 && status === "PROCESSED") {
      setTimeout(() => {
        // throws it back to the router to determine what to do next
        revalidator.revalidate();
      }, 2000);
    }
  }, [progress, status, revalidator]);

  return (
    <DashboardScreen>
      <Progress value={progress} className="w-full" />
      <h1
        className=" mb-4 mt-2 flex w-full justify-center text-base font-semibold "
        ref={parent}
      >
        {status !== "PROCESSED" ? (
          <span
            key="processing"
            className="w-64 animate-pulse text-muted-foreground"
          >
            Processing your submission
          </span>
        ) : (
          <div key="processed">Processed!</div>
        )}
      </h1>
      <p>Status: {status}</p>

      <p>
        Polling:
        <span className={cn(isFetching ? "text-green-500" : "text-orange-500")}>
          {" "}
          {isFetching.toString()}
        </span>
      </p>
    </DashboardScreen>
  );
};
