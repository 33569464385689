import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { graphql } from "@/gql";
import { Organization } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

import {
  TransformationTypeItem,
  TransformationTypeItemSkeleton,
} from "./TransformationTypeItem";

export const getQueryOptions = (organizationId: Organization["id"]) => {
  return createQueryOptions({
    query: graphql(`
      query TransformationTypeList($where: TransformationTypeWhereInput) {
        transformationTypes(where: $where) {
          id
          name
          submissionTypes {
            id
          }
          updatedAt
          description
        }
      }
    `),
    variables: {
      where: {
        organizationId: {
          equals: organizationId,
        },
      },
    },
  });
};

export const TransformationTypeList: React.FC = () => {
  const [parent] = useAutoAnimate();
  const { organizationId: oId } = useParams();
  const organizationId = Number(oId);

  const queryOpts = getQueryOptions(organizationId);

  const { data, isLoading } = useQuery(queryOpts);

  return (
    <div className="max-w-3xl">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="flex items-center text-2xl font-semibold">
          <div className="mr-4 rounded-full border border-border p-3">
            <Icons.transformation />
          </div>
          Transformations
        </h2>
        <Button disabled className="w-48">
          Create transformation
        </Button>
      </div>
      <div className="flex flex-col gap-4" ref={parent}>
        {isLoading ? (
          <>
            <TransformationTypeItemSkeleton key="loader-1" />
            <TransformationTypeItemSkeleton key="loader-2" />
            <TransformationTypeItemSkeleton key="loader-3" />
          </>
        ) : data && data?.transformationTypes?.length > 0 ? (
          data.transformationTypes.map((transformationType, i) => (
            <TransformationTypeItem
              key={transformationType.id + "-" + i}
              transformationType={transformationType}
            />
          ))
        ) : (
          <Card className="text-md max-w-3xl font-medium text-muted-foreground">
            <CardContent className="flex justify-center pt-6">
              No Transformation Types found
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};
