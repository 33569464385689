import { ComponentProps, memo } from "react";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/cjs/languages/prism/json";
import { duotoneDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import { WithSuspenseAndErrorBoundary } from "@/components/WithSuspenseAndErrorBoundary";
import { cn } from "@/lib/cn";
import { jsonValidationSchema } from "@/lib/validations";

SyntaxHighlighter.registerLanguage("json", json);

interface Props
  extends Omit<ComponentProps<typeof SyntaxHighlighter>, "children"> {
  json: ReturnType<(typeof jsonValidationSchema)["parse"]>;
}

const syntaxTheme = duotoneDark;

const Component = ({ json, className, ...props }: Props) => {
  const jsonStr = JSON.stringify(jsonValidationSchema.parse(json), null, 2);

  return (
    <SyntaxHighlighter
      style={syntaxTheme}
      language={"json"}
      PreTag="div"
      className={cn("shadow-inner", className)}
      showLineNumbers={false}
      wrapLines={true}
      {...props}
    >
      {jsonStr}
    </SyntaxHighlighter>
  );
};

export const JsonBlock = memo(
  WithSuspenseAndErrorBoundary({
    Component,
  }),
);
