import { useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/cn";

import { Field } from "../use-gen-custom-fields";
import InputField, { placeholdersByType } from "./input-field";

type Props = {
  field?: Field;
  onSave: (
    updatedField: Field,
    mode: "edit" | "add",
    editingField?: Field,
  ) => void;
  mode: "add" | "edit";
};

export const EditFieldModal = ({ field, onSave, mode }: Props) => {
  const [name, setName] = useState(field?.name || "");
  const [type, setType] = useState(field?.type || "text");
  const [description, setDescription] = useState(field?.description || "");
  const [nestedFields, setNestedFields] = useState<Field[]>(
    field?.fields || [],
  );

  const handleSave = () => {
    onSave({ name, type, description, fields: nestedFields }, mode, field);
  };

  const addNestedField = () => {
    setNestedFields((prevFields) => [
      ...prevFields,
      { name: "", type: "text", description: "" },
    ]);
  };

  const updateNestedField = (index: number, updatedField: Field) => {
    setNestedFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index] = updatedField;
      return newFields;
    });
  };

  const removeNestedField = (index: number) => {
    setNestedFields((prevFields) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });
  };

  return (
    <Dialog>
      <DialogTrigger className={cn(mode === "add" && "w-full")}>
        {mode === "edit" ? (
          <Button variant={"outline"} size={"sm"}>
            {"Edit"}
          </Button>
        ) : (
          <Button variant={"outline"} className="w-full">
            {"Add New Field"}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className={"max-h-screen overflow-auto"}>
        <h2>{mode === "edit" ? "Edit Field" : "Add Field"}</h2>
        <div className="space-y-4">
          <div>
            <Label>Name</Label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="name of field..."
            />
          </div>
          <div>
            <Label>Type</Label>
            {/* @ts-ignore - it's correct */}
            <Select value={type} onValueChange={setType}>
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="text">Text</SelectItem>
                  <SelectItem value="number">Number</SelectItem>
                  <SelectItem value="date">Date</SelectItem>
                  <SelectItem value="address">Address</SelectItem>
                  <SelectItem value="thing">Thing (object)</SelectItem>
                  <SelectItem value="collection">Collection (array)</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label>Description</Label>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={
                type != null
                  ? placeholdersByType[type]
                  : "description of field.."
              }
            />
          </div>
          {(type === "thing" || type === "collection") && (
            <div className="ml-6">
              <h3 className="pb-2 text-lg font-semibold">Nested Fields</h3>
              <div className=" space-y-4">
                {nestedFields.map((nestedField, index) => (
                  <InputField
                    key={index}
                    isNested={true}
                    index={index}
                    name={nestedField.name}
                    type={nestedField.type}
                    description={nestedField.description}
                    onNameChange={(name) =>
                      updateNestedField(index, { ...nestedField, name })
                    }
                    onTypeChange={(type) =>
                      updateNestedField(index, { ...nestedField, type })
                    }
                    onDescriptionChange={(description) =>
                      updateNestedField(index, { ...nestedField, description })
                    }
                    onRemove={() => removeNestedField(index)}
                  />
                ))}
              </div>
              <Button
                variant={"outline"}
                onClick={addNestedField}
                className="mt-4"
              >
                Add Nested Field
              </Button>
            </div>
          )}
        </div>
        <DialogFooter className="flex flex-row justify-end gap-4">
          <DialogClose>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <DialogClose onClick={handleSave}>
            <Button>Save</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
