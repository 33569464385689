import { useState } from "react";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { ExportForm } from "./ExportForm";
import { ExportDB } from "./getOrgExportSettings";

type Props = {
  exportDB?: ExportDB;
};

export const DatabaseExportDialog = ({ exportDB }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {exportDB ? (
          <Button
            variant={"outline"}
            className="h-10 w-10 rounded-full p-1 text-foreground/80"
          >
            <Icons.edit className="h-4" />
          </Button>
        ) : (
          <Button className="w-48">{"Create New Export"}</Button>
        )}
      </DialogTrigger>

      <DialogContent
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>
            {exportDB ? "Edit Database Export" : "Create Database Export"}
          </DialogTitle>
          <DialogDescription>
            Update your organization's export settings.
          </DialogDescription>
        </DialogHeader>

        <ExportForm exportDB={exportDB} setOpen={setOpen} />
      </DialogContent>
    </Dialog>
  );
};
