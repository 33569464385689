import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";

import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { getSubmissionHeaderQO } from "./SubmissionHeader";

export function SubmissionHeaderTitles() {
  const { submissionId } = useParams();
  const queryOpts = getSubmissionHeaderQO(Number(submissionId));
  const { data: submissionData } = useSuspenseQuery(queryOpts);
  const { data: userData } = useCurrentUserQuery();
  const submission = submissionData.submission;
  const user = userData.user;

  if (!submission) throw new Error("Submission not found");

  return (
    <div className="flex flex-col gap-1 font-medium">
      {user?.organization.id && submission.type ? (
        <Link
          className="text-muted-foreground"
          to={`/dashboard/settings/org/${user.organization.id}/submission-type/${submission.type.id}`}
        >
          {submission.type.name}
        </Link>
      ) : (
        <h3 className="text-muted-foreground">
          {submission.type?.name ?? "No Submission Type"}
        </h3>
      )}

      <Link
        className=" text-xl"
        to={`/dashboard/partner/${submission.vendor.id}`}
      >
        {submission.vendor.name}
      </Link>
    </div>
  );
}
