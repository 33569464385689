import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate, useParams, useRevalidator } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { CreateSubmissionMutation } from "@/gql/graphql";
import { cn } from "@/lib/cn";

import { getVendorSubmissionTypesQueryOptions } from "../submission/:submissionId/confirm-submission-type/getVendorSubmissionTypesQueryOptions";
import { FileUpload2 } from "./FileUpload2";
import { useUploadFileAndCreateSubmission } from "./useUploadFileAndCreateSubmission";

export const SubmissionTypeUploadGrid = () => {
  const { vendorId: vId } = useParams();
  const vendorId = Number(vId);
  let revalidator = useRevalidator();
  const navigate = useNavigate();

  const { data: submissionTypeData } = useSuspenseQuery(
    getVendorSubmissionTypesQueryOptions(vendorId),
  );
  const { submissionTypes: vendorSubmissionTypes } = submissionTypeData;
  let [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const [file, setFile] = useState<File | undefined>(undefined);
  const { mutate } = useUploadFileAndCreateSubmission();

  return (
    <div className="grid grid-flow-col">
      {vendorSubmissionTypes.map((submissionType, idx) => {
        function onUpload(file: File) {
          mutate(
            { file, submissionTypeId: submissionType.id },
            {
              onSuccess: (submissionData: CreateSubmissionMutation) => {
                console.log(
                  "onUpload success, submissionID should be here. Hookup create submission",
                  { submissionData },
                );
                const submissionId = submissionData.createSubmission.id;
                setFile(undefined);

                navigate(
                  `/vendor-submit/${vendorId}/submission/${submissionId}/normalize`,
                  {
                    state: {
                      fileId: submissionData.createSubmission.fileId,
                      submissionData,
                    },
                  },
                );
                revalidator.revalidate();
              },
              onError: (error) => {
                console.error("onUpload error", error);
                setFile(undefined);
              },
            },
          );
        }
        return (
          <Dialog key={submissionType.id}>
            <DialogTrigger>
              <div
                className="group relative block h-full w-full p-2 "
                onMouseEnter={() => setHoveredIndex(idx)}
                onMouseLeave={() => setHoveredIndex(null)}
                key={submissionType.id}
              >
                <AnimatePresence>
                  {hoveredIndex === idx && (
                    <motion.span
                      className="absolute inset-0 block h-full w-full rounded-3xl  bg-neutral-200 dark:bg-slate-800/[0.8]"
                      layoutId="hoverBackground"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.15 },
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.1, delay: 0.66 },
                      }}
                    />
                  )}
                </AnimatePresence>
                <Card
                  className={cn(
                    "relative z-20 h-full w-full cursor-pointer overflow-hidden rounded-2xl border bg-black p-4 group-hover:border-slate-700",
                  )}
                >
                  <CardHeader className="flex items-center justify-center">
                    {/* TODO: need icon on submissiontypes */}
                    {idx === 0 ? (
                      <Icons.package className="h-24 w-24" />
                    ) : (
                      <Icons.invoice className="h-24 w-24" />
                    )}
                  </CardHeader>
                  <CardContent className="text-center">
                    <p className="text-lg">{submissionType.name}</p>
                    <p className=" text-sm font-extralight text-primary/70">
                      {/* TODO: flesh out description */}
                      {submissionType.description}
                    </p>
                  </CardContent>
                </Card>
              </div>
            </DialogTrigger>
            <DialogContent>
              <DialogTitle>Upload {submissionType.name}</DialogTitle>
              <FileUpload2
                setFileToUpload={setFile}
                file={file}
                onUpload={onUpload}
                isUploading={false}
              />
            </DialogContent>
          </Dialog>
        );
      })}
    </div>
  );
};
