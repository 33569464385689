import { useState } from "react";
import { toast } from "sonner";

import { graphql } from "@/gql/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

type Props = {
  onDownload: (signedUrl: string, fileName: string) => void;
};

export const usePrepareFileDownload = ({ onDownload }: Props) => {
  const [toastId, _setToastId] = useState<string | number | null>(null);

  const { mutate: prepareFileDownload } = useGraphqlMutation({
    mutation: graphql(`
      mutation PrepareFileDownload($fileId: Float!) {
        prepareFileDownload(fileId: $fileId) {
          signedUrl
        }
      }
    `),
    onMutate: () => {
      if (toastId) toast.dismiss(toastId);

      // setToastId(toast.loading("Preparing download..."));
    },
    onSettled: () => {
      if (toastId) toast.dismiss(toastId);
    },
  });

  return (fileId: number, fileName: string) =>
    prepareFileDownload(
      { fileId },
      {
        onSuccess: (data) => {
          const signedUrl = data.prepareFileDownload.signedUrl;
          if (typeof signedUrl !== "string") return;

          onDownload(signedUrl, fileName);

          // const link = document.createElement("a");
          // link.href = signedUrl;
          // link.download = fileName;

          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
        },
        onError: (error) => {
          console.log({ error });
        },
      },
    );
};
