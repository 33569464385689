import { graphql } from "@/gql/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  vendorId: number;
};

export const useGetVendorSummaryHeaderData = ({ vendorId }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetVendorSummaryHeaderData", vendorId],
    query: graphql(`
      query GetVendorSummaryHeaderData($where: VendorWhereUniqueInput!) {
        vendor(where: $where) {
          id
          vendorId
          name
        }
      }
    `),
    variables: { where: { id: vendorId } },
  });
};
