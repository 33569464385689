import { SortOrder } from "@intuitive-systems/protege-engine";

import { graphql } from "@/gql/gql";
import { QueryMode } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  transformationCategory?: string;
  labelName: string;
  typeahead?: string;
};

export const useGetProductTypeahead = ({
  transformationCategory,
  labelName,
  typeahead = "",
}: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetProductTypeahead", transformationCategory, labelName],
    query: graphql(`
      query useGetProductTypeahead(
        $where: ProductWhereInput
        $orderBy: [ProductOrderByWithRelationAndSearchRelevanceInput!]
        $take: Int
        $distinct: [ProductScalarFieldEnum!]
      ) {
        products(
          where: $where
          orderBy: $orderBy
          take: $take
          distinct: $distinct
        ) {
          manufacturer
          category
          family
          id
          line
          model
          name
          upc
        }
      }
    `),
    variables: {
      where: {
        category:
          transformationCategory != null
            ? {
                equals: transformationCategory,
                mode: QueryMode.Insensitive,
              }
            : undefined,
        [labelName]: {
          mode: QueryMode.Insensitive,
          contains: typeahead,
        },
      },
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      take: 5,
      // @ts-ignore labelName is correct
      distinct: labelName,
    },
  });
};
