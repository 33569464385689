import { useParams } from "react-router-dom";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { BackButton } from "@/components/BackButton";
import { Icons } from "@/components/Icons";
import { Spinner } from "@/components/Spinner";
import { Card, CardContent } from "@/components/ui/card";
import { graphql } from "@/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

export const SubmissionTypeEditPage: React.FC = () => {
  const { submissionTypeId } = useParams();

  const { data, isLoading } = useGraphqlQuery({
    queryKey: ["users", `submissionTypeId=${submissionTypeId}`],
    query: graphql(`
      query SubmissionTypeEditPage($where: SubmissionTypeWhereUniqueInput!) {
        submissionType(where: $where) {
          id
          name
          description
          outputColumns
          transformationTypes {
            id
            name
          }
          validationTypes {
            id
            name
          }
        }
      }
    `),
    variables: {
      where: {
        id: Number(submissionTypeId),
      },
    },
  });

  if (isLoading) {
    return (
      <DashboardScreen
        title="Loading..."
        className="flex h-display w-full items-center justify-center"
      >
        <Spinner size="2xl" />
      </DashboardScreen>
    );
  }

  const submissionType = data?.submissionType;

  return (
    <DashboardScreen title={submissionType?.name}>
      <div className="max-w-3xl bg-background">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-4">
            <div className="rounded-full border border-border p-3">
              <Icons.submission className=" text-foreground" />
            </div>
            <div>
              <h1 className="text-lg font-semibold">{submissionType?.name}</h1>
              <p className="text-sm text-muted-foreground">
                {submissionType?.description}
              </p>
            </div>
          </div>

          <BackButton relative="history" />
        </div>
        <div className="mt-8">
          <h2 className="mb-4 text-lg font-semibold">Data Actions</h2>
          <Card className="max-w-3xl">
            <CardContent className="flex flex-col gap-6 p-6">
              <div className="flex flex-col justify-between gap-2">
                <h3 className=" text-lg font-semibold">Transformations</h3>
                {submissionType?.transformationTypes.map(
                  (transformationType, i) => (
                    <div key={transformationType.id}>
                      <span className="mr-1 text-sm text-muted-foreground">
                        {i + 1}.
                      </span>{" "}
                      {transformationType.name}
                    </div>
                  ),
                )}
                {submissionType?.transformationTypes.length === 0 && (
                  <div className="text-muted-foreground">
                    No transformations performed
                  </div>
                )}
              </div>

              <div className="flex flex-col justify-between gap-2">
                <h3 className=" text-lg font-semibold">Validations</h3>
                {submissionType?.validationTypes.map((validationType, i) => (
                  <div key={validationType.id}>
                    <span className="mr-1 text-sm text-muted-foreground">
                      {i + 1}.
                    </span>{" "}
                    {validationType.name}
                  </div>
                ))}
                {submissionType?.validationTypes.length === 0 && (
                  <div className="text-muted-foreground">
                    No validations performed
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </DashboardScreen>
  );
};
