import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

import { Field } from "../use-gen-custom-fields";

type Props = {
  index?: number;
  name?: string;
  onNameChange: (name: string) => void;
  type?: Field["type"];
  onTypeChange: (type: Field["type"]) => void;
  description?: string;
  onDescriptionChange: (description: string) => void;
  onRemove?: () => void;
  fields?: Field[];
  onFieldsChange?: (fields: Field[]) => void;
  isNested?: boolean;
};

export const placeholdersByType = {
  string: "description of the field..",
  text: "this field is..",
  number: "the number of..",
  date: "the date of the..",
  address: "the address of the..",
  thing: "something with sub fields..",
  collection: "a group of..",
  boolean: "true or false..",
  enum: "one of the following..",
};

const InputField = ({
  index,
  name,
  type,
  description,
  onNameChange,
  onTypeChange,
  onDescriptionChange,
  onRemove,
  fields,
  onFieldsChange,
  isNested,
}: Props) => {
  const addNestedField = () => {
    if (onFieldsChange) {
      onFieldsChange([
        ...(fields || []),
        { name: "", type: "text", description: "" },
      ]);
    }
  };

  const updateNestedField = (nestedIndex: number, updatedField: Field) => {
    if (onFieldsChange) {
      const newFields = [...(fields || [])];
      newFields[nestedIndex] = updatedField;
      onFieldsChange(newFields);
    }
  };

  const removeNestedField = (nestedIndex: number) => {
    if (onFieldsChange) {
      const newFields = [...(fields || [])];
      newFields.splice(nestedIndex, 1);
      onFieldsChange(newFields);
    }
  };

  return (
    <div className="grid grid-cols-[1fr_80px] gap-4">
      <Label className="col-span-2 flex gap-2">
        <p>
          {index != null ? `Extraction Field ${index + 1}` : "Create New Field"}
        </p>
        {index != null && (
          <Icons.remove
            onClick={onRemove}
            className="h-4 w-4 cursor-pointer hover:text-destructive"
          />
        )}
      </Label>
      <div className="col-span-2 flex gap-4">
        <Input
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
          placeholder="name of field.."
        />
        <Select value={type} onValueChange={onTypeChange}>
          <SelectTrigger className="w-[90px]">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="text">Text</SelectItem>
              <SelectItem value="number">Number</SelectItem>
              <SelectItem value="date">Date</SelectItem>
              <SelectItem value="address">Address</SelectItem>
              {!isNested && (
                <SelectItem value="thing">Thing (object)</SelectItem>
              )}
              {!isNested && (
                <SelectItem value="collection">Collection (array)</SelectItem>
              )}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <Textarea
        value={description}
        onChange={(e) => onDescriptionChange(e.target.value)}
        className="col-span-2"
        placeholder={
          type != null ? placeholdersByType[type] : "description of field.."
        }
      />
      {(type === "thing" || type === "collection") &&
        fields &&
        onFieldsChange && (
          <div className="col-span-2 mt-4">
            <Button onClick={addNestedField}>Add Nested Field</Button>
            <div className="mt-4 space-y-4">
              {fields.map((nestedField, nestedIndex) => (
                <InputField
                  key={nestedIndex}
                  index={nestedIndex}
                  name={nestedField.name}
                  type={nestedField.type}
                  description={nestedField.description}
                  onNameChange={(name) =>
                    updateNestedField(nestedIndex, { ...nestedField, name })
                  }
                  onTypeChange={(type) =>
                    updateNestedField(nestedIndex, { ...nestedField, type })
                  }
                  onDescriptionChange={(description) =>
                    updateNestedField(nestedIndex, {
                      ...nestedField,
                      description,
                    })
                  }
                  onRemove={() => removeNestedField(nestedIndex)}
                  fields={nestedField.fields}
                  onFieldsChange={(fields) =>
                    updateNestedField(nestedIndex, { ...nestedField, fields })
                  }
                />
              ))}
            </div>
          </div>
        )}
    </div>
  );
};

export default InputField;
