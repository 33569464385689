import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

export function BrainAiThinking(props: Props) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.4712C10.7349 18.9631 11.6187 19.25 12.5694 19.25C14.6819 19.25 16.4635 17.8338 17.0167 15.8991C18.3271 15.3617 19.25 14.0734 19.25 12.5694C19.25 11.5629 18.8366 10.6529 18.1703 10C18.8366 9.3471 19.25 8.43711 19.25 7.43056C19.25 5.57553 17.8459 4.04846 16.0425 3.8542C15.5934 2.07047 13.9787 0.75 12.0556 0.75C11.3067 0.75 10.6047 0.9502 10 1.29999M10 18.4712C9.26509 18.9631 8.38133 19.25 7.43056 19.25C5.31815 19.25 3.5365 17.8338 2.98327 15.8991C1.67291 15.3617 0.75 14.0734 0.75 12.5694C0.75 11.5629 1.16341 10.6529 1.82969 10C1.16341 9.3471 0.75 8.43711 0.75 7.43056C0.75 5.57553 2.15413 4.04846 3.95754 3.8542C4.40662 2.07047 6.02133 0.75 7.94444 0.75C8.69326 0.75 9.39531 0.9502 10 1.29999M10 18.4712L10 1.29999M6.75 11.75C8.54493 11.75 10 13.2051 10 15M13.25 8.25C11.4551 8.25 10 6.79493 10 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
