import { cn } from "@/lib/cn";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const DocumentExtractScreen = ({
  className,
  children,
  ...props
}: Props) => {
  return (
    <div className={cn("self-stretch overflow-auto", className)} {...props}>
      {children}
    </div>
  );
};
