import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

type Props = {
  label: string;
  value: any;
};

const isObject = (value: any) => {
  return value && typeof value === "object" && !Array.isArray(value);
};

export const ExtractionField = ({ label, value }: Props) => {
  if (Array.isArray(value)) {
    return (
      <Card className="col-span-2 border-0">
        <CardHeader>
          <Label className="text-center text-lg font-semibold">
            {label} ({value.length})
          </Label>
        </CardHeader>
        <div className="flex flex-wrap gap-4">
          {value.map((val, i) => (
            <ExtractionField key={i} label={`${label} #${i + 1}`} value={val} />
          ))}
        </div>
      </Card>
    );
  }

  if (isObject(value)) {
    return (
      <Card className="col-span-2 w-full">
        <CardHeader>
          <Label className=" text-lg font-semibold">{label}</Label>
        </CardHeader>
        <CardContent className=" grid grid-cols-2 gap-4 p-4 sm:p-6">
          {Object.entries(value).map(([key, val], i) => (
            <ExtractionField key={i} label={key} value={val} />
          ))}
        </CardContent>
      </Card>
    );
  }

  return (
    <div>
      <Label>{label}</Label>
      <Input disabled value={value} />
    </div>
  );
};
