import { useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { SubmissionStatus } from "@/gql/graphql";
import { cn } from "@/lib/cn";

import { getVendorQueryOptions } from "../../../getVendorQueryOptions";
import { BodyLayout } from "../BodyLayout";
import { useUpdateSubmission } from "../confirm-submission-type/useUpdateSubmission";
import { Header } from "../Header";
import { SubmissionChartCard } from "./SubmissionChartCard";
import { SubmissionProductsTable } from "./SubmissionProductsTable";
import { SubmissionSummaryCard } from "./SubmissionSummaryCard";

export const SummaryPage = () => {
  const { vendorId: vId, submissionId: sId } = useParams();
  const submissionId = Number(sId);
  const vendorId = Number(vId);
  const { data: vendorData } = useSuspenseQuery(
    getVendorQueryOptions(vendorId),
  );
  const { mutate: updateSubmission } = useUpdateSubmission();
  const navigate = useNavigate();

  return (
    <BodyLayout>
      <Header
        title="Review Your Submission Information"
        description="We've summarized the information you've provided at a high level. Take a look and make sure everything looks good before submitting."
      />

      <div className="py-6">
        <HoverBorderGradient
          as="button"
          containerClassName=""
          onClick={() => {
            updateSubmission(
              {
                data: {
                  status: { set: SubmissionStatus.ReadyForDelivery },
                },
                where: {
                  id: submissionId,
                },
              },
              {
                onSuccess: () => {
                  navigate(`/vendor/${vendorId}`);
                },
                onError: (error) => {
                  toast.error(`Error submitting: ${error.message}`);
                },
              },
            );
          }}
          className={cn(
            "min-w-[250px] hover:bg-accent hover:text-accent-foreground",
          )}
        >
          {`Submit to ${vendorData.vendor?.organization.name}`}
        </HoverBorderGradient>
      </div>

      <div className="grid w-full grid-cols-2 grid-rows-2 gap-6">
        <SubmissionSummaryCard />

        <SubmissionChartCard />

        <SubmissionProductsTable />
      </div>
    </BodyLayout>
  );
};
