import { Outlet } from "react-router-dom";

import { Separator } from "@/components/ui/separator";

import { VendorSummaryHeader } from "./summary/VendorSummaryHeader";

export function VendorLayout() {
  return (
    <div className="grid h-full min-w-[684px] grid-rows-[auto_1fr]">
      <header>
        <VendorSummaryHeader />
        <Separator className="my-6" />
      </header>
      <Outlet />
    </div>
  );
}
