import { Table } from "@tanstack/react-table";

import {
  ColumnFilterOption,
  GlobalFilterOption,
  SortOption,
  TableFilterButton,
} from "@/components/TableFilterButton";
import { CardHeader, CardTitle } from "@/components/ui/card";

import { TablePaginationButtons } from "../../../../components/TablePaginationButtons";
import { CreateSubmissionButton } from "./create-submission/CreateSubmissionButton";

type SubmissionListHeaderProps = {
  tableTitle: string;
  table: Table<any>;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  hasNext?: boolean;
  isFetchingNextPage?: boolean;
  sortOptions: SortOption[];
  columnFilterOptions: ColumnFilterOption[];
  globalFilterOptions: GlobalFilterOption[];
  showCreateSubmissionButton?: boolean;
};

export function SubmissionListHeader({
  tableTitle,
  table,
  onClickNext,
  onClickPrev,
  hasNext,
  isFetchingNextPage,
  sortOptions,
  columnFilterOptions,
  globalFilterOptions,
  showCreateSubmissionButton = true,
}: SubmissionListHeaderProps) {
  return (
    <CardHeader className="flex flex-row items-center justify-between">
      <CardTitle className="">{tableTitle}</CardTitle>
      <div className="flex items-center gap-2">
        <TablePaginationButtons
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
          hasNext={hasNext}
          isFetchingNextPage={isFetchingNextPage}
          table={table}
        />
        <TableFilterButton
          sortOptions={sortOptions}
          globalFilterOptions={globalFilterOptions}
          columnFilterOptions={columnFilterOptions}
        />
        {showCreateSubmissionButton && <CreateSubmissionButton />}
      </div>
    </CardHeader>
  );
}
