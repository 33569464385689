import { Linkedin } from "lucide-react";

import { cn } from "@/lib/cn";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const navigation = [
  {
    name: "X",
    href: "https://twitter.com/IntuitiveSysAI",
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
      </svg>
    ),
  },
  // LinkedIn
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/intuitive-systems-inc/",
    icon: Linkedin,
  },
];

export function DocumentExtractFooter({ className, ...props }: Props) {
  return (
    <footer
      className={cn("flex w-full items-center justify-between", className)}
      {...props}
    >
      <p className="text-[10px] text-muted-foreground sm:text-xs">
        &copy; {new Date().getFullYear()} Intuitive Systems AI, Inc. All rights
        reserved.
      </p>

      <div className="flex justify-center space-x-4 ">
        {navigation.map((item) => (
          <a
            key={item.name}
            href={item.href}
            target="_blank"
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">{item.name}</span>
            <item.icon
              className="h-5 w-5 shrink-0 sm:h-6 sm:w-6"
              aria-hidden="true"
            />
          </a>
        ))}
      </div>
    </footer>
  );
}
