import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

export const SubmissionSummaryCard = () => {
  return (
    <Card className="col-span-1 row-span-1">
      <CardHeader>
        <CardTitle>Submission Summary</CardTitle>
        <CardDescription>File Name</CardDescription>
      </CardHeader>
      {/* labels on the far left, stats on far right */}
      <CardContent>
        <div className="flex justify-between py-2">
          <p>Rows Submitted</p>
          <p>100</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>AMD Related Rows</p>
          <p>50</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Correction Completion %</p>
          <p>50%</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>CPUs Sold</p>
          <p>50</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>GPUs Sold</p>
          <p>50</p>
        </div>
        <Separator />
        <div className="flex justify-between py-2">
          <p>Estimated Rebate/Points calc</p>
          <p>$100</p>
        </div>
      </CardContent>
    </Card>
  );
};
