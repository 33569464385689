type Props = {
  title: string;
  description: string;
};

export const Header = ({ title, description }: Props) => {
  return (
    <header className="text-center">
      <h1 className="text-3xl font-semibold">{title}</h1>
      <h2 className="max-w-prose text-sm font-light text-primary/70">
        {description}
      </h2>
    </header>
  );
};
