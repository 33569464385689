import * as React from "react";
import { useState } from "react";

import { Icons } from "@/components/Icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/cn";

import { useGetProductTypeahead } from "./useGetProductTypeahead";

type Props = {
  labelName: string;
  isLowConfidence: boolean;
  currValue: string;
  setMode: React.Dispatch<React.SetStateAction<"VIEW" | "EDIT">>;
  handleSubmitFeedback: (content: string) => void;
  transformationCategory?: string;
};

export function ProductTypeahead({
  currValue,
  setMode,
  isLowConfidence,
  handleSubmitFeedback,
  labelName,
  transformationCategory,
}: Props) {
  const [typeaheadValue, setTypeaheadValue] = useState("");

  const { data, isFetching } = useGetProductTypeahead({
    transformationCategory,
    labelName,
    typeahead: typeaheadValue,
  });

  const typeaheadItems = (data?.products ?? [])
    // @ts-expect-error - TS doesn't know that labelName is a key of Product
    .filter((item) => item[labelName])
    .map((item) => {
      // @ts-expect-error - TS doesn't know that labelName is a key of Product
      return item[labelName];
    });

  return (
    <Popover
      defaultOpen={true}
      onOpenChange={(val) => {
        setMode(val ? "EDIT" : "VIEW");
      }}
    >
      <PopoverTrigger asChild>
        <div className={"group relative flex h-full w-full flex-1 @container"}>
          <div
            className={cn(
              "duration-400 flex h-full w-full max-w-prose cursor-text items-start justify-start rounded-none border-2 border-amber-100 px-2 py-2.5 text-start text-sm tabular-nums transition-colors",
              isLowConfidence
                ? "bg-status-uncertain text-status-uncertain-foreground"
                : "bg-status-success text-foreground/70",
            )}
          >
            {currValue}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <Command>
          <CommandInput
            showIcon={false}
            placeholder="Input new value..."
            className="h-9"
            onInput={(e) => {
              console.log("input", { e: e.currentTarget.value });
              setTypeaheadValue(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                console.log("enter key pressed", { typeaheadValue });
                e.preventDefault();
                e.stopPropagation();
                if (typeaheadValue) {
                  handleSubmitFeedback(typeaheadValue);
                  setMode("VIEW");
                }
              }
            }}
          />
          {isFetching ? (
            <CommandEmpty className="roundedshadow mt-4 h-12 animate-pulse bg-muted/30"></CommandEmpty>
          ) : (
            <CommandEmpty>No products found.</CommandEmpty>
          )}
          <CommandGroup>
            {typeaheadItems.map((item, i) => (
              <CommandItem
                key={i}
                value={item}
                onSelect={(selectValue) => {
                  if (selectValue) {
                    handleSubmitFeedback(selectValue.toLocaleUpperCase());
                    setMode("VIEW");
                  }
                }}
              >
                {item}
                <Icons.check
                  className={cn(
                    "ml-auto h-4 w-4",
                    typeaheadValue === item ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
