import "react";

import { useQuery } from "@tanstack/react-query";

import { BackButton } from "@/components/BackButton";
import { Icons } from "@/components/Icons";
import {
  getBadgeStatusFromSubmissionStatus,
  StatusBadge,
} from "@/components/StatusBadge";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { graphql } from "@/gql";
import { cn } from "@/lib/cn";
import { formatDateToLocal } from "@/lib/formatDateToLocal";
import { formatEnumLabel } from "@/lib/formatEnumLabel";
import { createQueryOptions } from "@/lib/hooks/graphql";
import { onDownloadFileToComputer } from "@/lib/onDownloadFileToComputer";

import { usePrepareFileDownload } from "../../../../../lib/hooks/queries/usePrepareFileDownload";
import { ResetSubmissionButton } from "./ResetSubmissionButton";
import { SubmissionHeaderDropdown } from "./SubmissionHeaderDropdown";
import { SubmissionHeaderSkeleton } from "./SubmissionHeaderSkeleton";
import { SubmissionHeaderTitles } from "./SubmissionHeaderTitles";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  submissionId: number;
}

export const getSubmissionHeaderQO = (submissionId: number) =>
  createQueryOptions({
    query: graphql(`
      query GetSubmissionCardHeaderData($where: SubmissionWhereUniqueInput!) {
        submission(where: $where) {
          createdAt
          status
          file {
            name
            id
          }
          outputFile {
            name
            id
          }
          type {
            id
            type
            name
          }
          vendor {
            id
            name
          }
        }
      }
    `),
    variables: { where: { id: submissionId } },
  });

export function SubmissionHeader({ submissionId, className, ...props }: Props) {
  const queryOpts = getSubmissionHeaderQO(submissionId);
  const { data, isLoading } = useQuery(queryOpts);
  const submission = data?.submission;

  const prepareFileDownload = usePrepareFileDownload({
    onDownload: onDownloadFileToComputer,
  });
  const { id: fileId, name: fileName } = submission?.file ?? {};
  const onDownload = () => {
    if (!fileId || !fileName) return;
    prepareFileDownload(fileId, fileName);
  };

  if (isLoading) return <SubmissionHeaderSkeleton />;
  if (!submission) return <div>Submission not found</div>;

  return (
    <header
      className={cn("flex h-16 flex-row justify-between", className)}
      {...props}
    >
      <div className="flex items-start gap-4">
        <h2 className="flex items-center ">
          <Button
            onClick={onDownload}
            variant={"outline"}
            className="group mr-4 h-14 w-14 rounded-full p-3"
          >
            <Icons.submission className="h-6 w-6 group-hover:hidden" />
            <Icons.download className="hidden h-6 w-6 group-hover:block" />
          </Button>
          <SubmissionHeaderTitles />
        </h2>

        <div className="flex gap-3 ">
          <Badge>{formatDateToLocal(submission.createdAt)}</Badge>
          <StatusBadge
            status={getBadgeStatusFromSubmissionStatus(submission.status)}
          >
            {formatEnumLabel(submission.status)}
          </StatusBadge>
        </div>
      </div>

      <div className="flex gap-3">
        {submission.status === "FAILED" && (
          <ResetSubmissionButton submissionId={submissionId} />
        )}

        <SubmissionHeaderDropdown />

        <BackButton relative="history" />
      </div>
    </header>
  );
}
