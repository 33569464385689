import { useSuspenseQuery } from "@tanstack/react-query";

import { graphql } from "@/gql/gql";
import { SubmissionTypesQuery } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

import { useDecodedJwtCookie } from "./Auth0";

const query = graphql(`
  query SubmissionTypes($where: SubmissionTypeWhereInput) {
    submissionTypes(where: $where) {
      id
      createdAt
      updatedAt
      name
      description
      organizationId
      outputColumns
      type
    }
  }
`);

export type OrgSubmissionType = SubmissionTypesQuery["submissionTypes"][0];

export const getOrgSubmissionTypesQueryOptions = ({
  organizationId,
}: {
  organizationId: number | undefined;
}) => {
  return createQueryOptions({
    query,
    variables: {
      where: {
        organizationId: {
          equals: organizationId,
        },
      },
    },
  });
};

export const useCurrentOrgSubmissionTypesQuery = () => {
  const { decodedJwt } = useDecodedJwtCookie();
  const organizationId = decodedJwt?.claims?.orgId;

  const options = getOrgSubmissionTypesQueryOptions({
    organizationId,
  });

  return useSuspenseQuery(options);
};
