import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import { Icons } from "@/components/Icons";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { TextInput } from "@/components/ui/text-input";
import { getLabelValue } from "@/lib/getLabelValue";

import {
  Label as LabelType,
  TransformationPredictionsTableAgGridParams,
} from "../../../submission/:submissionId/corrections/table/useGetTransformationsPredictionsTableColDefs";

type Props = {
  gridApi: AgGridReact["api"] | undefined;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  onClose?: () => void;
};

export const FindAndReplaceDialog = ({
  isOpen,
  setIsOpen,
  onClose,
  gridApi,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [replaceValue, setReplaceValue] = useState<string>("");
  const [columns, setColumns] = useState<
    {
      headerName: string;
      selected: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (gridApi != null) {
      const colDefs = gridApi.getColumnDefs();
      const columns = (colDefs ?? []).map((col) => ({
        headerName: col.headerName as string,
        selected: true,
      }));
      setColumns(columns);
    }
  }, [gridApi]);

  const onReplaceAll = () => {
    if (gridApi != null) {
      const selectedCols = columns.filter((col) => col.selected);
      const selectedColHeaders = selectedCols.map((col) => col.headerName);

      // iterate over all rows and replace the values that match the search value and are in the selected columns
      gridApi.forEachNode((untypedNode) => {
        const node =
          untypedNode as unknown as TransformationPredictionsTableAgGridParams;
        selectedColHeaders.forEach((header) => {
          const oldValue = node.data[header];

          // if the old value is a string
          if (typeof oldValue === "string" && oldValue === searchValue) {
            node.setDataValue(header, replaceValue);
          } // otherwise it's a label
          else if (typeof oldValue === "object") {
            const label = oldValue as LabelType;
            // if feedback exists take the last content as the value, otherwise take the value
            const labelValueorFeedback = getLabelValue(label);
            if (labelValueorFeedback === searchValue) {
              const newData = {
                ...label,
                feedback: [
                  ...label.feedback,
                  {
                    content: replaceValue,
                    upvote: true,
                    id: 6969420,
                  },
                ],
              };
              node.setDataValue(header, newData);
            }
          }
        });
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        onClose && onClose();
        setIsOpen(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Find and Replace</DialogTitle>
          <DialogDescription>
            Bulk replace any values in the table with a new value. This only
            works on exact cell matches.
          </DialogDescription>
        </DialogHeader>

        <section className="space-y-4">
          {/* Column Selector */}
          <div className="space-y-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="flex justify-between gap-2"
                >
                  <p>Applied Columns</p>
                  <Icons.chevronsDown className="h-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                {columns.map((col) => (
                  <DropdownMenuCheckboxItem
                    key={col.headerName}
                    checked={col.selected}
                    onSelect={(e) => e.preventDefault()}
                    onCheckedChange={(checked) => {
                      setColumns((prev) =>
                        prev.map((c) =>
                          c.headerName === col.headerName
                            ? { ...c, selected: checked }
                            : c,
                        ),
                      );
                    }}
                  >
                    {col.headerName}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>

            <ul className="mt-4 flex flex-wrap gap-2">
              {columns
                .filter((col) => col.selected)
                .map((col) => (
                  <li key={col.headerName}>
                    <Badge>{col.headerName}</Badge>
                  </li>
                ))}
            </ul>
          </div>

          <div className="space-y-1">
            <Label htmlFor="find">Search For Value...</Label>
            <TextInput
              onChange={(e) => setSearchValue(e.target.value)}
              type="text"
              id="find"
            />
            <p className="text-xs text-primary/50">
              If left empty we will use empty values
            </p>
          </div>
          <div className="space-y-1">
            <Label htmlFor="replace">Replace With...</Label>
            <TextInput
              onChange={(e) => setReplaceValue(e.target.value)}
              type="text"
              id="replace"
            />
          </div>
        </section>

        <DialogFooter>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onReplaceAll();
              setIsOpen(false);
            }}
          >
            Replace All
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
