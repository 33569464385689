// import { XSquareIcon } from "lucide-react";

import { MultiStepLoader as Loader } from "../(stuff)/multi-step-loader";

const loadingStates = [
  {
    text: "Listening to dial up tone",
  },
  {
    text: "Receiving your document through fax",
  },
  {
    text: "Asking mom for money",
  },
  {
    text: "Raising seed round",
  },
  {
    text: "Waking interns up",
  },
  {
    text: "Checking data by hand",
  },
  {
    text: "Collecting two by fours",
  },
  {
    text: "Waiting for the paint to dry",
  },
  {
    text: "Shipping without testing",
  },
  {
    text: "Regretting that decision",
  },
  {
    text: "Going to the bar",
  },
  {
    text: "Telling the computer to hurry up",
  },
  {
    text: "Building additional pylons",
  },
  {
    text: "Reticulating spines",
  },
  {
    text: "Brewing another pot of coffee",
  },
  {
    text: "Counting sheep",
  },
  {
    text: "Debugging the debugger",
  },
  {
    text: "Rewiring the internet",
  },
  {
    text: "Spinning up hamsters",
  },
  {
    text: "Fixing the flux capacitor",
  },
  {
    text: "Consulting the Magic 8-Ball",
  },
  {
    text: "Rolling for initiative",
  },
  {
    text: "Performing dark rituals",
  },
  {
    text: "Shuffling the deck",
  },
  {
    text: "Recharging batteries",
  },
  {
    text: "Starting the quantum leap",
  },
  {
    text: "Polishing the crystal ball",
  },
  {
    text: "Summoning the wizard",
  },
  {
    text: "Exploring the matrix",
  },
  {
    text: "Defragmenting reality",
  },
  {
    text: "Launching into hyperspace",
  },
  {
    text: "Aligning the chakras",
  },
  {
    text: "Negotiating with AI overlords",
  },
];

type Props = {
  loading: boolean;
};

export function MultiStepLoader({ loading }: Props) {
  return (
    <div className="flex h-[60vh] w-full items-center justify-center">
      {/* Core Loader Modal */}
      <Loader loadingStates={loadingStates} loading={loading} duration={2000} />

      {/* {loading && (
        <button
          className="fixed right-4 top-4 z-[120] text-black dark:text-white"
          //   onClick={() => setLoading(false)}
        >
          <XSquareIcon className="h-10 w-10" />
        </button>
      )} */}
    </div>
  );
}
