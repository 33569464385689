import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { TailwindIndicator } from "./components/TailwindIndicator";

export function DevTools() {
  return (
    <>
      <ReactQueryDevtools />
      <TailwindIndicator className="bottom-[4.5rem] left-auto right-3" />
    </>
  );
}
