import { Suspense } from "react";
import { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";

import { DashboardScreenFallback } from "@/components/DashboardScreenFallback";
import { Icons } from "@/components/Icons";
import { Organization } from "@/gql/graphql";
import { loadQuery } from "@/lib/hooks/graphql";

import { AccountGeneralPage } from "./account/general/AccountGeneralPage";
import OrgDataActionPage from "./organization/data-action/OrgDataActionPage";
import { getQueryOptions as getTransformationTypeListQueryOptions } from "./organization/data-action/TransformationTypeList";
import { ExportPage } from "./organization/export/ExportPage";
import { OrgGeneralPage } from "./organization/general/OrgGeneralPage";
import { MemberEditPage } from "./organization/member/[userId]/MemberEditPage";
import { MembersPage } from "./organization/member/MembersPage";
import { SubmissionTypeEditPage } from "./organization/submission-type/[submissionTypeId]/SubmissionTypeEditPage";
import { OrgSubmissionTypePage } from "./organization/submission-type/SubmissionTypesPage";

export const settingsRoutes = [
  {
    id: "Organization",
    path: "org/:organizationId",
    Icon: Icons.building,
    loader: async ({ params }: LoaderFunctionArgs) => {
      let organizationId: Organization["id"] = Number(params.organizationId);

      try {
        organizationId = z.number().parse(organizationId);
      } catch (error) {
        console.error("Invalid organizationId", error);
        return;
      }

      const tTLQO = getTransformationTypeListQueryOptions(organizationId);
      loadQuery(tTLQO);

      return null;
    },
    children: [
      {
        id: "Org General",
        name: "General",
        path: "",
        showInNav: true,
        element: <OrgGeneralPage />,
      },
      {
        id: "Submission Types",
        name: "Submissions",
        path: "submission-type",
        showInNav: true,
        element: <OrgSubmissionTypePage />,
      },
      {
        id: "Submission Type",
        name: "Submission",
        path: "submission-type/:submissionTypeId",
        element: <SubmissionTypeEditPage />,
      },
      {
        id: "Data Actions",
        name: "Data Actions",
        path: "data-action",
        showInNav: true,
        element: <OrgDataActionPage />,
      },
      {
        id: "Members",
        name: "Members",
        path: "member",
        showInNav: true,
        element: <MembersPage />,
      },
      {
        id: "Member",
        name: "Member",
        path: "member/:userId",
        element: (
          <Suspense fallback={<DashboardScreenFallback />}>
            <MemberEditPage />
          </Suspense>
        ),
      },
      {
        id: "Export",
        name: "Export Data",
        path: "export",
        showInNav: true,
        element: <ExportPage />,
      },
    ],
  },
  {
    id: "Account",
    path: "account/:userId",
    Icon: Icons.user,
    children: [
      {
        id: "Account General",
        name: "General",
        path: "",
        showInNav: true,
        element: <AccountGeneralPage />,
      },
      // {
      //   id: "Account Security",
      //   name: "Security",
      //   path: "security",
      //   showInNav: true,
      //   element: <AccountSecurityPage />,
      // },
    ],
  },
];
