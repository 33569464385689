import { Suspense } from "react";
import {
  createBrowserRouter,
  createSearchParams,
  Link,
  Outlet,
  redirect,
  resolvePath,
  RouteObject,
} from "react-router-dom";
import { match, P } from "ts-pattern";
import { z } from "zod";

import { DashboardLayout } from "@/app/dashboard/DashboardLayout";
import ErrorPage from "@/app/ErrorPage";
import App from "@/app/layout";
import { VendorUploadPage2 } from "@/app/vendor-submit/:vendorId/home/VendorUploadPage2";
import {
  NORMAL_CONFIG_CREATE,
  NORMAL_CONFIG_UPDATE,
  NORMAL_DIALOG_QUERY_PARAM,
  NormalizeMappingPage,
} from "@/app/vendor-submit/:vendorId/presubmission/:presubmissionId/normalize-mapping/NormalizeMappingPage";
import { YourExtractionsPage } from "@/app/vendor-submit/:vendorId/submission/:submissionId/corrections/YourExtractionsPage";
import { LoadingPage } from "@/app/vendor-submit/:vendorId/submission/:submissionId/LoadingPage";
import { VendorSubmissionLayout } from "@/app/vendor-submit/:vendorId/submission/:submissionId/VendorSubmissionLayout";

import { AuthenticationGuard } from "./app/AuthenticationGuard";
import { dashboardRoutes } from "./app/dashboard/dashboardRoutes";
import { CreateCustomSchemaPage } from "./app/document-extract/create-custom-schema";
// import { CreateCustomSchemaPage } from "./app/document-extract/old-create-custom-schema-page";
import { DocumentExtractLayout } from "./app/document-extract/layout";
import { DocumentExtractHomePage } from "./app/document-extract/page";
import { ResultsPage } from "./app/document-extract/results-page";
import { UploadPage } from "./app/document-extract/upload-page";
import { Login } from "./app/login/page";
import { Logout } from "./app/logout/page";
import { ValidationPage } from "./app/vendor-submit/:vendorId/presubmission/:presubmissionId/validation/validation-page";
import { getVendorSubmissionTypesQueryOptions } from "./app/vendor-submit/:vendorId/submission/:submissionId/confirm-submission-type/getVendorSubmissionTypesQueryOptions";
import { TransformationPage } from "./app/vendor-submit/:vendorId/submission/:submissionId/corrections/TransformationPage";
import { getSubmissionStatusQueryOptions } from "./app/vendor-submit/:vendorId/submission/:submissionId/loader";
import { SummaryPage } from "./app/vendor-submit/:vendorId/submission/:submissionId/summary/SummaryPage";
import { buttonVariants } from "./components/ui/button";
import { Submission, SubmissionStatus, Vendor } from "./gql/graphql";
import { loadQuery } from "./lib/hooks/graphql";
import { getDecodedJwtCookie } from "./lib/hooks/queries/Auth0";
import { getUserQueryOptions } from "./lib/hooks/queries/User";

export const router = createBrowserRouter([
  {
    path: "/",
    Component: App,
    ErrorBoundary: ErrorPage,
    children: [
      {
        id: "Old Splash Route",
        path: "",
        loader: () => {
          return redirect("/dashboard");
        },
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        id: "Document Extract",
        path: "document-extract",
        element: <DocumentExtractLayout />,
        children: [
          {
            id: "Landing",
            path: "",
            element: <DocumentExtractHomePage />,
          },
          {
            id: "Upload",
            path: "upload",
            element: <UploadPage />,
          },
          {
            id: "Custom",
            path: "custom",
            element: <CreateCustomSchemaPage />,
          },
          {
            id: "Reults",
            path: "results",
            element: <ResultsPage />,
          },
        ],
      },
      {
        id: "Dashboard",
        path: "dashboard",
        element: <AuthenticationGuard component={DashboardLayout} />,
        children: dashboardRoutes,
        loader: () => {
          const decodedJwt = getDecodedJwtCookie();
          if (decodedJwt === null) return null;

          const options = getUserQueryOptions({ id: decodedJwt?.claims.id });
          // ensure user is being fetched prior to rendering dashboard
          return loadQuery(options);
        },
      },
      {
        path: "vendor-submit",
        loader: async ({ params }) => {
          const getOKTAJwt = async () => "fake-jwt";
          const getVendorIdFromOKTAJwt = async (): Promise<Vendor["id"]> => 2;
          const vendorId = params?.vendorId;

          const oktaJwt = await getOKTAJwt();

          // if no OKTA JWT, redirect to... somewhere...
          if (!oktaJwt) return redirect("/vendor-auth-messed-up");

          // if no vendorId in params, find it via the OKTA JWT/our db and redirect to the correct vendor
          if (!vendorId) {
            const vendorIdFromOKTA = await getVendorIdFromOKTAJwt();
            return redirect(`/vendor-submit/${vendorIdFromOKTA}`);
          }

          return null;
        },
        element: <Outlet />,

        children: [
          {
            path: ":vendorId", // putting vendorId in the path allows org members to access a vendor's submit page
            element: <Outlet />,
            children: [
              // ASSIGNED TO: @ben
              {
                path: "",
                loader: async ({ params, request }) => {
                  let vendorId: Vendor["id"] = Number(params.vendorId);

                  try {
                    vendorId = z.number().parse(vendorId); // catches NaN
                  } catch (e) {
                    if (e instanceof z.ZodError)
                      console.error("vendorId is not valid", e.errors);
                    return redirect("/vendor-auth-messed-up");
                  }

                  loadQuery(getVendorSubmissionTypesQueryOptions(vendorId));

                  return null;
                },
                element: (
                  <Suspense>
                    <VendorUploadPage2 />
                  </Suspense>
                ),
              },
              {
                path: "submission",
                element: <VendorSubmissionLayout />,
                loader: async ({ params }) => {
                  if (!params.submissionId) {
                    return redirect(`/vendor-submit/${params.vendorId}`);
                  }
                  return null;
                },
                children: [
                  {
                    path: ":submissionId",
                    element: <Outlet />,
                    // responsible for conditional routing of a particular submission
                    loader: async ({ params, request }) => {
                      let submissionId: Submission["id"] = Number(
                        params.submissionId,
                      );

                      try {
                        submissionId = z.number().parse(submissionId);
                      } catch (e) {
                        if (e instanceof z.ZodError)
                          console.error("submissionId is not valid", e.errors);
                        return redirect(`/vendor-submit/${params.vendorId}`);
                      }

                      const data = await loadQuery(
                        getSubmissionStatusQueryOptions(submissionId),
                      );

                      const status = data?.submission?.status;

                      const requestPath = new URL(request.url).pathname;
                      const baseSubmissionPath = `/vendor-submit/${params.vendorId}/submission/${submissionId}`;

                      return (
                        match(status)
                          .with(undefined, () => {
                            return redirect(
                              `/vendor-submit/${params.vendorId}`,
                            );
                          })
                          .with(
                            P.union(SubmissionStatus.AwaitingPreprocessing),
                            () => {
                              const hasType = false;
                              const hasMapping = hasType && false;
                              const hasCorrectMapping = hasMapping && false;

                              if (!hasType) {
                                const { pathname } = resolvePath(
                                  "confirm-submission-type",
                                  baseSubmissionPath,
                                );

                                if (requestPath !== pathname)
                                  return redirect(pathname);
                              } else if (!hasMapping || !hasCorrectMapping) {
                                const queryParams = createSearchParams({
                                  [NORMAL_DIALOG_QUERY_PARAM]: hasMapping
                                    ? NORMAL_CONFIG_UPDATE
                                    : NORMAL_CONFIG_CREATE,
                                });

                                const { pathname } = resolvePath(
                                  `normalize?${queryParams}`,
                                  baseSubmissionPath,
                                );

                                if (requestPath !== pathname)
                                  return redirect(pathname);
                              } else {
                                const { pathname } = resolvePath(
                                  "validation",
                                  baseSubmissionPath,
                                );

                                if (requestPath !== pathname)
                                  return redirect(pathname);
                              }
                            },
                          )
                          .with(
                            P.union(
                              SubmissionStatus.Pending,
                              SubmissionStatus.Processing,
                            ),
                            () => {
                              const { pathname } = resolvePath(
                                "loading",
                                baseSubmissionPath,
                              );

                              if (requestPath !== pathname)
                                return redirect(pathname);
                            },
                          )
                          .with(
                            P.union(
                              SubmissionStatus.Failed,
                              SubmissionStatus.Processed,
                              SubmissionStatus.VendorReview,
                            ),
                            () => {
                              const { pathname } = resolvePath(
                                "corrections",
                                baseSubmissionPath,
                              );

                              if (requestPath !== pathname)
                                return redirect(pathname);
                            },
                          )
                          .with(
                            P.union(
                              SubmissionStatus.VendorApproved,
                              SubmissionStatus.VendorRejected,

                              SubmissionStatus.OrgReview,
                              SubmissionStatus.OrgApproved,
                              SubmissionStatus.OrgRejected,

                              SubmissionStatus.ReadyForDelivery,
                              SubmissionStatus.Delivered,
                            ),
                            () => {
                              const { pathname } = resolvePath(
                                "summary",
                                baseSubmissionPath,
                              );

                              if (requestPath !== pathname)
                                return redirect(pathname);
                            },
                          )
                          .exhaustive() ?? null
                      );
                    },
                    // these screens are the main steps in the submission process
                    children: [
                      // ASSIGNED TO: @ben (frontend), @michael (backend inference endpoint)
                      // {
                      //   path: "confirm-submission-type",
                      //   loader: async ({ params }) => {
                      //     // Not sure if we need to do anything here yet
                      //     return null;
                      //   },
                      //   // TODO: add fallabck loading screen
                      //   element: <ConfirmSubmissionTypePage />,
                      // },
                      // ASSIGNED TO: @michael
                      {
                        path: "normalize",
                        loader: async ({ params }) => {
                          // Not sure if we need to do anything here yet
                          return null;
                        },
                        element: <NormalizeMappingPage />,
                      },
                      // ASSIGNED TO: @ben
                      {
                        path: "validation",
                        loader: async ({ params }) => {
                          // Not sure if we need to do anything here yet
                          return null;
                        },
                        element: <ValidationPage />,
                      },
                      // ASSIGNED TO: @michael
                      {
                        path: "loading",
                        element: <LoadingPage />,
                      },
                      // ASSIGNED TO: @ben
                      {
                        path: "corrections",
                        children: [
                          {
                            path: "",
                            element: (
                              // TODO: add fallback loading screen
                              <YourExtractionsPage />
                            ),
                          },
                          {
                            path: ":transformationId",
                            element: (
                              <Suspense>
                                <TransformationPage />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: "summary",
                        element: (
                          <Suspense>
                            <SummaryPage />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "vendor-auth-messed-up",
        element: (
          <div className="flex flex-col gap-4">
            The "Something went wrong with vendor Auth" Screen
            <div>
              <Link
                to="/vendor-submit"
                className={buttonVariants({ variant: "default" })}
              >
                Vendor Submit
              </Link>
            </div>
          </div>
        ),
      },
    ],
  },
] satisfies RouteObject[]);
