import { PredictionCardContentLabel } from "@/app/dashboard/training/PredictionCardContent";
import { PredictionRowsQuery } from "@/gql/graphql";

type Label =
  | PredictionRowsQuery["predictions"][0]["labels"][0]
  | PredictionCardContentLabel;

export const getLabelValue = (label: Label) => {
  let value = label.value;
  //  check the feedback array from the back to get the latest feedback or return null if no content
  if (label.feedback.length > 0) {
    label.feedback.forEach((feedback) => {
      if (feedback.content) {
        value = feedback.content;
      }
    });
  }
  return value;
};
