import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { DashboardScreen } from "../../../DashboardScreen";
import {
  UserInformationForm,
  UserInformationFormSkeleton,
} from "./UserInformationForm";

export function AccountGeneralPage() {
  const { data, isLoading } = useCurrentUserQuery();

  return (
    <DashboardScreen scrollable>
      {isLoading ? (
        <UserInformationFormSkeleton />
      ) : data?.user ? (
        <UserInformationForm user={data.user} />
      ) : null}
    </DashboardScreen>
  );
}
