import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/cn";

import { Field } from "../use-gen-custom-fields";
import { EditFieldModal } from "./edit-field-modal";

type FieldListProps = {
  fields: Field[];
  handleSaveField: (
    updatedField: Field,
    mode: "edit" | "add",
    editingField?: Field,
  ) => void;
  onRemoveField: (field: Field) => void;
  isNested?: boolean;
};

function convertTypeToString(type: Field["type"]) {
  if (type === "string") return "text";
  else if (type === "thing") return "thing (object)";
  else if (type === "collection") return "collection (array)";
  return type;
}

const FieldList = ({
  fields,
  isNested,
  handleSaveField,
  onRemoveField,
}: FieldListProps) => {
  return (
    <div className="space-y-2">
      {fields.map((field, index) => (
        <div
          key={index}
          className={cn("flex flex-col p-2", index !== 0 && "border-t")}
        >
          <div className="flex justify-between">
            <div>
              <div className="flex items-center gap-2">
                <p className="font-medium">{field.name}</p>
                <Button
                  onClick={() => {
                    onRemoveField(field);
                  }}
                  size={"icon"}
                  variant={"ghost"}
                  className="h-4 w-4 rounded-full  text-primary/70"
                >
                  <Icons.remove />
                </Button>
              </div>
              <p className="text-sm text-gray-500">
                Type: {convertTypeToString(field.type)}
              </p>
              <p className="text-sm text-gray-500">
                Description: {field.description}
              </p>
            </div>
            {!isNested && (
              <EditFieldModal
                field={field}
                onSave={handleSaveField}
                mode="edit"
              />
            )}
          </div>
          {field.fields && field.fields.length > 0 && (
            <div className="ml-4 mt-2 flex items-center gap-4">
              <Icons.chevronsRight className="h-4 w-4 text-gray-500" />
              <div>
                {/* <h4 className="text-sm font-semibold">Nested Fields:</h4> */}
                <FieldList
                  onRemoveField={onRemoveField}
                  handleSaveField={handleSaveField}
                  fields={field.fields}
                  isNested={true}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FieldList;
