import { useDroppable } from "@dnd-kit/core";

import { Card } from "@/components/ui/card";
import { cn } from "@/lib/cn";

type Props = {
  children: React.ReactNode;
  id: string;
  dragging: boolean;
  className?: string;
};

export function Droppable({ children, id, className, dragging }: Props) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <Card
      className={cn(
        "relative",
        {
          "bg-card": isOver,
        },
        className,
      )}
      ref={setNodeRef}
      aria-label="Droppable region"
    >
      {children}
    </Card>
  );
}
