import { useParams } from "react-router-dom";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import {
  RouterTabs,
  RouterTabsContent,
  RouterTabsList,
  RouterTabsTrigger,
} from "@/components/RouterTabs";
import { Separator } from "@/components/ui/separator";
import { usePathRedirect } from "@/lib/hooks/routing";

import { SubmissionHeader } from "./(header)/SubmissionHeader";

export function SubmissionLayout() {
  const { submissionId } = useParams();

  // todo: remove this in favor of a redirect at the router level
  usePathRedirect({
    matchPath: "dashboard/submissions/:submissionId",
    to: "corrections",
  });

  return (
    <DashboardScreen className="grid grid-rows-[4rem_auto_1fr] gap-4 px-0 pb-0">
      <SubmissionHeader submissionId={Number(submissionId)} className="px-4" />
      <Separator />

      <RouterTabs className="flex flex-col px-4">
        <RouterTabsList className="w-fit">
          <RouterTabsTrigger className="w-56" to="corrections">
            Corrections
          </RouterTabsTrigger>
          <RouterTabsTrigger to="analytics" className="w-56">
            Analytics
          </RouterTabsTrigger>
        </RouterTabsList>
        <RouterTabsContent className=" flex-1" />
      </RouterTabs>
    </DashboardScreen>
  );
}
