import { Icons } from "@/components/Icons";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

export const CorrectionsPageSkeleton = () => {
  return (
    <ul className="flex w-full flex-col pt-6 md:px-16">
      <Badge variant={"outline"} className="mb-2 h-6 self-end">
        ?/?
      </Badge>
      {Array.from({ length: 5 }).map((_, index) => {
        return (
          <li
            key={index}
            className="flex w-full flex-1 flex-col items-center gap-4 pb-4"
          >
            <Card key={index} className="w-full">
              <CardHeader className="flex flex-row items-center justify-between">
                <div className="flex items-center">
                  <div className="mr-4 flex h-10 w-10 items-center justify-center rounded-full border"></div>
                  <div className="space-y-2">
                    <CardTitle className="h-8 w-56 animate-pulse bg-primary/20"></CardTitle>
                    <CardDescription className="max-w-2xl">
                      <span className="h-4 w-32 animate-pulse bg-primary/20"></span>
                    </CardDescription>
                  </div>
                </div>
                <Button disabled variant={"outline"}>
                  View
                </Button>
              </CardHeader>
              <Separator />
              <CardContent className="pl-22 flex gap-12 bg-card pl-20 pt-6">
                {/* line up 3 round circles and titles  */}
                {Array.from({ length: 3 }).map((_, index) => {
                  return (
                    <div key={index} className="flex items-center gap-2">
                      <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary/20 text-xs"></div>
                      <p className="h-4 w-20  animate-pulse bg-primary/20"></p>
                    </div>
                  );
                })}
              </CardContent>
            </Card>
            {index !== 5 - 1 && (
              <Icons.chevronsDown className="h-6 w-6 text-gray-500" />
            )}
          </li>
        );
      })}
    </ul>
  );
};
