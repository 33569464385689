import { ColumnFiltersState, Table } from "@tanstack/react-table";

import { GlobalFilterOption } from "@/components/TableFilterButton";
import { CONFIDENCE_INTERVALS } from "@/lib/constants/confidenceIntervals";

import { PredictionRowType } from "./PredictionTableColumns";

type Props = {
  table: Table<PredictionRowType>;
  predictions: PredictionRowType[];
  setGlobalFilter: React.Dispatch<
    React.SetStateAction<[number, number] | void>
  >;
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
};

export const useGetPredictionTableFilterOptions = ({
  table,
  predictions,
  setColumnFilters,
  setGlobalFilter,
}: Props) => {
  const globalFilterOptions: GlobalFilterOption[] = [
    // {
    //   name: "Product Type Test",
    //   colId: "Product Type",
    //   onSelect: () => {
    //     setColumnFilters((filters) => [
    //       ...filters,
    //       {
    //         id: "Product Type",
    //         value: "Part",
    //       },
    //     ]);
    //   },
    //   onUnselect: () => {
    //     setColumnFilters((filters) =>
    //       filters.filter((filter) => filter.id !== "Product Type"),
    //     );
    //   },
    // },
    {
      name: `Low Confidence`,
      count: predictions.filter((prediction) => {
        // iterate over the predictions object and check that the confidence is less than 0.8
        const isLowConfidence = prediction.labels.some((label) => {
          return label.confidence < CONFIDENCE_INTERVALS.Low[1];
        });

        return isLowConfidence;
      }).length,
      colId: "Global",
      onSelect: () => {
        setGlobalFilter([
          CONFIDENCE_INTERVALS.Low[0],
          CONFIDENCE_INTERVALS.Low[1],
        ]);
      },
      onUnselect: () => {
        setGlobalFilter(undefined);
      },
    },
    {
      name: `Med Confidence`,
      count: predictions.filter((prediction) => {
        // iterate over the predictions object and check that the confidence is less than 0.8
        const isMidConfidence = prediction.labels.some((label) => {
          return (
            label.confidence < CONFIDENCE_INTERVALS.Mid[1] &&
            label.confidence > CONFIDENCE_INTERVALS.Mid[0]
          );
        });

        return isMidConfidence;
      }).length,
      colId: "Global",
      onSelect: () => {
        setGlobalFilter([
          CONFIDENCE_INTERVALS.Mid[0],
          CONFIDENCE_INTERVALS.Mid[1],
        ]);
      },
      onUnselect: () => {
        setGlobalFilter(undefined);
      },
    },
    {
      name: `High Confidence`,
      count: predictions.filter((prediction) => {
        // iterate over the predictions object and check that the confidence is less than 0.8
        const isHighConfidence = prediction.labels.some((label) => {
          return (
            label.confidence < CONFIDENCE_INTERVALS.High[1] &&
            label.confidence > CONFIDENCE_INTERVALS.High[0]
          );
        });

        return isHighConfidence;
      }).length,
      colId: "Global",
      onSelect: () => {
        setGlobalFilter([
          CONFIDENCE_INTERVALS.High[0],
          CONFIDENCE_INTERVALS.High[1],
        ]);
      },
      onUnselect: () => {
        setGlobalFilter(undefined);
      },
    },
  ];

  return globalFilterOptions;
};

export const getFilterOptionFromValue = (
  value: [number, number] | void,
  filterOptions: GlobalFilterOption[],
) => {
  if (!value) {
    return;
  }
  if (
    value[0] === CONFIDENCE_INTERVALS.Low[0] &&
    value[1] === CONFIDENCE_INTERVALS.Low[1]
  ) {
    return filterOptions.find((option) => option.name === "Low Confidence");
  } else if (
    value[0] === CONFIDENCE_INTERVALS.Mid[0] &&
    value[1] === CONFIDENCE_INTERVALS.Mid[1]
  ) {
    return filterOptions.find((option) => option.name === "Med Confidence");
  } else if (
    value[0] === CONFIDENCE_INTERVALS.High[0] &&
    value[1] === CONFIDENCE_INTERVALS.High[1]
  ) {
    return filterOptions.find((option) => option.name === "High Confidence");
  }
};
