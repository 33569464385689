import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { graphql } from "@/gql";
import { cn } from "@/lib/cn";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

import { SettingsForm } from "../../SettingsForm";

const orgNameSchema = z.object({
  name: z.string(),
});

interface OrgNameFormProps extends React.ComponentProps<typeof Card> {
  initName: string;
}

export const OrgNameForm = ({ initName, ...props }: OrgNameFormProps) => {
  const { organizationId } = useParams();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useGraphqlMutation({
    mutation: graphql(`
      mutation Mutation(
        $data: OrganizationUpdateInput!
        $where: OrganizationWhereUniqueInput!
      ) {
        updateOneOrganization(data: $data, where: $where) {
          name
          id
        }
      }
    `),
    onSuccess: () => {
      toast("Success", {
        description: `Organization name updated.`,
        duration: 2000,
      });
      queryClient.invalidateQueries({
        queryKey: ["organization", `organizationId=${organizationId}`],
      });
    },
  });

  const form = useForm<z.infer<typeof orgNameSchema>>({
    resolver: zodResolver(orgNameSchema),
    defaultValues: {
      name: initName,
    },
  });

  useEffect(() => {
    form.reset({
      name: initName,
    });
  }, [initName]);

  const onSubmit = (values: z.infer<typeof orgNameSchema>) => {
    mutate({
      data: {
        name: {
          set: values.name,
        },
      },
      where: {
        id: Number(organizationId),
      },
    });
  };

  return (
    <SettingsForm
      {...props}
      className={cn("max-w-3xl", props.className)}
      form={form}
      onFormSubmit={onSubmit}
      isPending={isPending}
      title="Organization Information"
      description="Update your organization's information."
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input {...field} className="w-96" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </SettingsForm>
  );
};

export const OrgNameFormSkeleton = ({
  ...props
}: Omit<OrgNameFormProps, "initName">) => {
  return (
    <Card {...props} className={cn("max-w-3xl animate-pulse", props.className)}>
      <CardHeader>
        <CardTitle>Organization Information</CardTitle>
        <CardDescription>
          Update your organization's information.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col gap-4 ">
        <FormItem>
          <Label className="text-muted-foreground">Name</Label>
          <Input className="w-96" disabled />
        </FormItem>
      </CardContent>
      <Separator className="mb-7" />
      <CardFooter className="flex justify-end gap-3">
        <Button variant="ghost" disabled>
          Discard Changes
        </Button>
        <Button disabled className="w-32">
          Save
        </Button>
      </CardFooter>
    </Card>
  );
};
