import { Suspense } from "react";

import { StatCardSkeleton } from "@/components/StatCardSkeleton";
import { cn } from "@/lib/cn";

import { useDashHomeStore } from "../dashHomeStore";
import { KPMCardSubmissionAccuracy } from "./KPMCardSubmissionAccuracy";
import { KPMCardSubmissionCount } from "./KPMCardSubmissionCount";
import { KPMCardSubmissionInProgress } from "./KPMCardSubmissionInProgress";
import { KPMCardTimeSaved } from "./KPMCardTimeSaved";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export function DashboardKPMs({ className }: Props) {
  const { dateRangeType, dateRange } = useDashHomeStore();

  return (
    <article
      className={cn("grid gap-4 sm:grid-cols-2 lg:grid-cols-4", className)}
    >
      <Suspense fallback={<StatCardSkeleton />}>
        <KPMCardSubmissionCount
          dateRange={dateRange}
          dateRangeType={dateRangeType}
        />
      </Suspense>
      <Suspense fallback={<StatCardSkeleton />}>
        <KPMCardSubmissionAccuracy
          dateRange={dateRange}
          dateRangeType={dateRangeType}
        />
      </Suspense>
      <Suspense fallback={<StatCardSkeleton />}>
        <KPMCardSubmissionInProgress
          dateRange={dateRange}
          dateRangeType={dateRangeType}
        />
      </Suspense>
      <Suspense fallback={<StatCardSkeleton />}>
        <KPMCardTimeSaved dateRange={dateRange} dateRangeType={dateRangeType} />
      </Suspense>
    </article>
  );
}
