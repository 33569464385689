import { getConfidenceCategory } from "./getConfidenceStyle";
import {
  ExternalFilter,
  FilterType,
} from "./TransformationPredictionsTableFilters";
import { TransformationPredictionsTableAgGridRow } from "./useGetTransformationsPredictionsTableColDefs";

type node = {
  data: TransformationPredictionsTableAgGridRow;
};

export function doesExternalFilterPass(
  untypedNode: any,
  externalFilters: ExternalFilter[],
): boolean {
  const node = untypedNode as node;
  //   console.log({ node });
  if (node.data) {
    // iterate over the row data - find the labels by checking if the data is a string or a Label - if it's a Label, check the confidence
    const confidenceCategories = Object.entries(node.data)
      .map(([key, value]) => {
        const cellData = value;
        // check if params.data is a string or a Label - skip if so
        if (typeof cellData === "string") {
          return;
        }

        const { feedback, confidence } = cellData;

        // if feedback exists, return high confidence
        if (feedback && feedback.length > 0) {
          return FilterType.HIGH.toLowerCase();
        }

        // iterate over confidence interval values and return the first one that is true, the key is the confidence category
        return getConfidenceCategory(confidence)?.toLowerCase();
      })
      .filter(Boolean) as string[];

    // if any of the confidences match the externalFilters, return true
    return confidenceCategories.some((confidenceCategory) =>
      externalFilters.some((filter) => filter.value === confidenceCategory),
    );
  }

  // default to show cell
  return true;
}
