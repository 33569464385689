import { useMemo } from "react";
import { NodeProps } from "reactflow";

import { useHeaderMappingStore } from "@/components/header-mapping/HeaderMappingStore";
import { cn } from "@/lib/cn";

export const headerHandleStyle = {
  borderRadius: "0px",
  opacity: "0",
  height: "100%",
  width: "100%",
  zIndex: 20,
};

export interface HeaderNodeProps extends NodeProps {
  children?: React.ReactNode;
}
export const HeaderNode = ({
  id,
  data,
  children,
  dragging,
  ...props
}: HeaderNodeProps) => {
  const { activeNodeId, setActiveNodeId, edges } = useHeaderMappingStore();

  const isActive = useMemo(() => activeNodeId === id, [activeNodeId, id]);
  const activeEdges = useMemo(
    () =>
      edges.filter(
        (edge) => edge.source === activeNodeId || edge.target === activeNodeId,
      ),
    [edges, activeNodeId],
  );
  const isConnectedToActive = useMemo(
    () => activeEdges.some((edge) => edge.source === id || edge.target === id),
    [activeEdges, id],
  );

  return (
    <div
      onMouseEnter={() => {
        setActiveNodeId(id);
      }}
      onMouseLeave={() => {
        setActiveNodeId(null);
      }}
      className={cn(
        "group cursor-default overflow-hidden rounded-sm border border-card bg-background text-xs text-foreground transition-all duration-200 ease-in-out",
        (isActive || isConnectedToActive || dragging) && "border-white/80",
        dragging && "cursor-grabbing",
        "hover:border-white/80",

        activeNodeId &&
          !isActive &&
          !isConnectedToActive &&
          !dragging &&
          "opacity-10",
      )}
      style={{
        width: `148px`,
        zIndex: 10,
      }}
      {...props}
    >
      <div className="h-12 overflow-hidden bg-card p-2 text-card-foreground">
        {data.label}
      </div>
      {children}
    </div>
  );
};
