import { useCallback, useState } from "react";
import { QueryKey } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";

import { FindAndReplaceDialog } from "@/app/vendor-submit/:vendorId/presubmission/:presubmissionId/validation/find-and-replace-dialog";
import { Combobox } from "@/components/ui/combobox";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

import { getConfidenceCategory } from "./getConfidenceStyle";
import { TransformationPredictionsTableAgGridRow } from "./useGetTransformationsPredictionsTableColDefs";

export type ExternalFilter = {
  type: FilterType;
  value: string;
};

export enum FilterType {
  HIGH = "high",
  MID = "mid",
  LOW = "low",
}

enum ActionType {
  Download = "download",
  FindReplace = "find-replace",
  DisplayColumns = "display-columns",
  PinColumns = "pin-columns",
}

type Props = {
  queryKey: QueryKey;
  gridApi: AgGridReact["api"] | undefined;
  rows: TransformationPredictionsTableAgGridRow[];
  setExternalFilters: React.Dispatch<
    React.SetStateAction<
      {
        type: FilterType;
        value: string;
      }[]
    >
  >;
};

export const TransformationPredictionsTableFilters = ({
  queryKey,
  gridApi,
  rows,
  setExternalFilters,
}: Props) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  let lowConfidenceCellCount = 0;
  let midConfidenceCellCount = 0;
  let highConfidenceCellCount = 0;
  rows.forEach((row) => {
    Object.values(row).forEach((cell) => {
      if (typeof cell === "string") {
        return false;
      }
      if (getConfidenceCategory(cell.confidence) === FilterType.LOW) {
        lowConfidenceCellCount++;
      } else if (getConfidenceCategory(cell.confidence) === FilterType.MID) {
        midConfidenceCellCount++;
      } else if (getConfidenceCategory(cell.confidence) === FilterType.HIGH) {
        highConfidenceCellCount++;
      }
    });
  });

  const onDownload = useCallback(() => {
    if (gridApi) {
      gridApi.exportDataAsCsv();
    }
  }, []);

  const handleActionChange = useCallback(
    (item: ActionType) => {
      if (item === ActionType.Download) {
        onDownload();
      } else if (item === ActionType.FindReplace) {
        setDialogOpen(true);
      }
      // Additional cases for other actions
    },
    [onDownload],
  );

  return (
    <>
      <section className="flex gap-4 pb-2">
        <ToggleGroup
          variant="outline"
          type="multiple"
          defaultValue={[FilterType.LOW, FilterType.MID, FilterType.HIGH]}
          onValueChange={(val) => {
            setExternalFilters(
              val.map((v) => ({ type: v as FilterType, value: v as string })),
            );
          }}
        >
          <ToggleGroupItem value={FilterType.LOW} aria-label="Toggle valid">
            {`Low ${lowConfidenceCellCount}`}
          </ToggleGroupItem>
          <ToggleGroupItem
            defaultChecked
            value={FilterType.MID}
            aria-label="Toggle valid"
          >
            {`Mid ${midConfidenceCellCount}`}
          </ToggleGroupItem>
          <ToggleGroupItem
            defaultChecked
            value={FilterType.HIGH}
            aria-label="Toggle valid"
          >
            {`High ${highConfidenceCellCount}`}
          </ToggleGroupItem>
        </ToggleGroup>

        <Combobox
          placeholder="Actions"
          items={[
            { label: "Download", value: ActionType.Download },
            {
              label: "Find and Replace",
              value: ActionType.FindReplace,
            },
          ]}
          value=""
          onChange={(val) => handleActionChange(val as ActionType)}
        />
      </section>
      {isDialogOpen && (
        <FindAndReplaceDialog
          gridApi={gridApi}
          isOpen={isDialogOpen}
          setIsOpen={setDialogOpen}
        />
      )}
    </>
  );
};
