import { useMemo } from "react";

import { ProductTypeahead } from "@/app/dashboard/home/(submissions-list)/create-submission/ProductTypeahead";

import { getHeaderType } from "./get-header-type";
import {
  TypedOutputColumn,
  TypedOutputColumnTypes,
} from "./use-get-typed-output-columns";
import { validateCell } from "./validate-cell";

type Params = {
  headerValues: string[];
  typedOutputColumns: TypedOutputColumn[];
};

export const useGetColDefs = ({ headerValues, typedOutputColumns }: Params) => {
  // add metadata to the output columns

  return useMemo(() => {
    if (!headerValues) {
      return [];
    }

    const firstCol = {
      headerName: "Row",
      field: "rowIndex",
      colId: String(0),
      checkboxSelection: true,
      headerCheckboxSelection: true,
      flex: 0.7,
      cellParams: {
        type: TypedOutputColumnTypes.STRING,
      },
      enableCellChangeFlash: true,
      cellClassRules: {},
    };

    const dynamicCols = headerValues.map((header, i) => {
      const type = getHeaderType({ header, typedOutputColumns });

      const standardColDef = {
        headerName: header,
        field: header,
        enableCellChangeFlash: true,
        // The colID is +1 because excel rows are 1-indexed, header 1 is colId 1
        colId: header,
        cellParams: {
          type,
        },
        cellClassRules: {
          "cell-error": (params: any) => {
            const validationStatus = validateCell(params.value, type);
            // update row error state
            if (validationStatus !== true) {
              // params.data.errorState = true;
              // do I need to update the row?
              return true;
            }
            return false;
          },
        },
      };

      if (type === TypedOutputColumnTypes.SKU) {
        return {
          ...standardColDef,
          flex: 1,
          cellEditor: (p: { value: string }) => {
            return (
              <ProductTypeahead
                labelName={"Product Manufacturer SKU"}
                isLowConfidence={false}
                currValue={p.value}
                setMode={() => {}}
                handleSubmitFeedback={() => {}}
              />
            );
          },
        };
      } else if (type === TypedOutputColumnTypes.TEXTAREA) {
        return {
          ...standardColDef,
          flex: 2,
          cellEditorPopup: true,
          cellEditor: "agLargeTextCellEditor",
          cellEditorParams: {
            maxLength: 100,
          },
        };
      } else if (type === TypedOutputColumnTypes.DATE) {
        return {
          ...standardColDef,
          cellEditor: "agDateCellEditor",
        };
      } else if (type === TypedOutputColumnTypes.NUMBER) {
        return {
          ...standardColDef,
          type: "numericColumn",
          cellEditor: "agNumberCellEditor",
          cellDataType: "number",
        };
      } else {
        return standardColDef;
      }
    });
    return [firstCol, ...dynamicCols];
  }, [headerValues, typedOutputColumns]);
};
