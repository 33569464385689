import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { Icons } from "@/components/Icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SubmissionStatus } from "@/gql/graphql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";
import { usePrepareFileDownload } from "@/lib/hooks/queries/usePrepareFileDownload";
import { onDownloadFileToComputer } from "@/lib/onDownloadFileToComputer";

import { resetSubmissionMutation } from "./ResetSubmissionButton";
import { getSubmissionHeaderQO } from "./SubmissionHeader";
import { useSetSubmissionStatus } from "./useSetSubmissionStatus";

export function SubmissionHeaderDropdown() {
  const { submissionId } = useParams();
  const fileDownload = usePrepareFileDownload({
    onDownload: onDownloadFileToComputer,
  });
  const { mutate } = useGraphqlMutation({
    mutation: resetSubmissionMutation,
    onMutate: () => {
      toast.loading("Resetting submission...");
    },
    onSuccess: () => {
      document.location.reload();
    },
    onError: () => {
      toast.error("Failed to reset submission");
      console.error("Failed to reset submission");
    },
  });
  const options = getSubmissionHeaderQO(Number(submissionId));

  const { data } = useQuery(options);
  const submission = data?.submission;
  const file = submission?.file;
  const outputFile = submission?.outputFile;

  const { mutate: setSubmissionToReady } = useSetSubmissionStatus();

  if (!submission) {
    return null;
  }

  const handleReset = () => {
    if (submissionId === undefined) {
      throw new Error("submissionId is undefined");
    }

    mutate({
      submissionId: Number(submissionId),
    });
  };

  const handleSubmit = () => {
    if (submissionId === undefined) {
      throw new Error("submissionId is undefined");
    }
    setSubmissionToReady({
      data: {
        status: { set: SubmissionStatus.OrgApproved },
      },
      where: {
        id: Number(submissionId),
      },
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="w-24 gap-2 ">
          Actions...
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuLabel>Submission Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <DropdownMenuItem
                className="cursor-pointer gap-2"
                onSelect={(e) => {
                  e.preventDefault();
                }}
              >
                <DropdownMenuShortcut className="ml-0">
                  <Icons.send className="h-4 w-4" />
                </DropdownMenuShortcut>
                Mark Ready For Delivery
              </DropdownMenuItem>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone and will mark the submission as
                  finalized
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleSubmit}>
                  Continue
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <DropdownMenuItem
                className="cursor-pointer gap-2"
                onSelect={(e) => {
                  e.preventDefault();
                }}
              >
                <DropdownMenuShortcut className="ml-0">
                  <Icons.reprocess className="h-4 w-4" />
                </DropdownMenuShortcut>
                Reprocess
              </DropdownMenuItem>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone and will re-run the submission
                  pipeline for this file.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleReset}>
                  Continue
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="gap-2">
              <DropdownMenuShortcut className="ml-0">
                <Icons.download className="h-4 w-4" />
              </DropdownMenuShortcut>
              Download
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  className="cursor-pointer"
                  disabled={submission?.file?.id === undefined}
                  onClick={(e) => {
                    if (file) {
                      fileDownload(file.id, file.name);
                    } else {
                      throw new Error("file is undefined");
                    }
                    e.stopPropagation();
                  }}
                >
                  Input Data
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="cursor-pointer"
                  disabled={outputFile?.id === undefined}
                  onClick={(e) => {
                    if (outputFile) {
                      fileDownload(outputFile.id, outputFile.name);
                    } else {
                      throw new Error("outputFile is undefined");
                    }
                    e.stopPropagation();
                  }}
                >
                  Output Data
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
