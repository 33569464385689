import { ColumnDef } from "@tanstack/react-table";

import { MonoText } from "@/components/MonoText";
import { Checkbox } from "@/components/ui/checkbox";
import { graphql } from "@/gql";
import { PredictionRowsQuery } from "@/gql/graphql";
import { camelCaseToHeader } from "@/lib/string";

import { ColHeader } from "./ColHeader";
import { PredictionTableLabel } from "./PredictionTableLabel";

export const predictionRowsQuery = graphql(`
  query PredictionRows(
    $where: PredictionWhereInput
    $feedbackOrderBy: [FeedbackOrderByWithRelationAndSearchRelevanceInput!] = {
      createdAt: desc
    }
    $feedbackTake: Int = 1
  ) {
    predictions(where: $where) {
      transformation {
        type {
          category
        }
      }
      id
      createdAt
      metadata {
        key
        value
        updatedAt
      }
      labels {
        id
        value
        name
        confidence
        updatedAt
        feedback(orderBy: $feedbackOrderBy, take: $feedbackTake) {
          id
          upvote
          content
        }
      }
    }
  }
`);

export type PredictionRowType = PredictionRowsQuery["predictions"][number];

export type PredictionColumnDef = ColumnDef<PredictionRowType>;

type Props = {
  predictions: PredictionRowType[];
  setShowFilterState: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
};

export const SELECT_COL_ID = "Select";
export const METADATA_COL_ID = "Metadata";
export const LABELS_COL_ID = "Labels";

export const createPredictionColumnDefs = ({
  predictions,
  setShowFilterState,
}: Props): PredictionColumnDef[] => {
  if (predictions.length === 0) return [];

  const firstPrediction = predictions[0];
  const transformationCategory = firstPrediction.transformation.type.category;

  const { metadata } = firstPrediction;

  const otherCols: PredictionColumnDef[] = [
    {
      id: SELECT_COL_ID,
      header: ({ table }) => {
        const someButNotAllSelected =
          table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected();

        return (
          <Checkbox
            checked={
              table.getIsAllRowsSelected() ||
              (table.getIsSomeRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => {
              // If the user checks the head checkbox while some but not all rows are selected,
              if (value === true && someButNotAllSelected) {
                table.resetRowSelection();
                return;
              }

              table.toggleAllRowsSelected(!!value);
            }}
            aria-label="Select all"
          />
        );
      },
      cell: ({ row }) => (
        <div
          className="group flex h-full w-full cursor-pointer justify-center pt-3"
          onClick={() => row.toggleSelected(!row.getIsSelected())}
        >
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
            // className="opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100"
          />
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
      size: 30,
    },
    {
      header: (props) => {
        return (
          <ColHeader
            i={0}
            labelName={"Created At"}
            setShowFilterState={setShowFilterState}
            {...props}
          />
        );
      },
      id: "Created At",
      enableHiding: true,
      accessorFn: (row) => {
        return row.createdAt;
      },
      cell: ({ getValue }) => {
        return <div>{getValue() as string}</div>;
      },
    },
  ];

  const metadataCols: PredictionColumnDef[] = metadata.map(
    (colPredictionMeta, i) => {
      const { key } = colPredictionMeta;
      const title = camelCaseToHeader(key);
      return {
        header: (props) => {
          return (
            <ColHeader
              i={i}
              labelName={title}
              setShowFilterState={setShowFilterState}
              {...props}
            />
          );
        },
        id: title,
        enableHiding: true,
        accessorFn: (row) => {
          return row.metadata.find((meta) => {
            return meta.key === key;
          })?.value;
        },
        cell: ({ getValue }) => {
          return (
            <MonoText className="max-w-prose bg-transparent p-0 px-2 py-2.5 text-sm text-foreground">
              {getValue() as string}
            </MonoText>
          );
        },
      };
    },
  );

  const labelNames = firstPrediction.labels.map(({ name }) => name);

  const labelCols: PredictionColumnDef[] = labelNames.map((labelName, i) => {
    return {
      header: (props) => (
        <ColHeader
          i={i}
          labelName={labelName}
          setShowFilterState={setShowFilterState}
          {...props}
        />
      ),
      id: labelName,
      enableHiding: true,
      accessorFn: (row) => {
        const label = row.labels.find((label) => {
          return label.name === labelName;
        });

        if (!label?.value) return "";
        if (!label?.feedback) return label.value;

        return label.feedback.at(0)?.content ?? label.value;
      },
      cell: (ctx) => {
        const rowValues = ctx.row.original;
        const label = rowValues.labels.find((label) => {
          return label.name === labelName;
        });

        if (!label) {
          return <div className="font-medium text-red-800">No value</div>;
        }

        return (
          <PredictionTableLabel
            key={label.id}
            transformationCategory={transformationCategory ?? ""}
            labelName={labelName}
            labelValue={ctx.getValue() as string}
            confidence={label.confidence}
            labelId={label.id}
            hasFeedback={
              label.feedback?.length !== undefined && label.feedback.length > 0
            }
          />
        );
      },
    };
  });

  return [
    ...otherCols,
    ...metadataCols,
    {
      id: LABELS_COL_ID,
      header: LABELS_COL_ID,
      columns: [...labelCols],
    },
  ];
};
