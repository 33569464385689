import { useMemo } from "react";

import { ProductTypeahead } from "@/app/dashboard/home/(submissions-list)/create-submission/ProductTypeahead";
import { PredictionRowsQuery } from "@/gql/graphql";
import { getLabelValue } from "@/lib/getLabelValue";

import { getConfidenceStyle } from "./getConfidenceStyle";

export type Label = PredictionRowsQuery["predictions"][0]["labels"][0];
export type TransformationPredictionsTableAgGridRow = {
  [key: string]: string | Label;
};

// Call this a node after confirming functionality
export type TransformationPredictionsTableAgGridParams = {
  value: string;
  data: TransformationPredictionsTableAgGridRow;
  setDataValue: (header: string, value: string | Label) => void;
};

type Params = {
  headers: string[];
};

enum TransformationsPredictionsTableFixedColumns {
  PRODUCT_SKU = "productSKU",
  PRODUCT_DESCRIPTION = "productDescription",
}

export const useGetTransformationsPredictionsTableColDefs = ({
  headers,
}: Params) => {
  return useMemo(() => {
    const dynamicCols = headers.map((header, i) => {
      const standardColDef = {
        headerName: header,
        valueSetter: (
          params: TransformationPredictionsTableAgGridParams & {
            newValue: string | Label;
          },
        ) => {
          const newValue = params.newValue;
          if (typeof newValue === "string") {
            params.data[header] = newValue;
          } else {
            params.data[header] = {
              ...newValue,
            };
          }
          return true;
        },
        valueGetter: (params: TransformationPredictionsTableAgGridParams) => {
          const value = params.data[header];
          if (typeof value === "string") {
            return value;
          } else {
            // if there is feedback, return the last one
            if (value.feedback.length > 0) {
              return value.feedback[value.feedback.length - 1].content;
            } else {
              // if there is no feedback, return the value
              return value.value;
            }
          }
        },
        enableCellChangeFlash: true,
        colId: header,
        cellDataType: "label",

        cellEditor: (params: {
          data: TransformationPredictionsTableAgGridRow;
          node: any;
        }) => {
          const value = params.data[header];

          if (typeof value === "string") {
            return (
              <ProductTypeahead
                labelName={header}
                isLowConfidence={false}
                currValue={value}
                setMode={() => {}}
                handleSubmitFeedback={() => {}}
              />
            );
          } else {
            const node = params.node;
            const label = params.data[header] as Label;
            const onValueChange = (newValue: string) => {
              const newData = {
                ...label,
                feedback: [
                  ...label.feedback,
                  {
                    content: newValue,
                    upvote: true,
                    id: 6969420,
                  },
                ],
              };

              node?.setDataValue(header, newData);
            };

            const labelValueOrFeedback = getLabelValue(value);

            return (
              <ProductTypeahead
                labelName={header}
                isLowConfidence={false}
                currValue={labelValueOrFeedback}
                setMode={() => {}}
                handleSubmitFeedback={onValueChange}
              />
            );
          }
        },
        cellClassRules: {
          // TODO: create classes for low confidence, mid confidence, high confidence
          "cell-low": (params: TransformationPredictionsTableAgGridParams) => {
            return getConfidenceStyle({
              header,
              params,
              category: "low",
            });
          },
          "cell-mid": (params: TransformationPredictionsTableAgGridParams) => {
            return getConfidenceStyle({
              header,
              params,
              category: "mid",
            });
          },
          "cell-high": (params: TransformationPredictionsTableAgGridParams) => {
            return getConfidenceStyle({
              header,
              params,
              category: "high",
            });
          },
        },
      };

      if (header === TransformationsPredictionsTableFixedColumns.PRODUCT_SKU) {
        return {
          ...standardColDef,
          headerName: "Manufacturer SKU",
          field: header,
          flex: 1,
          editable: false,
          cellClassRules: {},
        };
      } else if (
        header ===
        TransformationsPredictionsTableFixedColumns.PRODUCT_DESCRIPTION
      ) {
        return {
          ...standardColDef,
          headerName: "Product Description",
          field: header,
          flex: 2,
          editable: false,
          cellClassRules: {},
          cellEditorParams: {
            maxLength: 100,
          },
        };
      } else {
        return standardColDef;
      }
    });
    return [...dynamicCols];
  }, [headers]);
};
