import { useParams } from "react-router-dom";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { useOrganizationQuery } from "@/lib/hooks/queries/Organization";

import { OrgNameForm, OrgNameFormSkeleton } from "./OrgNameForm";

export function OrgGeneralPage({}) {
  const { organizationId } = useParams();

  const { data, isLoading } = useOrganizationQuery({
    id: Number(organizationId),
  });

  return (
    <DashboardScreen className="flex flex-col gap-4" scrollable>
      {isLoading ? (
        <OrgNameFormSkeleton />
      ) : data?.organization ? (
        <OrgNameForm initName={data.organization.name} />
      ) : null}
    </DashboardScreen>
  );
}
