import { TypedOutputColumnTypes } from "./use-get-typed-output-columns";

type Params = {
  header: string;
  typedOutputColumns: { name: string; type: TypedOutputColumnTypes }[];
};

export const getHeaderType = ({ header, typedOutputColumns }: Params) => {
  const type =
    typedOutputColumns.find((col) => col.name === header)?.type ??
    TypedOutputColumnTypes.STRING;

  return type;
};
