import { useParams } from "react-router-dom";
import { z } from "zod";

import { useTypedSearchParams } from "@/lib/hooks/routing";

export const NORMAL_DIALOG_QUERY_PARAM = "normalize_dialog";
export const NORMAL_CONFIG_CREATE = "create";
export const NORMAL_CONFIG_UPDATE = "update";

export const schema = z.object({
  [NORMAL_DIALOG_QUERY_PARAM]: z.enum([
    NORMAL_CONFIG_CREATE,
    NORMAL_CONFIG_UPDATE,
  ]),
});

export const NormalizeMappingPage = () => {
  const { vendorId: vId, submissionId: sId } = useParams();

  const searchParams = useTypedSearchParams({ schema });
  console.log(searchParams);

  const vendorId = Number(vId);
  const submissionId = Number(sId);

  return (
    <div className="debug">
      <h1>Normalize Mapping Page</h1>

      <p>Vendor ID: {vendorId}</p>
      <p>Submission ID: {submissionId}</p>
      <p>Search Params: {JSON.stringify(searchParams)}</p>
    </div>
  );
};
