import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useUpdateOrgExportSettings = () => {
  return useGraphqlMutation({
    mutation: graphql(`
      mutation UpdateOrgExportDatabaseMutation(
        $data: ExportDatabaseUpdateInput!
        $where: ExportDatabaseWhereUniqueInput!
      ) {
        updateOneExportDatabase(data: $data, where: $where) {
          id
        }
      }
    `),
  });
};
