import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

import { SubmissionError } from "../useGetSubmissionErrors";
import { useGetErrorSolution } from "./useGetErrorSolution";

type Props = {
  errors: SubmissionError[];
  submissionType: string | undefined;
  vendorId: number | undefined;
};

export const SubmissionErrors = ({
  errors,
  submissionType,
  vendorId,
}: Props) => {
  const error = errors[0];

  const onClick = useGetErrorSolution({
    error,
    submissionType,
    vendorId,
  });

  return (
    <Card className="mb-6 flex w-full items-center justify-center gap-16 bg-black p-24">
      <div className="flex max-w-md flex-col space-y-4">
        <h2 className="text-3xl font-bold">Something went wrong</h2>
        <p className="text-lg">
          Sorry, the submission could not be processed because of a serious
          error.
        </p>
        <p className="break-words rounded bg-muted p-3 font-mono">
          {error?.message}
        </p>
        <Button
          disabled={!onClick}
          onClick={() => onClick?.()}
          className="bg-destructive hover:bg-destructive/80"
        >
          Resolve Issue
        </Button>
      </div>
      <Icons.error className="h-56 w-56 flex-grow" />
      {/* <img
        alt="Error"
        className="h-[200px] w-[600px]"
        height="200"
        src="/placeholder.svg"
        style={{
          aspectRatio: "200/600",
          objectFit: "cover",
        }}
        width="200"
      /> */}
    </Card>
  );
};
