import "react-router-dom";

import * as React from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { NavLink, NavLinkProps, Outlet } from "react-router-dom";

import { Tabs, TabsList } from "@/components/ui/tabs";
import { cn } from "@/lib/cn";
import { easeSnappy } from "@/lib/motion";

const RouterTabs = Tabs;
const RouterTabsList = TabsList;

interface RouterTabsTriggerProps extends NavLinkProps {
  children: React.ReactNode;
  className?: string;
}

const RouterTabsTrigger = ({
  children,
  className,
  ...props
}: RouterTabsTriggerProps) => {
  return (
    <NavLink
      className={({ isActive }) =>
        cn(
          "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
          isActive
            ? "bg-background text-foreground shadow-sm"
            : "text-muted-foreground",
          className,
        )
      }
      end={props.end || props.to === ""}
      {...props}
    >
      {children}
    </NavLink>
  );
};

const RouterTabsContent = ({ className }: { className?: string }) => {
  const [parent] = useAutoAnimate({
    duration: 125,
    easing: easeSnappy,
  });

  return (
    <div
      ref={parent}
      className={cn(
        "mt-4 flex ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        className,
      )}
    >
      <Outlet />
    </div>
  );
};

export { RouterTabs, RouterTabsList, RouterTabsTrigger, RouterTabsContent };
