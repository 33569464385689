import { Button } from "@/components/ui/button";
import { cn } from "@/lib/cn";

type ToggleButtonProps = {
  onClick: () => void;
  active: boolean;
  children: React.ReactNode;
  className?: string;
};

export function FilterPopoverToggleButton({
  onClick,
  active,
  children,
  className,
}: ToggleButtonProps) {
  return (
    <Button
      onClick={onClick}
      type="button"
      size="sm"
      variant="outline"
      className={cn(
        "",
        active && "border-primary text-secondary-foreground",
        className,
      )}
    >
      {children}
    </Button>
  );
}
