import { Field } from "../use-gen-custom-fields";

const itemFields: Field[] = [
  {
    name: "itemNumber",
    type: "text",
    description:
      "The number used to identify the item, generally the SKU of the product.",
  },
  {
    name: "itemSerialNumber",
    type: "text",
    description: "The serial number of the item.",
  },
  {
    name: "itemDescription",
    type: "text",
    description: "The full description of the item.",
  },
  {
    name: "itemPrice",
    type: "number",
    description: "The monetary price of the item sold.",
  },
  {
    name: "itemQuantity",
    type: "number",
    description: "The number of items sold.",
  },
  {
    name: "itemTotal",
    type: "number",
    description: "The total amount of the item sold after taxes and fees.",
  },
];

const invoiceSummaryFields: Field[] = [
  {
    name: "Invoice Number",
    type: "text",
    description: "The number used to identify the invoice as a whole.",
  },
  {
    name: "PON",
    type: "text",
    description: "The purchase order number.",
  },
  {
    name: "invoiceDate",
    type: "date",
    description: "The date the invoice was created.",
  },
  {
    name: "shipDate",
    type: "date",
    description: "The date the items were shipped.",
  },
  {
    name: "soldToName",
    type: "text",
    description: "The name of the person or company the items were sold to.",
  },
  {
    name: "soldToAddress",
    type: "address",
    description: "The address of the person or company the items were sold to.",
  },
  {
    name: "subtotal",
    type: "number",
    description: "The total amount of the items sold before taxes and fees.",
  },
  {
    name: "tax",
    type: "number",
    description: "The total amount of tax applied to the invoice.",
  },
  {
    name: "total",
    type: "number",
    description: "The total amount of the invoice after taxes and fees.",
  },
  {
    name: "currency",
    type: "text",
    description: "The currency used for the invoice.",
  },
];

export const invoiceFields: Field[] = [
  {
    name: "Invoice_Summary",
    type: "thing",
    description:
      "The overall summary of information on the invoice, not the individual items.",
    fields: invoiceSummaryFields,
  },
  {
    name: "Items",
    type: "collection",
    description: "All the items on the invoice.",
    fields: itemFields,
  },
];
