import { useLocalStorage } from "react-use";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/cn";

import { DashboardNavItem } from "./DashboardNavItem";
import { dashboardRoutes } from "./dashboardRoutes";
import { LogoutButton } from "./LogoutButton";

export function DashboardNav() {
  const [expanded, setExpanded] = useLocalStorage("nav-expanded", false);

  return (
    <>
      <ScrollArea className="border-r border-border bg-zinc-900">
        <nav
          className={cn(
            "group relative flex h-display flex-col justify-between gap-4 overflow-visible px-2 py-6 font-semibold text-muted-foreground transition-all",
            expanded ? "w-56" : "w-16",
          )}
        >
          {/* Top section of nav */}
          <div className="flex flex-col gap-3">
            {dashboardRoutes.map((route) => {
              return (
                route.showInNav && (
                  <DashboardNavItem
                    key={route.id}
                    to={route.path}
                    name={route.id}
                    Icon={route.Icon}
                  />
                )
              );
            })}
          </div>

          {/* Bottom section of nav */}
          <div className="flex flex-shrink-0 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg transition-colors duration-200 ease-in-out">
            {/* <ModeToggle /> */}
            <LogoutButton />
          </div>
        </nav>
      </ScrollArea>
      <div
        className={cn(
          "absolute top-2 z-20 hidden pl-2 transition-all group-focus-within:flex group-hover:flex",
          expanded ? "left-56" : "left-16",
        )}
      >
        <Button className="h-8 w-8 px-0" onClick={() => setExpanded(!expanded)}>
          <Icons.chevronsRight
            className={cn("h-4 w-4", expanded ? "rotate-180" : "")}
          />
        </Button>
      </div>
    </>
  );
}
