import { NavLink } from "react-router-dom";

import { cn } from "@/lib/cn";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { settingsRoutes } from "./settingsRoutes";

export function SettingsNav() {
  const { data } = useCurrentUserQuery();

  const OrgIcon = settingsRoutes[0].Icon;
  const AccountIcon = settingsRoutes[1].Icon;

  return (
    <nav className="flex w-56 flex-col gap-6 px-6 py-6">
      <div className="flex flex-col text-sm ">
        <h2 className="mb-2 flex gap-2 uppercase text-muted-foreground">
          <OrgIcon className="h-5 w-5" />
          Organization
        </h2>
        {settingsRoutes[0].children.map(
          (route) =>
            route.showInNav && (
              <NavLink
                key={`settings-link-${route.id}`}
                to={`org/${data?.user?.organization.id}${
                  route.path ? "/" : ""
                }${route.path}`}
                end={route.path === ""}
                className={({ isActive }) =>
                  cn(
                    isActive
                      ? "text-foreground"
                      : "text-muted-foreground hover:text-foreground/90",
                    "ml-7 inline-flex h-10 flex-shrink-0 cursor-pointer items-center rounded-lg transition-all duration-200 ease-in-out",
                  )
                }
              >
                {route.name}
              </NavLink>
            ),
        )}
      </div>
      <div className="flex flex-col text-sm ">
        <h2 className="mb-2 flex gap-2 uppercase text-muted-foreground">
          <AccountIcon className="h-5 w-5" />
          Account
        </h2>
        {settingsRoutes[1].children.map(
          (route) =>
            route.showInNav && (
              <NavLink
                key={`settings-link-${route.id}`}
                to={`account/${data?.user?.id}${route.path ? "/" : ""}${
                  route.path
                }`}
                end={route.path === ""}
                className={({ isActive }) =>
                  cn(
                    isActive
                      ? " text-foreground"
                      : "text-muted-foreground hover:text-foreground/90",
                    "ml-7 inline-flex h-10 flex-shrink-0 cursor-pointer items-center rounded-lg transition-all duration-200 ease-in-out",
                  )
                }
              >
                {route.name}
              </NavLink>
            ),
        )}
      </div>
    </nav>
  );
}
