import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants } from "@/components/ui/button";
import { WithSuspenseAndErrorBoundary } from "@/components/WithSuspenseAndErrorBoundary";
import { TransformationStatus } from "@/gql/graphql";

import { BodyLayout } from "../BodyLayout";
import { Header } from "../Header";
import { CorrectionsPageSkeleton } from "./CorrectionsPageSkeleton";
import { getSubmissionTransformationsQueryOptions } from "./getSubmissionTransformationsQueryOptions";
import { TransformationsList } from "./TransformationsList";
import { useUpdateTransformation } from "./useUpdateTransformation";

const _YourExtractionsPage = () => {
  const { submissionId: pId } = useParams();
  const submissionId = Number(pId);
  const queryOptions = getSubmissionTransformationsQueryOptions(submissionId);
  const { data } = useSuspenseQuery(queryOptions);
  const { mutate } = useUpdateTransformation({
    queryKey: queryOptions.queryKey,
  });

  const transformations = (data?.submission?.transformations ?? []).sort(
    (a, b) => a.id - b.id,
  );

  const approvedTransformations = transformations.filter(
    (transformation) => transformation.status === TransformationStatus.Approved,
  ).length;

  const onApproveAll = () => {
    transformations.forEach((transformation) => {
      mutate({
        data: {
          status: {
            set: TransformationStatus.Approved,
          },
        },
        where: {
          id: transformation.id,
        },
      });
    });
  };

  return (
    <BodyLayout>
      <Header
        title="Your Data Extractions"
        description="Click on the items below to review the extracted data. We've surfaced the most uncertain items for your review (corrections you make will apply to all future submissions)."
      />

      <div className="flex w-full flex-col pt-6 md:px-16">
        <Badge variant={"outline"} className="mb-2 h-6 self-end">
          {approvedTransformations}/{transformations.length}
        </Badge>
        <TransformationsList transformations={transformations} />
      </div>
      <footer className="flex w-full justify-between pt-4">
        <Button variant={"secondary"}>Previous Page</Button>
        {approvedTransformations === transformations.length ? (
          <Link
            to={"../summary"}
            className={buttonVariants({
              variant: "default",
            })}
          >
            Continue
          </Link>
        ) : (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button>Continue</Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>Caution</AlertDialogHeader>
              <AlertDialogDescription>
                Some extractions have not been reviewed. You may be missing out
                on important information.
              </AlertDialogDescription>
              <AlertDialogFooter>
                <AlertDialogCancel>Go Back</AlertDialogCancel>
                <AlertDialogAction onClick={onApproveAll}>
                  <Link
                    to={"../summary"}
                    className={buttonVariants({
                      variant: "default",
                    })}
                  >
                    Approve All & Continue
                  </Link>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </footer>
    </BodyLayout>
  );
};

export const YourExtractionsPage = WithSuspenseAndErrorBoundary({
  Component: _YourExtractionsPage,
  suspenseFallback: <CorrectionsPageSkeleton />,
});
