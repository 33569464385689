import { toast } from "sonner";

import { Icons } from "@/components/Icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

type Props = {
  submissionId: number;
};

export const resetSubmissionMutation = graphql(`
  mutation ResetSubmission($submissionId: Float!) {
    resetSubmission(submissionId: $submissionId) {
      id
    }
  }
`);

export const ResetSubmissionButton = ({ submissionId }: Props) => {
  const { mutate } = useGraphqlMutation({
    mutation: resetSubmissionMutation,
    onMutate: () => {
      toast.loading("Resetting submission...");
    },
    onSuccess: () => {
      document.location.reload();
    },
    onError: () => {
      toast.error("Failed to reset submission");
      console.error("Failed to reset submission");
    },
  });

  const onConfirm = () => {
    mutate({
      submissionId,
    });
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" className="gap-2">
          <Icons.reprocess className="h-4 w-4" />
          Reprocess
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone and will re-run the submission pipeline
            for this file.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
