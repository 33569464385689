import "../../../ag-grid-theme-custom.css";
import "../../../cell-error.css"; // Custom CSS for cell error styling

// import "@ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
// import "@ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import Excel from "exceljs";

import { getHeaderType } from "./get-header-type";
import { useGetColDefs } from "./use-get-col-defs";
import { TypedOutputColumn } from "./use-get-typed-output-columns";
import { validateCell } from "./validate-cell";
import {
  FilterType,
  ValidationExcelTableFilters,
} from "./validation-excel-table-filters";
import { useValidationStore } from "./validation-store";

type Props = {
  headerValues: string[];
  rowsValues: {
    [key: string]: Excel.CellValue;
  }[];
  worksheet: Excel.Worksheet;
  workbook: Excel.Workbook;
  typedOutputColumns: TypedOutputColumn[];
};

export const ValidationExcelTable = ({
  rowsValues,
  headerValues,
  workbook,
  worksheet,
  typedOutputColumns,
}: Props) => {
  const { validationErrors, setValidationErrors } = useValidationStore();
  const [gridApi, setGridApi] = useState<AgGridReact["api"]>();
  const [externalFilter, setExternalFilter] = useState<{
    type: FilterType;
    value: string;
  }>({ type: FilterType.All, value: FilterType.All });

  useEffect(() => {
    setValidationErrors(
      rowsValues.reduce(
        (acc, row) => {
          Object.entries(row).forEach(([key, value]) => {
            const type = getHeaderType({ header: key, typedOutputColumns });
            const validationStatus = validateCell(value, type);
            // console.log({ key, value, validationStatus, type });
            if (validationStatus !== true) {
              acc.push({
                header: key,
                value,
                validationStatus,
                rowIndex: row.rowIndex as number,
              });
            }
          });
          return acc;
        },
        [] as {
          header: string;
          value: any;
          validationStatus: string;
          rowIndex: number;
        }[],
      ),
    );
  }, []);

  const colDefs = useGetColDefs({ headerValues, typedOutputColumns });

  const defaultColDef = useMemo(
    () => ({
      filter: true, // Enable filtering on all columns
      editable: true, // Enable editing on all columns
      resizable: true,
      flex: 0.7,
    }),
    [],
  );

  const onCellValueChanged = (event: any) => {
    const { value, data, colDef } = event;
    if (worksheet != null && data != null) {
      const rowIndex = data.rowIndex ?? event.rowIndex + 2; // +2 because data starts from row 2 in Excel
      // ExcelJS rows and columns are 1-based, AG Grid rows are 0-based
      const excelRow = worksheet.getRow(rowIndex);
      const excelCell = excelRow.getCell(parseInt(colDef.colId));

      excelCell.value = value; // Set the new value
      // now update the validationErrors, if the cell updated we can find it by matching the rowIndex and headerName
      const newValidationErrors = validationErrors.filter(
        (error) =>
          !(error.rowIndex === rowIndex && error.header === colDef.headerName),
      );

      setValidationErrors(newValidationErrors);
    }
  };

  function doesExternalFilterPass(node: any): boolean {
    // node.data is row values {}
    if (node.data) {
      console.log({ data: node.data });
      if (
        externalFilter.type === FilterType.All ||
        externalFilter.type === FilterType.Valid ||
        externalFilter.type === FilterType.Invalid
      ) {
        // check whether the row has a validation error
        let isRowValid = true;
        validationErrors.find((error) => {
          if (error.rowIndex === node.data.rowIndex) {
            isRowValid = false;
          }
        });

        switch (externalFilter.type) {
          case FilterType.Valid:
            return isRowValid;
          case FilterType.Invalid:
            return !isRowValid;
          default:
            return true;
        }
      } else if (externalFilter.type === FilterType.Custom) {
        // check whether the row has a validation error and if the value matches the filter
        //  find all errors with the same rowIndex
        const errors = validationErrors.filter((error) => {
          const isRowError = error.rowIndex === node.data.rowIndex;
          const isMatchedError =
            error.validationStatus.toLowerCase() ===
            externalFilter.value.toLowerCase();

          return isRowError && isMatchedError;
        });
        // if there are no errors, the row is valid
        return errors.length > 0;
      }
    }
    return true;
  }

  return (
    <>
      <ValidationExcelTableFilters
        workbook={workbook}
        gridApi={gridApi}
        rowsValues={rowsValues}
        setExternalFilter={setExternalFilter}
      />
      <div
        id="myGrid"
        className="ag-theme-custom cell-error h-[600px]"
        // className="ag-theme-quartz-dark cell-error" // applying the grid theme
      >
        <AgGridReact
          gridOptions={{
            columnDefs: colDefs,
            defaultColDef,
          }}
          // debug={true}
          onGridReady={(params) => {
            setGridApi(params.api);
          }}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
          pagination={true}
          // defaultColDef={defaultColDef}
          rowData={rowsValues}
          // columnDefs={colDefs}
          reactiveCustomComponents={true}
          isExternalFilterPresent={() => externalFilter.type !== FilterType.All}
          doesExternalFilterPass={doesExternalFilterPass}
        />
      </div>
    </>
  );
};
