import { DocumentExtractScreen } from "./document-extract-screen";
import { UseCaseGrid } from "./use-case-grid";

export const DocumentExtractHomePage = () => {
  return (
    <DocumentExtractScreen className="flex-col pt-16">
      <div className="mx-auto max-w-3xl text-center">
        <h1 className="text-3xl font-bold tracking-normal sm:text-6xl">
          Extract Structured Data
          <br />
          from{" "}
          <span className="bg-gradient-to-tr from-[#f995bf] to-[#9992ff] bg-clip-text italic text-transparent fill-mode-backwards">
            Any
          </span>{" "}
          Document
        </h1>
      </div>

      <div className="mx-auto mt-16 flex max-w-prose flex-col gap-5">
        <h2 className="mx-auto text-lg font-semibold text-primary/90 sm:text-xl">
          What kind of document are you parsing?
        </h2>
        <UseCaseGrid className="" />
      </div>
    </DocumentExtractScreen>
  );
};
