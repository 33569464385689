import React from "react";

import { SubmissionStatus, Transformation } from "@/gql/graphql";
import { cn } from "@/lib/cn";

import { Badge } from "./ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

type Status =
  | "PENDING"
  | "IN_PROGRESS"
  | "SUCCESS"
  | "FAILED"
  | "CANCELLED"
  | "NEW";

const statusMap: Record<Status, { badge: string; dot: string }> = {
  PENDING: {
    badge: "bg-status-pending text-muted-foreground hover:bg-status-pending",
    dot: "bg-status-pending-solid",
  },
  IN_PROGRESS: {
    badge: "bg-status-inprogress hover:bg-status-inprogress animate-pulse",
    dot: "bg-status-inprogress-solid",
  },
  SUCCESS: {
    badge: "bg-status-success hover:bg-status-success",
    dot: "bg-status-success-solid",
  },
  FAILED: {
    badge: "bg-status-failed hover:bg-status-failed",
    dot: "bg-status-failed-solid",
  },
  CANCELLED: {
    badge: "bg-status-cancelled hover:bg-status-cancelled",
    dot: "bg-status-cancelled-solid",
  },
  NEW: {
    badge: "bg-status-new hover:bg-status-new",
    dot: "bg-status-new-solid",
  },
};

export interface StatusBadgeProps extends React.ComponentProps<typeof Badge> {
  status: Status;
  showDotOnly?: boolean;
}

export const StatusBadge = React.forwardRef<HTMLDivElement, StatusBadgeProps>(
  ({ className, status, showDotOnly, children, ...props }, ref) => {
    const statusStyles = statusMap[status];

    const Dot = (
      <div
        className={cn("h-2 w-2 flex-shrink-0 rounded-full", statusStyles.dot)}
      />
    );

    if (showDotOnly)
      return (
        <Tooltip>
          <TooltipTrigger className={cn("p-2", className)}>
            {Dot}
          </TooltipTrigger>
          <TooltipContent>{children}</TooltipContent>
        </Tooltip>
      );

    return (
      <Badge
        ref={ref}
        className={cn(
          "select-none gap-1.5 text-foreground/90",
          statusStyles.badge,
          className,
        )}
        {...props}
      >
        {Dot}
        {children}
      </Badge>
    );
  },
);

export const getBadgeStatusFromSubmissionStatus = (
  status: SubmissionStatus,
): StatusBadgeProps["status"] => {
  const submissionStatusMap: Record<SubmissionStatus, Status> = {
    [SubmissionStatus.AwaitingPreprocessing]: "PENDING",

    [SubmissionStatus.Pending]: "PENDING",
    [SubmissionStatus.Processing]: "PENDING",
    [SubmissionStatus.Processed]: "PENDING",
    [SubmissionStatus.VendorApproved]: "PENDING",
    [SubmissionStatus.VendorRejected]: "PENDING",
    [SubmissionStatus.VendorReview]: "PENDING",
    [SubmissionStatus.Failed]: "PENDING",

    [SubmissionStatus.OrgReview]: "IN_PROGRESS",
    [SubmissionStatus.OrgApproved]: "IN_PROGRESS",
    [SubmissionStatus.OrgRejected]: "FAILED",
    [SubmissionStatus.ReadyForDelivery]: "IN_PROGRESS",
    [SubmissionStatus.Delivered]: "SUCCESS",
  };

  return submissionStatusMap[status];
};

export const getBadgeStatusFromTransformationProcessed = (
  processed: Transformation["processed"],
) => (processed ? "SUCCESS" : "IN_PROGRESS");
