import { useState } from "react";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { Card, CardHeader } from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";

import { Draggable } from "./Draggable";
import { Output } from "./OutputColumnItem";

export type Input = {
  id: string;
  example: string;
};

type Props = {
  input: Input;
  outputColumn: Output[];
  onAssociateInput: (outputId: string | void, inputId: string | void) => void;
};

export const InputColumnItem = ({
  input,
  outputColumn,
  onAssociateInput,
}: Props) => {
  const [open, setOpen] = useState(false);

  const allOutputs = outputColumn.map((output) => output.id);

  return (
    <Draggable key={input.id} id={input.id} className="z-10">
      <Card>
        <CardHeader className="flex flex-row justify-between hover:bg-accent/25">
          <div>
            <p>
              Header:
              <span className="ml-2 rounded bg-muted px-1 py-1 font-mono tabular-nums text-muted-foreground">
                {input.id}
              </span>
            </p>
            <p>
              Example:
              <span className="ml-2 rounded bg-muted px-1 py-1 font-mono tabular-nums text-muted-foreground">
                {input.example}
              </span>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild className="z-50" data-no-dnd="true">
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="z-50 w-[175px] justify-between"
                >
                  Select column...
                  <Icons.sort className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="z-50 w-[200px] p-0" data-no-dnd="true">
                <Command className="z-50">
                  <CommandInput
                    placeholder="Search Columns..."
                    className="h-9"
                  />
                  <CommandEmpty>No columns found.</CommandEmpty>
                  <ScrollArea className="h-52 overflow-auto">
                    <CommandGroup className="z-50">
                      {allOutputs.map((output, i) => (
                        <CommandItem
                          className="z-50"
                          key={i}
                          value={output}
                          onSelect={(currentOutput) => {
                            setOpen(false);
                            const output = allOutputs.find(
                              (output) =>
                                output.toLowerCase() ===
                                currentOutput.toLowerCase(),
                            );
                            onAssociateInput(output, input.id);
                          }}
                        >
                          {output}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </ScrollArea>
                </Command>
              </PopoverContent>
            </Popover>
            <Icons.arrowRight />
          </div>
        </CardHeader>
      </Card>
    </Draggable>
  );
};
