import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/cn";

type RadioInputProps = {
  type: string;
  isChecked: boolean;
  onChange: (type: string) => void;
};

export const RadioInput = ({ type, isChecked, onChange }: RadioInputProps) => {
  return (
    <div className="flex items-center gap-2">
      <Label className="font-normal">{type}</Label>
      <Input
        onChange={() => onChange(type)}
        type="radio"
        className={cn(isChecked ? "text-muted" : "", "h-5 w-5")}
        checked={isChecked}
      />
    </div>
  );
};
