import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useSetSubmissionStatus = () => {
  return useGraphqlMutation({
    mutationKey: ["setSubmissionStatus"],
    mutation: graphql(`
      mutation setSubmissionStatusMutation(
        $data: SubmissionUpdateInput!
        $where: SubmissionWhereUniqueInput!
      ) {
        updateOneSubmission(data: $data, where: $where) {
          status
        }
      }
    `),
  });
};
