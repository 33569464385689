import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";

import { Button } from "@/components/ui/button";

import { Vendor } from "./VendorTable";

export const getVendorTableColumns = () => {
  const columns: ColumnDef<Vendor>[] = [
    {
      accessorKey: "id",
    },
    {
      accessorKey: "vendorId",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Partner ID
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="pl-4 lowercase tabular-nums">
          {row.getValue("vendorId")}
        </div>
      ),
    },
    {
      accessorKey: "name",
      header: () => <div className="text-rights">Name</div>,
      cell: ({ row }) => {
        return <div className="font-medium">{row.getValue("name")}</div>;
      },
    },
    {
      header: "Submissions",
      accessorFn: (vendor) => vendor?.totalSubmissions?.submissions,
      cell: (ctx) => {
        const value = ctx.getValue() as number;
        return <div className="font-medium">{value}</div>;
      },
    },
    // {
    //   header: "Need Attention",
    //   accessorFn: (vendor) => vendor?.lowAccuracySubmissions,
    //   cell: (ctx) => {
    //     const value = ctx.getValue() as number;
    //     return <div className="font-medium">{value}</div>;
    //   },
    // },
    // {
    //   header: "Errors",
    //   accessorFn: (vendor) => vendor?.errorSubmissions,
    //   cell: (ctx) => {
    //     const value = ctx.getValue() as number;

    //     return <div className="font-medium">{value}</div>;
    //   },
    // },
  ];

  return columns;
};
