import { Link, useLocation, useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { WithSuspenseAndErrorBoundary } from "@/components/WithSuspenseAndErrorBoundary";
import {
  GetSubmissionTransformationsQuery,
  TransformationStatus,
} from "@/gql/graphql";
import { cn } from "@/lib/cn";

import { CorrectionsPageSkeleton } from "./CorrectionsPageSkeleton";
import { getSubmissionTransformationsQueryOptions } from "./getSubmissionTransformationsQueryOptions";
import { useUpdateTransformation } from "./useUpdateTransformation";

type Submission = NonNullable<GetSubmissionTransformationsQuery["submission"]>;

type Props = {
  transformations: Submission["transformations"];
};

const TransformationsListComponent = ({ transformations }: Props) => {
  const location = useLocation();
  const { submissionId: pId } = useParams();
  const submissionId = Number(pId);
  const queryOptions = getSubmissionTransformationsQueryOptions(submissionId);
  const { mutate } = useUpdateTransformation({
    queryKey: queryOptions.queryKey,
  });

  const onApprove = (
    transformationId: number,
    currStatus: TransformationStatus,
  ) => {
    console.log("Approve", transformationId);
    if (currStatus === TransformationStatus.Approved) {
      mutate({
        data: {
          status: {
            set: TransformationStatus.Pending,
          },
        },
        where: {
          id: transformationId,
        },
      });
    } else {
      mutate({
        data: {
          status: {
            set: TransformationStatus.Approved,
          },
        },
        where: {
          id: transformationId,
        },
      });
    }
  };

  return (
    <ul>
      {transformations.map((transformation, index) => {
        const approved =
          transformation.status === TransformationStatus.Approved;
        return (
          <li
            key={index}
            className="flex w-full flex-1 flex-col items-center gap-4 pb-4"
          >
            <Card key={index} className="w-full">
              <CardHeader className="flex flex-row items-center justify-between">
                <div className="flex items-center">
                  <Button
                    variant={"outline"}
                    onClick={() =>
                      onApprove(transformation.id, transformation.status)
                    }
                    className={cn(
                      "relative mr-4 h-8 w-8 rounded-full border",
                      approved && "border-green-500",
                    )}
                  >
                    <span className="absolute inset-0 flex items-center justify-center text-lg font-bold text-primary-foreground">
                      {approved && (
                        <Icons.check className="h-4 w-4  text-green-500" />
                      )}
                    </span>
                  </Button>
                  <div className="space-y-2">
                    <CardTitle className="text-md">
                      {transformation.type.name}
                    </CardTitle>
                    <CardDescription className="max-w-2xl">
                      {transformation.type?.description}
                    </CardDescription>
                  </div>
                </div>
                <Link
                  to={`${location.pathname}/${transformation.id}`}
                  className={buttonVariants({
                    variant: "outline",
                  })}
                >
                  View
                </Link>
              </CardHeader>
            </Card>
          </li>
        );
      })}
    </ul>
  );
};

export const TransformationsList = WithSuspenseAndErrorBoundary({
  Component: TransformationsListComponent,
  suspenseFallback: <CorrectionsPageSkeleton />,
});
