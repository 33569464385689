import { useSuspenseQuery } from "@tanstack/react-query";
import { Folders } from "lucide-react";

import { AnalyticsCard } from "@/components/KPMCard";
import { graphql } from "@/gql";
import { getDateRangesForQuery } from "@/lib/getDateRangesForQuery";
import { createQueryOptions } from "@/lib/hooks/graphql";
import { useGetIsDemoUser } from "@/lib/hooks/useGetIsDemoUser";

import { KPMProps } from "./KPMCardTimeSaved";

const query = graphql(`
  query KPMCardSubmissionCount(
    $currWhere: SubmissionWhereInput
    $prevWhere: SubmissionWhereInput
  ) {
    curr: aggregateSubmission(where: $currWhere) {
      _count {
        id
      }
    }
    prev: aggregateSubmission(where: $prevWhere) {
      _count {
        id
      }
    }
  }
`);

export const getQueryOptions = ({ dateRange, dateRangeType }: KPMProps) => {
  const { currWhere, prevWhere } = getDateRangesForQuery({
    dateRange,
    dateRangeType,
  });

  return createQueryOptions({
    query,
    variables: {
      currWhere,
      prevWhere,
    },
  });
};

export const KPMCardSubmissionCount = ({
  dateRangeType,
  dateRange,
}: KPMProps) => {
  const showDemoData = useGetIsDemoUser();

  const options = getQueryOptions({ dateRange, dateRangeType });
  const { data, isLoading } = useSuspenseQuery(options);

  const curr = showDemoData ? 72 : data.curr._count?.id ?? 0;
  const prev = showDemoData ? 52 : data.prev._count?.id ?? 0;

  return (
    <AnalyticsCard
      isLoading={isLoading}
      name={"Total Submissions"}
      icon={<Folders className="h-5 w-5" />}
      label={`${curr}`}
      value={curr}
      prevValue={prev}
      timePeriod={dateRangeType}
    />
  );
};
