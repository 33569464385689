import { useSuspenseQuery } from "@tanstack/react-query";

import { Icons } from "@/components/Icons";
import { AnalyticsCard } from "@/components/KPMCard";
import { graphql } from "@/gql";
import { getDateRangesForQuery } from "@/lib/getDateRangesForQuery";
import { createQueryOptions } from "@/lib/hooks/graphql";
import { useGetIsDemoUser } from "@/lib/hooks/useGetIsDemoUser";

import { KPMProps } from "./KPMCardTimeSaved";

const query = graphql(`
  query GetAccuracyScoreCardData(
    $currWhere: SubmissionMetaWhereInput
    $prevWhere: SubmissionMetaWhereInput
  ) {
    curr: submissionMetas(where: $currWhere) {
      key
      value
    }
    prev: submissionMetas(where: $prevWhere) {
      key
      value
    }
  }
`);

export const getQueryOptions = ({ dateRange, dateRangeType }: KPMProps) => {
  const { currWhere, prevWhere } = getDateRangesForQuery({
    dateRange,
    dateRangeType,
  });

  return createQueryOptions({
    query,
    variables: {
      currWhere,
      prevWhere,
    },
  });
};

export const KPMCardSubmissionAccuracy = ({
  dateRange,
  dateRangeType,
}: KPMProps) => {
  const showDemoData = useGetIsDemoUser();

  const options = getQueryOptions({ dateRange, dateRangeType });

  const { data, isLoading } = useSuspenseQuery(options);

  const curr =
    data.curr.filter((data) => data.key === "initial_estimated_accuracy") ?? [];
  const prev =
    data.prev.filter((data) => data.key === "initial_estimated_accuracy") ?? [];

  // now take the average accuracy for curr and prev
  const currAvg =
    toWholePercentage(
      curr.reduce((acc, curr) => {
        if (curr.value === null || curr.value === "NaN") return acc;
        return acc + Number(curr.value);
      }, 0) / curr.length,
    ) ?? 0;
  const prevAvg = toWholePercentage(
    prev.reduce((acc, curr) => {
      if (curr.value === null || curr.value === "NaN") return acc;
      return acc + Number(curr.value);
    }, 0) / prev.length,
  );

  return (
    <AnalyticsCard
      isLoading={isLoading}
      name={"Average Accuracy"}
      icon={<Icons.target className="h-5 w-5" />}
      label={`${showDemoData ? 92 : currAvg}%`}
      value={showDemoData ? 92 : currAvg}
      prevValue={showDemoData ? 89 : prevAvg}
      timePeriod={dateRangeType}
    />
  );
};

function toWholePercentage(decimalNumber: number): number {
  return Math.round(decimalNumber * 100);
}
