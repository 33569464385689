import { TooltipContentProps } from "@radix-ui/react-tooltip";

import { cn } from "@/lib/cn";

import { Icons } from "./Icons";
import { Badge, BadgeProps } from "./ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

type LLMBadgeProps = {
  removable?: boolean;
  onRemove?: () => void;
};

interface Props
  extends React.HTMLAttributes<HTMLDivElement>,
    LLMBadgeProps,
    BadgeProps,
    TooltipContentProps {
  text: string;
}

export default function LabelBadge({
  className,
  text,
  children,
  side,
  align,
  removable = false,
  onRemove = () => {},
  ...props
}: Props) {
  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge
          {...props}
          className={cn("flex cursor-default flex-row gap-1", className)}
        >
          {text}
          {removable && (
            <Icons.remove
              onClick={() => onRemove()}
              className="h-3 w-3 cursor-pointer"
            />
          )}
        </Badge>
      </TooltipTrigger>
      <TooltipContent
        asChild
        className="cursor-default"
        align={align}
        side={side}
      >
        {children}
      </TooltipContent>
    </Tooltip>
  );
}
