export const onDownloadFileToComputer = (
  signedUrl: string,
  fileName: string,
) => {
  const link = document.createElement("a");
  link.href = signedUrl;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
