import React from "react";
import { Link } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { buttonVariants } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { OrgSubmissionTypesQuery } from "@/gql/graphql";
import { cn } from "@/lib/cn";

interface SubmissionTypeItemProps {
  submissionType: OrgSubmissionTypesQuery["submissionTypes"][number];
}

export const SubmissionTypeItem: React.FC<SubmissionTypeItemProps> = ({
  submissionType,
}) => {
  return (
    <Card className="max-w-3xl">
      <CardContent className="px-5 py-4">
        <div className="flex items-start justify-between">
          <div className="flex gap-6">
            <div className="flex items-start">
              <div className="rounded-full border border-border bg-muted p-3">
                <Icons.submission />
              </div>
            </div>
            <div>
              <h2 className="text-lg font-semibold">{submissionType.name}</h2>
              <p className="text-xs text-muted-foreground">
                <span className="mr-1">
                  {submissionType.normalizeMappings.length}
                </span>
                vendors submitting
              </p>
              <p className="mt-2 text-sm text-muted-foreground">
                {submissionType.description}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-xs text-muted-foreground">
              Updated {submissionType.updatedAt}
            </p>
            <Link
              to={`${submissionType.id}`}
              className={cn(
                buttonVariants({ variant: "outline" }),
                "h-10 w-10 rounded-full p-1 text-foreground/80",
              )}
            >
              <Icons.edit className="h-4" />
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const SubmissionTypeItemSkeleton: React.FC = () => {
  return (
    <Card className="max-w-3xl animate-pulse">
      <CardContent className="px-5 py-4">
        <div className="flex items-start justify-between">
          <div className="flex gap-6">
            <div className="flex items-start">
              <div className="rounded-full border border-border p-3">
                <Icons.submission className="text-foreground" />
              </div>
            </div>
            <div className="w-64">
              <div className="mb-2 h-6 w-3/4 rounded bg-muted" />
              <div className="mb-2 h-4 w-1/4 rounded bg-muted" />
              <div className="h-4 w-1/2 rounded bg-muted" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-4 w-24 rounded bg-muted" />
            <div className="flex h-10 w-10 items-center justify-center rounded-full border border-border">
              <Icons.edit className="text-foreground" />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
