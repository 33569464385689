import React, { useMemo } from "react";
import { z } from "zod";

// @ts-ignore
import { TimePeriod } from "@/app/dashboard/home/dashHomeStore";
import StatCard, { Stat } from "@/components/StatCard";
import { StatCardSkeleton } from "@/components/StatCardSkeleton";

const labelSchema = z
  .string()
  .refine(
    (s) => !s.includes("NaN"),
    "NaN value not allowed in AnalyticsCard label",
  );

interface Props {
  selected?: boolean;
  onClick?: () => void;
  name: string;
  icon: React.ReactNode;
  label: z.infer<typeof labelSchema>;
  value: number | string;
  prevValue?: number | string;
  isLoading: boolean;
  timePeriod: TimePeriod;
}

export const AnalyticsCard = ({
  selected = false,
  onClick,
  label,
  value,
  prevValue,
  name,
  icon,
  isLoading,
  timePeriod,
}: Props) => {
  // try {
  //   labelSchema.parse(label);
  // } catch (e) {
  //   console.error(e);
  // }

  const stat: Stat = useMemo(() => {
    return {
      name,
      data: {
        label,
        value,
      },
    };
  }, [value, label]);
  const prevStat: Stat = useMemo(() => {
    return {
      name,
      data: {
        label,
        value: prevValue ?? 0,
      },
    };
  }, [value, label]);

  if (isLoading) return <StatCardSkeleton />;

  return (
    <StatCard
      selected={selected}
      onClick={onClick}
      stat={stat}
      prevStat={prevValue != null ? prevStat : undefined}
      icon={icon}
      timePeriod={timePeriod}
    />
  );
};
