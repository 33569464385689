import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useGetIsDemoUser } from "@/lib/hooks/useGetIsDemoUser";

export type DateObj = {
  name: string;
  submissions: number;
};

type Props = {
  submissionsByTimePeriod: DateObj[];
};

export default function DashboardChart({ submissionsByTimePeriod }: Props) {
  const showDemoData = useGetIsDemoUser();

  const submissionsByTimePeriodDemo = submissionsByTimePeriod.map((item, i) => {
    return {
      name: item.name,
      submissions: (i + 1) % 2 === 1 ? i + 1 * 5 : i + 1 * 2,
    };
  });

  return (
    <ResponsiveContainer width="99%" height="99%">
      <BarChart
        width={500}
        height={300}
        data={
          showDemoData ? submissionsByTimePeriodDemo : submissionsByTimePeriod
        }
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false} />
        <Tooltip
          labelClassName="text-muted-foreground"
          wrapperClassName="bg-muted"
        />
        <Legend />
        <Bar
          dataKey="submissions"
          name="Partner Submissions"
          fill="rgb(239 68 68)"
          radius={[4, 4, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
