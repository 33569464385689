import { useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Handle, OnConnect, Position } from "reactflow";
import { toast } from "sonner";

import { useGetVendorMapping } from "@/app/dashboard/vendors/summary/submission/[submissionTypeId]/useGetVendorMapping";
import { useUpdateNormalizeMapping } from "@/app/dashboard/vendors/summary/submission/[submissionTypeId]/useUpdateNormalizeMapping";
import { useHeaderMappingStore } from "@/components/header-mapping/HeaderMappingStore";
import {
  headerHandleStyle,
  HeaderNode,
  HeaderNodeProps,
} from "@/components/header-mapping/nodes/HeaderNode";

export interface InputHeaderNodeProps extends HeaderNodeProps {
  example?: string;
}

export const INPUT_HEADER_NODE_TYPE = "inputHeader";

export function InputHeaderNode({ id, ...props }: InputHeaderNodeProps) {
  const { vendorId } = useParams() as {
    vendorId: string;
  };
  const { inputNodes, outputNodes, edges, setEdges } = useHeaderMappingStore();
  const { mapping, normalizeMappingId } = useGetVendorMapping();
  const { mutate } = useUpdateNormalizeMapping();
  const queryClient = useQueryClient();

  const self = useMemo(
    () => inputNodes.find((node) => node.id === id),
    [inputNodes, id],
  );

  const handleConnect = useCallback<OnConnect>(
    (connection) => {
      const newEdges = edges.map((edge) => {
        if (edge.source === connection.source) {
          if (connection.target != null) {
            edge.target = connection.target;
            edge.animated = true;

            // run mutation to update mapping
            const outputNode = outputNodes.find(
              (node) => node.id === connection.target,
            );
            const inputName = edge.id;
            const outputName = outputNode?.data.label;

            const newMapping = {
              ...mapping,
              [inputName]: outputName,
            };
            mutate(
              {
                where: {
                  id: normalizeMappingId,
                },
                data: {
                  mapping: newMapping,
                },
              },
              {
                onSuccess: () => {
                  toast("Success", {
                    description: `New mapping submitted successfully.`,
                    duration: 2000,
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["VendorSubmissionTypes", `vendorId=${vendorId}`],
                  });
                },
              },
            );
          }
        }
        return edge;
      });
      setEdges(newEdges);
    },
    [edges, setEdges],
  );

  return (
    <HeaderNode id={id} {...props}>
      <div className="h-12 border-t border-muted/20 p-2 text-muted-foreground">
        {self?.example || "No data"}
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        onConnect={handleConnect}
        style={headerHandleStyle}
      />
    </HeaderNode>
  );
}
