import { Field } from "../use-gen-custom-fields";

const employeeInformationFields: Field[] = [
  {
    name: "companyName",
    type: "text",
    description: "The name of the company.",
  },
  {
    name: "jobTitle",
    type: "text",
    description: "The job title of the employee.",
  },
  {
    name: "dateOfHire",
    type: "date",
    description: "The hire date of the employee.",
  },
  {
    name: "location",
    type: "text",
    description: "The work location of the employee.",
  },
  {
    name: "lastName",
    type: "text",
    description: "The last name of the employee.",
  },
  {
    name: "firstName",
    type: "text",
    description: "The first name of the employee.",
  },
  {
    name: "socialSecurityNumber",
    type: "text",
    description: "The social security number of the employee.",
  },
  { name: "sex", type: "text", description: "The sex of the employee (m/f)." },
  {
    name: "homeAddress",
    type: "text",
    description: "The home address of the employee.",
  },
  {
    name: "city",
    type: "text",
    description: "The city of the employee's home address.",
  },
  {
    name: "state",
    type: "text",
    description: "The state of the employee's home address.",
  },
  {
    name: "zip",
    type: "text",
    description: "The zip code of the employee's home address.",
  },
  {
    name: "dateOfBirth",
    type: "date",
    description: "The birth date of the employee.",
  },
  {
    name: "emailAddress",
    type: "text",
    description: "The email address of the employee.",
  },
  {
    name: "phoneNumber",
    type: "text",
    description: "The phone number of the employee.",
  },
  {
    name: "maritalStatus",
    type: "text",
    description: "The marital status of the employee.",
  },
];

const declineReasonFields: Field[] = [
  {
    name: "hasOtherCoverage",
    type: "boolean",
    description: "Indicates if the employee has other coverage.",
  },
  {
    name: "otherReason",
    type: "text",
    description: "Other reason for declining coverage.",
  },
];

const waivingMedicalCoverageFields: Field[] = [
  {
    name: "declineReason",
    type: "thing",
    description: "Reason for declining coverage.",
    fields: declineReasonFields,
  },
];

const planFields: Field[] = [
  {
    name: "selected",
    type: "boolean",
    description: "Indicates if the plan is selected.",
  },
  {
    name: "coverageTier",
    type: "text",
    description: "The coverage tier selected by the employee.",
    enum: ["EE Only", "EE + Spouse", "EE + Child(ren)", "EE + Family"],
  },
];

const enrollingInBenefitsFields: Field[] = [
  {
    name: "medical",
    type: "thing",
    description: "Details of the selected medical plan.",
    fields: planFields,
  },
  {
    name: "dental",
    type: "thing",
    description: "Details of the selected dental plan.",
    fields: planFields,
  },
  {
    name: "vision",
    type: "thing",
    description: "Details of the selected vision plan.",
    fields: planFields,
  },
];

const dependentInformationFields: Field[] = [
  {
    name: "relationship",
    type: "text",
    description: "The relationship of the dependent to the employee.",
  },
  {
    name: "lastName",
    type: "text",
    description: "The last name of the dependent.",
  },
  {
    name: "firstName",
    type: "text",
    description: "The first name of the dependent.",
  },
  { name: "sex", type: "text", description: "The sex of the dependent (m/f)." },
  {
    name: "dateOfBirth",
    type: "date",
    description: "The birth date of the dependent.",
  },
  {
    name: "socialSecurityNumber",
    type: "text",
    description: "The social security number of the dependent.",
  },
];

const certificationAndAuthorizationFields: Field[] = [
  {
    name: "authorization",
    type: "boolean",
    description: "Indicates if the authorization is provided.",
  },
  {
    name: "dateOfSignature",
    type: "date",
    description: "The date the form was signed.",
  },
];

export const benefitEnrollmentFields: Field[] = [
  {
    name: "employeeInformation",
    type: "thing",
    description: "Information about the employee.",
    fields: employeeInformationFields,
  },
  {
    name: "waivingMedicalCoverage",
    type: "thing",
    description: "Information about waiving medical coverage.",
    fields: waivingMedicalCoverageFields,
  },
  {
    name: "enrollingInBenefits",
    type: "thing",
    description: "Information about enrolling in benefits.",
    fields: enrollingInBenefitsFields,
  },
  {
    name: "dependentInformation",
    type: "collection",
    description: "Information about the dependents.",
    fields: dependentInformationFields,
  },
  {
    name: "certificationAndAuthorization",
    type: "thing",
    description: "Certification and authorization details.",
    fields: certificationAndAuthorizationFields,
  },
];
