import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { graphql } from "@/gql";
import { UserByIdQuery } from "@/gql/graphql";
import { cn } from "@/lib/cn";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

import { SettingsForm } from "../../SettingsForm";

interface UserInformationFormProps extends React.ComponentProps<typeof Card> {
  user: NonNullable<UserByIdQuery["user"]>;
}

const userInfoSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
});

export const UserInformationForm = ({
  user,
  ...props
}: UserInformationFormProps) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useGraphqlMutation({
    mutation: graphql(`
      mutation UserGeneralInfo(
        $data: UserUpdateInput!
        $where: UserWhereUniqueInput!
      ) {
        updateOneUser(data: $data, where: $where) {
          firstName
          lastName
          email
        }
      }
    `),
    onSuccess: () => {
      toast("Success", {
        description: "Account name updated.",
        duration: 2000,
      });
      queryClient.invalidateQueries({
        queryKey: ["user", "email=" + user.email],
      });
    },
  });

  useEffect(() => {
    form.reset({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
  }, [user]);

  const form = useForm<z.infer<typeof userInfoSchema>>({
    resolver: zodResolver(userInfoSchema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
  });

  function onSubmit(values: z.infer<typeof userInfoSchema>) {
    mutate({
      data: {
        firstName: { set: values.firstName },
        lastName: { set: values.lastName },
        email: { set: values.email },
      },
      where: {
        id: user.id,
      },
    });
  }

  return (
    <SettingsForm
      {...props}
      className={cn("max-w-3xl", props.className)}
      form={form}
      onFormSubmit={onSubmit}
      isPending={isPending}
      title="Account Information"
      description="Update your account information."
    >
      <div className="flex gap-4">
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <FormField
        control={form.control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input {...field} className="w-96" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </SettingsForm>
  );
};

export const UserInformationFormSkeleton = ({
  ...props
}: Omit<UserInformationFormProps, "user">) => {
  return (
    <Card {...props} className={cn("max-w-3xl animate-pulse", props.className)}>
      <CardHeader>
        <CardTitle>Account Information</CardTitle>
        <CardDescription>Update your account information.</CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col gap-4 ">
        <div className="flex gap-4">
          <div className="">
            <FormItem>
              <Label className="text-muted-foreground">First Name</Label>

              <Input disabled />
            </FormItem>
          </div>
          <div className="">
            <FormItem>
              <Label className="text-muted-foreground">Last Name</Label>

              <Input disabled />
            </FormItem>
          </div>
        </div>
        <FormItem>
          <Label className="text-muted-foreground">Email</Label>
          <Input className="w-96" disabled />
        </FormItem>
      </CardContent>
      <Separator className="mb-7" />
      <CardFooter className="flex justify-end gap-3">
        <Button variant="ghost" disabled>
          Discard Changes
        </Button>
        <Button disabled className="w-32">
          Save
        </Button>
      </CardFooter>
    </Card>
  );
};
