// import { Icons } from "@/components/Icons";
// import { Button } from "@/components/ui/button";
import { useCurrentOrgSubmissionTypesQuery } from "@/lib/hooks/queries/SubmissionTypes";

import { SubmissionTypeItem } from "./SubmissionTypeItem";

export function SubmissionTypesSection() {
  const { data } = useCurrentOrgSubmissionTypesQuery(); // TODO: Change to vendor submission types query
  const { submissionTypes } = data;

  return (
    <div>
      <h2 className="text-sm font-medium">Submission Types</h2>
      <ul role="list" className="mt-3 flex items-center gap-4">
        {submissionTypes.map((submissionType) => (
          <SubmissionTypeItem
            key={submissionType.id}
            submissionType={submissionType}
          />
        ))}
        {/* <Button className="h-full rounded-full p-1" variant="outline">
          <Icons.plus className="h-5" aria-hidden="true" />
        </Button> */}
      </ul>
    </div>
  );
}
