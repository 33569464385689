import { Field } from "../use-gen-custom-fields";

export const billOfLadingFields: Field[] = [
  {
    name: "billOfLadingNumber",
    type: "text",
    description: "The unique identifier for the bill of lading",
  },
  {
    name: "shipperName",
    type: "text",
    description: "The name of the shipper",
  },
  {
    name: "shipperAddress",
    type: "address",
    description: "The address of the shipper",
  },
  {
    name: "consigneeName",
    type: "text",
    description: "The name of the consignee",
  },
  {
    name: "consigneeAddress",
    type: "address",
    description: "The address of the consignee",
  },
  {
    name: "notifyPartyName",
    type: "text",
    description: "The name of the notify party",
  },
  {
    name: "notifyPartyAddress",
    type: "address",
    description: "The address of the notify party",
  },
  {
    name: "carrierName",
    type: "text",
    description: "The name of the carrier",
  },
  {
    name: "vesselName",
    type: "text",
    description: "The name of the vessel",
  },
  {
    name: "portOfLoading",
    type: "text",
    description: "The port where the goods were loaded",
  },
  {
    name: "portOfDischarge",
    type: "text",
    description: "The port where the goods will be discharged",
  },
  {
    name: "placeOfReceipt",
    type: "text",
    description: "The place where the goods were received",
  },
  {
    name: "placeOfDelivery",
    type: "text",
    description: "The place where the goods will be delivered",
  },
  {
    name: "dateOfIssue",
    type: "date",
    description: "The date the bill of lading was issued",
  },
  {
    name: "descriptionOfGoods",
    type: "text",
    description: "A description of the goods being shipped",
  },
  {
    name: "grossWeight",
    type: "number",
    description: "The gross weight of the goods",
  },
  {
    name: "measurement",
    type: "text",
    description: "The measurement of the goods",
  },
  {
    name: "freightDetails",
    type: "text",
    description: "Details about the freight charges",
  },
];
