import { useEffect, useState } from "react";
import { DialogDescription } from "@radix-ui/react-dialog";
import { formatISO } from "date-fns";
import { toast } from "sonner";

import { Icons } from "@/components/Icons";
import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import {
  OrgSubmissionType,
  useCurrentOrgSubmissionTypesQuery,
} from "@/lib/hooks/queries/SubmissionTypes";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";
import { queryClient } from "@/queryClient";

import { FileTypeSelector } from "./FileTypeSelector";
import { FileUpload } from "./FileUpload";
import { SubmissionForDatePicker } from "./SubmissionForDatePicker";
import { useCreateOneFileMutation } from "./useCreateOneFileMutation";
import { useCreateSubmissionMutation } from "./useCreateSubmissionMutation";
import { usePrepareFileUploadMutation } from "./usePrepareFileUploadMutation";
import { VendorSelect } from "./VendorSelect";

export const CreateSubmissionButton = () => {
  const { data } = useCurrentOrgSubmissionTypesQuery();
  const submissionTypes = data.submissionTypes;
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>(new Date());

  const user = useCurrentUserQuery();
  const orgId = user.data?.user?.organization.id as number;
  const [submissionType, setSubmissionType] = useState<OrgSubmissionType>(
    submissionTypes[0],
  );

  const [vendorId, setVendorId] = useState<number>();
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [isFileUploadComplete, setIsFileUploadComplete] =
    useState<boolean>(false);

  const { mutate: prepareFileUpload, data: prepareFileUploadData } =
    usePrepareFileUploadMutation();

  const { mutate: createOneFile } = useCreateOneFileMutation();
  const { mutate: createSubmission } = useCreateSubmissionMutation();

  useEffect(() => {
    if (fileToUpload != null) {
      prepareFileUpload({ fileName: fileToUpload.name });
    }
  }, [fileToUpload]);

  const onSubmit = () => {
    if (prepareFileUploadData != null && fileToUpload != null && vendorId) {
      setIsFileUploading(true);
      const { signedUrl, key } = prepareFileUploadData.prepareFileUpload;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": fileToUpload.type,
          "Content-Length": String(fileToUpload.size),
        },
        body: fileToUpload,
      };
      fetch(signedUrl, options)
        .then((response) => {
          if (!response.ok) {
            toast("Failed to upload file");
            throw new Error("Failed to upload file");
          }
          return response;
        })
        .then(() => {
          createOneFile(
            {
              data: {
                key,
                mimetype: fileToUpload.type,
                name: fileToUpload.name,
                size: fileToUpload.size,
              },
            },
            {
              onSuccess: (createOneFileData, _variables) => {
                setIsFileUploadComplete(true);
                createSubmission(
                  {
                    fileId: createOneFileData.createOneFile.id,
                    vendorId,
                    submissionTypeId: submissionType.id,
                    submittedFor: formatISO(date as Date),
                  },
                  {
                    onSuccess: (_createSubmissionData, _variables) => {
                      toast("Submission created successfully");
                      queryClient.invalidateQueries({
                        queryKey: ["GetSubmissionsInfinite"],
                      });
                    },
                    onError: (error, _variables) => {
                      console.log({ error });
                      toast("Failed to create submission");
                    },
                  },
                );
              },
              onError: (error, _variables) => {
                console.log({ error });
                toast("Failed to create file");
              },
            },
          );
        });
    }
  };

  const isSubmitDisabled = !(
    submissionType &&
    date &&
    vendorId &&
    fileToUpload &&
    prepareFileUploadData
  );

  useEffect(() => {
    // if the file is uploaded, close the dialog
    if (isFileUploadComplete) {
      setOpen(false);
    }
  }, [isFileUploadComplete]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          className="w-fit px-2 text-muted-foreground"
          variant="outline"
          size="sm"
        >
          <Icons.plus className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>Upload New Submission</DialogHeader>
        <Separator />
        <DialogDescription className="flex flex-col gap-4">
          {fileToUpload == null ? (
            <>
              <div className="flex justify-between gap-4">
                <FileTypeSelector
                  submissionType={submissionType}
                  setSubmissionType={setSubmissionType}
                />

                <SubmissionForDatePicker date={date} setDate={setDate} />
              </div>
              <VendorSelect
                vendorId={vendorId}
                setVendorId={setVendorId}
                orgId={orgId}
              />

              <FileUpload setFileToUpload={setFileToUpload} />
            </>
          ) : (
            <div className="flex flex-col gap-4">
              <Label>Submission Type:</Label>
              <span className="text-muted-foreground">
                {submissionType.name}
              </span>
              <Label>Vendor:</Label>
              <span className="text-muted-foreground">{vendorId}</span>
              <Label>File:</Label>
              <span className="text-muted-foreground">{fileToUpload.name}</span>
            </div>
          )}
        </DialogDescription>
        <DialogFooter>
          <Button
            onClick={onSubmit}
            variant="secondary"
            disabled={isSubmitDisabled}
            className="flex items-center gap-2"
          >
            {/* spinner while file is uploading */}
            Submit
            {isFileUploading ? (
              <Spinner />
            ) : (
              <Icons.upload className="h-5 w-5" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
