import { Suspense } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { NavLink, NavLinkProps } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { StatusBadge } from "@/components/StatusBadge";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent } from "@/components/ui/card";
import { TransformationsNavQuery } from "@/gql/graphql";
import { cn } from "@/lib/cn";
import { usePrepareFileDownload } from "@/lib/hooks/queries/usePrepareFileDownload";
import { onDownloadFileToComputer } from "@/lib/onDownloadFileToComputer";

import { NumLabelsToReview } from "./NumLabelsToReview";

interface Props extends Omit<NavLinkProps, "to"> {
  transformationId: number;
  transformationName: string;
  transformationProcessed: boolean;
  outputFile: TransformationsNavQuery["transformations"][0]["outputFile"];
}

export const TransformationsNavLink = ({
  transformationId,
  transformationName,
  transformationProcessed,
  outputFile,
  className,
  ...props
}: Props) => {
  const [parent] = useAutoAnimate();
  const [otherParent] = useAutoAnimate();

  const fileDownload = usePrepareFileDownload({
    onDownload: onDownloadFileToComputer,
  });
  const onDownload = () => {
    if (!outputFile) return;
    fileDownload(outputFile.id, outputFile.name);
  };

  return (
    <NavLink
      to={`transformations/${transformationId}`}
      className={({ isActive }) => {
        return cn(
          " h-fit rounded-lg text-card-foreground shadow-sm transition-colors duration-150 ease-in-out",
          isActive
            ? "bg-background shadow-sm"
            : "border-transparent text-muted-foreground hover:bg-background hover:shadow-sm",
          className,
        );
      }}
      {...props}
    >
      <CardContent className="flex items-start gap-4 p-3">
        <div className="flex items-start gap-3">
          <StatusBadge
            showDotOnly
            className="p-0 pt-1"
            status={transformationProcessed ? "SUCCESS" : "PENDING"}
          >
            {transformationProcessed ? "Processed" : "Unprocessed"}
          </StatusBadge>
          <div className="flex flex-1 flex-col gap-1">
            <div className="text-sm">{transformationName}</div>
            <div
              className="flex items-center gap-1.5 whitespace-nowrap text-sm text-muted-foreground/90"
              ref={parent}
            >
              <Suspense
                fallback={
                  <Badge className="animate-pulse bg-muted text-sm text-muted">
                    00
                  </Badge>
                }
              >
                <Badge variant="outline">
                  <div ref={otherParent}>
                    <NumLabelsToReview
                      transformationId={transformationId}
                      className="text-sm font-medium text-foreground"
                    />
                  </div>
                </Badge>
              </Suspense>
              to Review
            </div>
          </div>
        </div>

        <button onClick={onDownload} disabled={!outputFile}>
          <Icons.download
            className={cn("h-5 w-5", !outputFile && "text-muted-foreground/60")}
          />
        </button>
      </CardContent>
    </NavLink>
  );
};

export const TransformationStackItemSkeleton = () => {
  return (
    <Card className="h-[72px] animate-pulse ">
      <CardContent className="flex justify-between p-4">
        <div className="flex flex-col gap-1">
          <div className="h-4 w-24 rounded bg-muted"></div>
          <div className="h-3 w-16 rounded bg-muted"></div>
        </div>
      </CardContent>
    </Card>
  );
};
