import { Link, useParams } from "react-router-dom";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button, buttonVariants } from "@/components/ui/button";

import { BodyLayout } from "../../../submission/:submissionId/BodyLayout";
import { Header } from "../../../submission/:submissionId/Header";
import { useGetTypedOutputColumns } from "./use-get-typed-output-columns";
import { useLoadExcelWorkbook } from "./useLoadExcelWorkbook";
import { ValidationExcelTable } from "./validation-excel-table";
import { ValidationExcelTableSkeleton } from "./validation-excel-table-skeleton";
import { useValidationStore } from "./validation-store";

export const ValidationPage = () => {
  const { vendorId: vId, submissionId: pId } = useParams();
  const vendorId = Number(vId);
  const submissionId = Number(pId);
  const { headerValues, rowsValues, workbook, worksheet } =
    useLoadExcelWorkbook({ submissionId });
  const { typedOutputColumns, isLoading } = useGetTypedOutputColumns({
    submissionId,
    vendorId,
  });
  const { validationErrors } = useValidationStore();

  return (
    <BodyLayout className="max-w-full">
      <Header
        title="Review Normalized Data"
        description="We've normalized your data and run validations to Org Standards. Please review the data below and make any necessary corrections before submitting to AI processing."
      />
      <div className="w-full py-12">
        {/* {workbookLoaded && worksheet && rowsValues.length > 0 && ( */}
        {/* {worksheet && isFetched ? ( */}
        {worksheet == null || isLoading ? (
          <ValidationExcelTableSkeleton />
        ) : (
          <ValidationExcelTable
            headerValues={headerValues}
            rowsValues={rowsValues}
            workbook={workbook}
            worksheet={worksheet}
            typedOutputColumns={typedOutputColumns}
          />
        )}
      </div>
      <footer className="flex w-full justify-between pt-4">
        <Button variant={"secondary"}>Previous Page</Button>
        {/* Check state for validations errors - surface alert dialog if they exist*/}
        {validationErrors.length > 0 ? (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button>Process Submission</Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>Are you sure?</AlertDialogHeader>
              <AlertDialogDescription>
                {`You are about to process a submission that contains
                    ${validationErrors.length} unresolved issues.
                    Submitting this form without addressing these errors may result in incorrect data processing.`}
              </AlertDialogDescription>
              <AlertDialogFooter>
                <AlertDialogCancel>Go Back</AlertDialogCancel>
                <AlertDialogAction>
                  <Link
                    to={"../completed"}
                    className={buttonVariants({
                      variant: "default",
                    })}
                  >
                    Process Submission
                  </Link>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        ) : (
          <Button>Process Submission</Button>
        )}
      </footer>
    </BodyLayout>
  );
};
