import { TimePeriod } from "@/app/dashboard/home/dashHomeStore";
import { Icons } from "@/components/Icons";
import { AnalyticsCard } from "@/components/KPMCard";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { useGetTotalPartnersData } from "./useGetTotalPartnersCard";

export const TotalActivePartnersCard = () => {
  const user = useCurrentUserQuery();
  const orgId = user.data?.user?.organization?.id;
  const { data, isLoading } = useGetTotalPartnersData({ orgId });
  const count = data?.organization?._count?.vendors ?? "N/A";

  return (
    <AnalyticsCard
      isLoading={isLoading}
      name={"Active Partners (weekly)"}
      icon={<Icons.action className="h-5 w-5" />}
      label={count.toString()}
      value={count}
      prevValue={1}
      timePeriod={TimePeriod.WEEK}
    />
  );
};
