import { useSuspenseQuery } from "@tanstack/react-query";

import { graphql } from "@/gql";
import { createQueryOptions } from "@/lib/hooks/graphql";

export const query = graphql(`
  query TransformationPredictionIds($where: PredictionWhereInput) {
    predictions(where: $where) {
      id
    }
  }
`);

const getQueryOptions = (transformationId: number) =>
  createQueryOptions({
    query,
    variables: {
      where: {
        transformationId: {
          equals: transformationId,
        },
      },
    },
  });

export const useTransformationPredictionIds = (transformationId: number) => {
  const options = getQueryOptions(transformationId);
  const { data, ...rest } = useSuspenseQuery(options);

  return {
    data: data.predictions.flatMap((prediction) => prediction.id),
    ...rest,
  };
};
