import { Label } from "@/components/ui/label";
import {
  OrgSubmissionType,
  useCurrentOrgSubmissionTypesQuery,
} from "@/lib/hooks/queries/SubmissionTypes";

import { RadioInput } from "./RadioInput";

type Props = {
  submissionType: OrgSubmissionType | void;
  setSubmissionType: React.Dispatch<React.SetStateAction<OrgSubmissionType>>;
};

export const FileTypeSelector = ({
  submissionType,
  setSubmissionType,
}: Props) => {
  const { data } = useCurrentOrgSubmissionTypesQuery();
  const submissionTypes = data.submissionTypes;

  return (
    <div className="flex flex-col gap-4">
      <Label>Submission Type:</Label>

      <div className="flex gap-4">
        {submissionTypes.map((type) => (
          <RadioInput
            key={type.id}
            type={type.type}
            isChecked={submissionType?.id === type.id}
            onChange={() => setSubmissionType(type)}
          />
        ))}
      </div>
    </div>
  );
};
