import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Excel from "exceljs";

import { usePrepareFileDownload } from "@/lib/hooks/queries/usePrepareFileDownload";

import { getHeaderValue } from "./get-header-value";
import { getNormalizeTransformationOutputFile } from "./getNormalizedFileOutputFileQueryOptions";

type Props = {
  submissionId: number;
};

export const useLoadExcelWorkbook = ({ submissionId }: Props) => {
  const { data } = useQuery(
    getNormalizeTransformationOutputFile({ submissionId }),
  );
  console.log({ data });
  const [workbook, _setWorkbook] = useState(new Excel.Workbook());
  const [workbookLoaded, setWorkbookLoaded] = useState(false);
  const [worksheet, setWorksheet] = useState<Excel.Worksheet>();

  const handleDownload = useCallback(
    async (signedUrl: string) => {
      try {
        const response = await fetch(signedUrl);
        if (!response.ok) throw new Error("Failed to fetch the file");

        const arrayBuffer = await response.arrayBuffer();
        await workbook.xlsx.load(arrayBuffer);
        setWorkbookLoaded(true);

        const loadedWorksheet = workbook.getWorksheet(1);
        if (!loadedWorksheet) {
          console.error("No worksheet found in workbook");
          return;
        }
        setWorksheet(loadedWorksheet);
      } catch (error) {
        console.error("Error loading Excel file:", error);
      }
    },
    [workbook],
  );

  const fileDownload = usePrepareFileDownload({ onDownload: handleDownload });

  useEffect(() => {
    const fileId = data?.submission?.transformations[0]?.outputFileId;
    const fileName = "output.xlsx"; // Assuming you know the filename or it's static

    if (fileId) {
      fileDownload(fileId, fileName);
    }
  }, [data]);

  const headers = worksheet ? worksheet.getRow(1) : [];
  const rows = worksheet
    ? worksheet.getRows(2, worksheet.lastRow?.number ?? 2)
    : [];
  const headerValues = useMemo(() => {
    return Array.isArray(headers?.values)
      ? headers.values
          .map((val) => {
            return getHeaderValue(val);
          })
          .slice(1)
      : [];
  }, [headers]);

  const rowsValues = useMemo(() => {
    return (
      rows?.reduce(
        (acc, row) => {
          const rowValues = Array.isArray(row?.values) ? row.values : [];
          if (rowValues.length > 0) {
            acc.push({
              rowIndex: row.number,
              ...rowValues.reduce(
                (acc, val, i) => {
                  const header = headerValues[i - 1];
                  if (header) {
                    // Ensure header is not an empty string or undefined
                    acc[header] = val;
                  }
                  return acc;
                },
                {} as { [key: string]: Excel.CellValue },
              ),
            });
          }
          return acc;
        },
        [] as { [key: string]: Excel.CellValue }[],
      ) ?? []
    );
  }, [rows, headerValues]);

  return {
    workbook,
    workbookLoaded,
    worksheet,
    headers,
    rows,
    headerValues,
    rowsValues,
  };
};
