import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import { Card, CardContent, CardHeader } from "@/components/ui/card"; // Ensure your Card component is appropriately imported

import { cn } from "@/lib/cn";

import { useCases } from "./document-extract-store";

interface UseCaseGridProps extends React.HTMLAttributes<HTMLDivElement> {}

export const UseCaseGrid = ({ className, ...props }: UseCaseGridProps) => {
  let [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  return (
    <div className={cn("grid grid-cols-3 grid-rows-3", className)}>
      {useCases.map((useCase, idx) => (
        <Link
          to={`./upload?type=${useCase.type}`}
          className={cn(
            "group relative block p-2",
            useCase.type === "custom" && "col-span-3",
          )}
          onMouseEnter={() => setHoveredIndex(idx)}
          onMouseLeave={() => setHoveredIndex(null)}
          key={idx}
        >
          <AnimatePresence>
            {hoveredIndex === idx && (
              <motion.span
                className="absolute inset-0 z-10 block h-full w-full rounded-3xl bg-neutral-200 dark:bg-slate-800/[0.8]"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.1, delay: 0.1 },
                }}
              />
            )}
          </AnimatePresence>
          <Card
            className={cn(
              "relative z-20 flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-2xl border bg-black p-1 group-hover:border-slate-700 sm:p-4",
            )}
          >
            {/* <div className="debug-1"> */}
            <CardHeader className="flex items-center justify-center py-4">
              <useCase.icon className="h-6 w-6 text-muted-foreground sm:h-10 sm:w-10" />
            </CardHeader>
            <CardContent className="p-1 text-center sm:p-2">
              <h2 className="text-md text-center font-semibold">
                {useCase.title}
              </h2>
              {useCase.type === "custom" && (
                <p className="mx-auto mt-3  max-w-prose text-center text-xs font-medium text-muted-foreground sm:text-sm">
                  {useCase.description}
                </p>
              )}
            </CardContent>
            {/* </div> */}
          </Card>
        </Link>
      ))}
    </div>
  );
};
