import React from "react";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/cn";

import { useGetVendors } from "./useGetVendors";

type Props = {
  vendorId: number | undefined;
  setVendorId: React.Dispatch<React.SetStateAction<number | undefined>>;
  orgId: number;
};

export const VendorSelect = ({ setVendorId, orgId, vendorId }: Props) => {
  const [open, setOpen] = React.useState(false);

  const { data } = useGetVendors({ orgId });
  const vendors = data?.vendors;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex flex-col gap-4">
          <Label>Vendor:</Label>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className=" justify-between"
          >
            {vendorId
              ? vendors?.find((vendor) => vendor.id === vendorId)?.name
              : "Select vendor..."}
            <Icons.sort className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-full p-0">
        <Command>
          <CommandInput placeholder="Search vendors..." className="h-9" />
          <CommandEmpty>No framework found.</CommandEmpty>
          <ScrollArea className="h-52 overflow-auto">
            <CommandGroup className="">
              {vendors?.map((vendor) => (
                <CommandItem
                  key={vendor.id}
                  value={vendor.name}
                  onSelect={(currentVendorName) => {
                    const vendor = vendors?.find(
                      (vendor) =>
                        vendor.name.toLocaleLowerCase() ===
                        currentVendorName.toLocaleLowerCase(),
                    );
                    if (vendor?.id === vendorId) return;
                    setVendorId(
                      vendor?.id === vendorId ? vendorId : vendor?.id,
                    );
                    setOpen(false);
                  }}
                >
                  {vendor.name}
                  <Icons.check
                    className={cn(
                      "ml-auto h-4 w-4",
                      vendorId === vendor.id ? "opacity-100" : "opacity-0",
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
