import { useMemo, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import { Card, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { VendorsTableQuery } from "@/gql/graphql";
import { fuzzyFilter } from "@/lib/fuzzyFilter";

import { SubmissionsListTableSkeleton } from "../../home/(submissions-list)/SubmissionsListSkeleton";
import { getVendorTableColumns } from "./getVendorTableColumns";
import { useGetVendorTableData } from "./useGetVendorTableData";
import { VendorTableHeader } from "./VendorTableHeader";

export type Vendor = VendorsTableQuery["vendors"][number] & {
  lowAccuracySubmissions: number;
  errorSubmissions: number;
};

export function VendorTable() {
  const { data: vendorsData } = useGetVendorTableData();
  const vendors = useMemo(() => {
    return (
      vendorsData?.pages.flatMap((page) => {
        return page.vendors.map((vendor, i) => ({
          ...vendor,
          lowAccuracySubmissions:
            page.lowAccuracyVendors[i].lowAccuracySubmissions?.submissions,
          errorSubmissions: page.errorVendors[i].errorSubmissions?.submissions,
        }));
      }) || []
    );
  }, [vendorsData]);
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [rowSelection, setRowSelection] = useState({});

  const columns = getVendorTableColumns();

  const table = useReactTable({
    data: vendors,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    globalFilterFn: fuzzyFilter,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
  });

  const headerHeight = 36;
  const outerPadding = 24;
  const cardHeight = 142;
  const gridGap = 16;

  const maxHeight = `calc(100vh - ${
    outerPadding * 2
  }px - ${headerHeight}px - ${cardHeight}px - ${gridGap * 2}px)`;

  if (vendorsData == null) {
    return (
      <SubmissionsListTableSkeleton
        tableTitle="Partners"
        columnsCount={5}
        maxHeight={maxHeight}
      />
    );
  }

  return (
    <Card
      className={"flex h-full flex-col"}
      style={{
        maxHeight: maxHeight,
      }}
    >
      <VendorTableHeader table={table} />
      <CardContent className="mx-6 mb-6 flex flex-grow flex-col overflow-auto rounded-lg p-0 scrollbar-hide">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className="bg-muted/70 hover:bg-muted/70 "
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    navigate(`/dashboard/partner/${row.getValue("id")}`);
                  }}
                  className="cursor-pointer"
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-10 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
