import { Field } from "../use-gen-custom-fields";

export const lineItemFields: Field[] = [
  {
    name: "Description",
    type: "text",
    description: "Description of the item.",
  },
  {
    name: "Quantity",
    type: "number",
    description: "The quantity of the item.",
  },
  {
    name: "Price per Unit",
    type: "number",
    description: "The price per unit of the item.",
  },
  {
    name: "Total Amount",
    type: "number",
    description: "The total amount for the item.",
  },
];

const receiptSummaryFields: Field[] = [
  {
    name: "Invoice Subtotal",
    type: "number",
    description: "The subtotal amount of the invoice.",
  },
  {
    name: "Sales Tax",
    type: "number",
    description: "The total amount of sales tax applied to the invoice.",
  },
  {
    name: "Invoice Total",
    type: "number",
    description: "The total amount of the invoice.",
  },
];

const paymentInstructionsFields: Field[] = [
  {
    name: "Make Checks Payable To",
    type: "text",
    description: "The name to which checks should be made payable.",
  },
  {
    name: "ACH Instructions",
    type: "thing",
    description: "Instructions for ACH payments.",
    fields: [
      {
        name: "Account Title",
        type: "text",
        description: "The title of the account.",
      },
      {
        name: "Bank Name",
        type: "text",
        description: "The name of the bank.",
      },
      {
        name: "Bank Address",
        type: "text",
        description: "The address of the bank.",
      },
      {
        name: "Account Number",
        type: "text",
        description: "The account number.",
      },
      {
        name: "Routing Number",
        type: "text",
        description: "The routing number.",
      },
      {
        name: "Remittance Email",
        type: "text",
        description: "The email address for remittance.",
      },
    ],
  },
];

const vendorFields: Field[] = [
  {
    name: "Name",
    type: "text",
    description: "The name of the vendor.",
  },
  {
    name: "Address",
    type: "text",
    description: "The address of the vendor.",
  },
  {
    name: "Phone",
    type: "text",
    description: "The phone number of the vendor.",
  },
  {
    name: "Payment Instructions",
    type: "thing",
    description: "Payment instructions for the vendor.",
    fields: paymentInstructionsFields,
  },
];

const receiptFields: Field[] = [
  {
    name: "Line Items",
    type: "collection",
    description: "The list of line items.",
    fields: lineItemFields,
  },
  {
    name: "Receipt Summary",
    type: "thing",
    description: "Summary of the receipt.",
    fields: receiptSummaryFields,
  },
  {
    name: "Vendor",
    type: "thing",
    description: "Information about the vendor.",
    fields: vendorFields,
  },
];

export default receiptFields;
