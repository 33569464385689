import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const createManyFeedbackMutation = graphql(`
  mutation CreateManyFeedback($data: [FeedbackCreateManyInput!]!) {
    createManyFeedback(data: $data) {
      count
    }
  }
`);

export const useCreateManyFeedback = () => {
  return useGraphqlMutation({
    mutation: createManyFeedbackMutation,
  });
};
