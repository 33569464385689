import { NavLink, useParams } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/cn";

// TODO: Create more logic to disable options appropriately
export const VendorSubmissionFlowTabularHeader = () => {
  const { vendorId: vId, submissionId: sId } = useParams();
  const vendorId = Number(vId);
  const submissionId = Number(sId);

  const navOptions = [
    {
      label: "Home",
      href: `/vendor-submit/${vendorId}`,
      requires: [],
    },
    // {
    //   label: "Type Selection",
    //   href: `/vendor-submit/${vendorId}/submission/${submissionId}/confirm-submission-type`,
    //   requires: [vendorId, submissionId],
    // },
    {
      label: "Normalize",
      href: `/vendor-submit/${vendorId}/submission/${submissionId}/normalize`,
      requires: [vendorId, submissionId],
    },
    {
      label: "Validation",
      href: `/vendor-submit/${vendorId}/submission/${submissionId}/validation`,
      requires: [vendorId, submissionId],
    },
    {
      label: "Review Enrichments",
      href: `/vendor-submit/${vendorId}/submission/${submissionId}/corrections`,
      requires: [vendorId, submissionId],
    },
    {
      label: "Final Steps",
      href: `/vendor-submit/${vendorId}/submission/${submissionId}/summary`,
      requires: [vendorId, submissionId],
    },
  ];

  return (
    <header className="flex h-14 items-center gap-4 px-16">
      <div className="flex w-full max-w-none items-center justify-between ">
        {navOptions.map((option) => {
          const isDisabled = option.requires.some((r) => !r);

          return !isDisabled ? (
            <NavLink
              key={option.label}
              to={option.href}
              className={({ isActive, isPending, isTransitioning }) => {
                return cn(
                  "flex h-14 items-center justify-center border-b-2 border-transparent px-4 text-sm font-medium text-primary/80 hover:border-primary hover:text-primary",
                  isActive ? " border-primary text-primary" : "",
                  isPending || isTransitioning ? "cursor-wait opacity-50" : "",
                  isDisabled ? "cursor-not-allowed opacity-50" : "",
                );
              }}
              end
            >
              {option.label}
            </NavLink>
          ) : (
            <Tooltip key={option.label}>
              <TooltipTrigger>
                <div
                  className={cn(
                    "flex h-14 items-center justify-center border-b-2 border-transparent px-4 text-sm font-medium text-primary/80 hover:border-primary hover:text-primary",
                    "cursor-not-allowed opacity-50",
                  )}
                >
                  {option.label}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="p-4 text-sm text-primary/80">
                  Please complete the previous steps first
                </div>
              </TooltipContent>
            </Tooltip>
          );
        })}
      </div>
    </header>
  );
};
