import { Suspense } from "react";
import { Outlet, useParams } from "react-router-dom";

import { usePathRedirect } from "@/lib/hooks/routing";

import { TransformationsNav } from "../(stack-lists)/TransformationsNav";
import { useGetTransformationsStackListData } from "../(stack-lists)/useGetTransformationsStackListData";

export function SubmissionCorrectionsLayout() {
  const { submissionId } = useParams();

  const { data } = useGetTransformationsStackListData({
    submissionId: Number(submissionId),
  });
  const transformations = data?.submission?.transformations;

  usePathRedirect({
    matchPath: "dashboard/submissions/:submissionId/corrections",
    to: () => {
      if (transformations && transformations.length > 0) {
        return `transformations/${transformations[0].id}`;
      }
    },
  });

  return (
    <div className="grid flex-1 grid-rows-[auto_1fr] gap-3 ">
      <Suspense fallback={null}>
        <TransformationsNav />
      </Suspense>
      <Outlet />
    </div>
  );
}
