import { useNavigate } from "react-router-dom";

import { SubmissionError } from "../useGetSubmissionErrors";

type Props = {
  error: SubmissionError;
  submissionType: string | undefined;
  vendorId: number | undefined;
};

export const useGetErrorSolution = ({
  error,
  submissionType,
  vendorId,
}: Props) => {
  const navigate = useNavigate();

  if (error.action === "MAPPING") {
    return () => {
      navigate(`/dashboard/partner/${vendorId}/mapping/${submissionType}`);
    };
  }
  return null;
};
