import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CopyToClipboard } from "@/components/CopyToClipboardButton";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

// import { EmailResultsDialog } from "./(stuff)/email-results-dialog";
import { ExtractionField } from "./(stuff)/extraction-field";
import { JsonBlock } from "./(stuff)/json-block";
import { DocumentExtractHeader } from "./document-extract-header";
import { DocumentExtractScreen } from "./document-extract-screen";
import { useDocumentExtractStore } from "./document-extract-store";

export const ResultsPage = () => {
  const navigate = useNavigate();
  const { results, uploadedFile } = useDocumentExtractStore();

  useEffect(() => {
    if (uploadedFile == null) {
      navigate("/document-extract");
    }
  }, [uploadedFile, navigate]);

  return (
    <DocumentExtractScreen className="grid grid-cols-1 gap-5 px-2 sm:gap-6 sm:px-4 md:grid-cols-2">
      {Object.keys(results).length > 0 ? (
        <div className=" flex flex-col items-center justify-center sm:gap-6">
          <DocumentExtractHeader
            header="Your Results"
            subHeader="Need to implement this workflow? Shoot us an email hello@intuitivesystems and we can get started today."
          />
          {/* <EmailResultsDialog /> */}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center sm:gap-6">
          <DocumentExtractHeader
            header="No Results Found"
            subHeader="We couldn't find any results for your document."
          />
          <Button className="">Submit Bug Report</Button>
        </div>
      )}

      <Tabs
        defaultValue="json"
        className="grid grid-rows-[auto_1fr] self-stretch overflow-hidden"
      >
        <TabsList className="mb-2 ml-auto grid w-full grid-cols-2 border bg-transparent sm:w-72">
          <TabsTrigger className="border-r" value="json">
            JSON
          </TabsTrigger>
          <TabsTrigger className="" value="normies">
            Form Values
          </TabsTrigger>
        </TabsList>
        <TabsContent value="json" asChild>
          <ScrollArea className="relative self-stretch rounded-md border">
            <JsonBlock
              className="!m-0 h-full text-xs sm:text-sm"
              json={results}
            />
            <div className="absolute right-2 top-2">
              <Tooltip>
                <TooltipTrigger>
                  <CopyToClipboard value={JSON.stringify(results, null, 2)} />
                </TooltipTrigger>
                <TooltipContent>Copy JSON</TooltipContent>
              </Tooltip>
            </div>
          </ScrollArea>
        </TabsContent>
        <TabsContent value="normies" asChild>
          <div className="grid grid-cols-2 gap-4 overflow-auto scrollbar-hide">
            {Object.keys(results).length > 0 &&
              Object.entries(results).map(([key, value], index) => (
                <ExtractionField key={index} label={key} value={value} />
              ))}
          </div>
        </TabsContent>
      </Tabs>
    </DocumentExtractScreen>
  );
};
