import { useAutoAnimate } from "@formkit/auto-animate/react";
import { CheckSquare } from "lucide-react";
import { useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { graphql } from "@/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

import {
  ValidationTypeItem,
  ValidationTypeItemSkeleton,
} from "./ValidationTypeItem";

export const ValidationTypeList: React.FC = () => {
  const { organizationId } = useParams();

  const [parent] = useAutoAnimate();

  const { data, isLoading } = useGraphqlQuery({
    queryKey: ["validation-types", `organizationId=${organizationId}`],
    query: graphql(`
      query ValidationTypeList($where: ValidationTypeWhereInput) {
        validationTypes(where: $where) {
          id
          name
          submissionTypes {
            id
          }
          updatedAt
          description
        }
      }
    `),
    variables: {
      where: {
        organizationId: {
          equals: Number(organizationId),
        },
      },
    },
  });

  return (
    <div className="max-w-3xl">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="flex items-center text-2xl font-semibold">
          <div className="mr-4 rounded-full border border-border p-3">
            <CheckSquare />
          </div>
          Validations
        </h2>
        <Button disabled className="w-48">
          Create validation
        </Button>
      </div>
      <div className="flex flex-col gap-4" ref={parent}>
        {isLoading ? (
          <>
            <ValidationTypeItemSkeleton key="loader-1" />
            <ValidationTypeItemSkeleton key="loader-2" />
            <ValidationTypeItemSkeleton key="loader-3" />
          </>
        ) : data && data.validationTypes.length > 0 ? (
          data.validationTypes.map((validationType, i) => (
            <ValidationTypeItem
              key={validationType.id + "-" + i}
              validationType={validationType}
            />
          ))
        ) : (
          <Card className="text-md max-w-3xl font-medium text-muted-foreground">
            <CardContent className="flex justify-center pt-6">
              No Validation Types found
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};
