import { QueryKey } from "@tanstack/react-query";

import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";
import { queryClient } from "@/queryClient";

export const updateFeedbackMutation = graphql(`
  mutation UpdateTransformation(
    $data: TransformationUpdateInput!
    $where: TransformationWhereUniqueInput!
  ) {
    updateOneTransformation(data: $data, where: $where) {
      id
    }
  }
`);

type Params = {
  queryKey: QueryKey;
};

export const useUpdateTransformation = ({ queryKey }: Params) => {
  return useGraphqlMutation({
    mutation: updateFeedbackMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    //   onError(error, variables, context) {
    // toast.error("Error submitting labels");
    // @ts-ignore - https://github.com/TanStack/query/discussions/3434#discussioncomment-2425225
    // queryClient.setQueryData(queryKey, context.previousData);
    //   },
  });
};
