import { Edge, Node } from "reactflow";
import { create } from "zustand";

import { InputHeaderNodeProps } from "@/components/header-mapping/nodes/InputHeaderNode";
import { OutputHeaderNodeProps } from "@/components/header-mapping/nodes/OutputHeaderNode";

export interface InputNode extends Partial<Node> {
  example: string;
}

export interface OutputNode extends Partial<Node> {}

type HeaderMappingState = {
  edges: Edge[];
  inputNodes: InputNode[];
  outputNodes: OutputNode[];
  activeNodeId: InputHeaderNodeProps["id"] | OutputHeaderNodeProps["id"] | null;
};

type HeaderMappingActions = {
  setEdges: (edges: HeaderMappingState["edges"]) => void;
  setInputNodes: (inputNodes: HeaderMappingState["inputNodes"]) => void;
  setOutputNodes: (outputNodes: HeaderMappingState["outputNodes"]) => void;
  setActiveNodeId: (activeNode: HeaderMappingState["activeNodeId"]) => void;
};

export const useHeaderMappingStore = create<
  HeaderMappingState & HeaderMappingActions
>((set) => {
  return {
    edges: [],
    inputNodes: [],
    outputNodes: [],
    activeNodeId: null,
    setEdges: (edges) => set({ edges }),
    setInputNodes: (inputNodes) => set({ inputNodes }),
    setOutputNodes: (outputNodes) => set({ outputNodes }),
    setActiveNodeId: (activeNodeId) => set({ activeNodeId }),
  };
});
