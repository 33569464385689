import { Suspense } from "react";

import { PageHeader } from "@/components/PageHeader";
import { StatCardSkeleton } from "@/components/StatCardSkeleton";
import { cn } from "@/lib/cn";

import { LowConfidencePartnersCard } from "./analytics/LowConfidencePartnersCard";
import { TotalActivePartnersCard } from "./analytics/TotalActivePartnersCard";
import { TotalPartnersCard } from "./analytics/TotalPartnersCard";
import { VendorTable } from "./VendorTable";
import { VendorTableStoreProvider } from "./VendorTableContext";

export const VendorsSelection = () => {
  return (
    <div className="grid h-full grid-rows-[auto_auto_1fr] gap-4">
      <PageHeader title={"Partners"} />

      <article className={cn("grid grid-cols-3 gap-4")}>
        <Suspense fallback={<StatCardSkeleton />}>
          <TotalPartnersCard />
        </Suspense>

        <Suspense fallback={<StatCardSkeleton />}>
          <TotalActivePartnersCard />
        </Suspense>

        <Suspense fallback={<StatCardSkeleton />}>
          <LowConfidencePartnersCard />
        </Suspense>
      </article>

      <VendorTableStoreProvider>
        <VendorTable />
      </VendorTableStoreProvider>
    </div>
  );
};
