import { graphql } from "@/gql/gql";
import { GetSubmissionErrorsQuery } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Submission = NonNullable<GetSubmissionErrorsQuery["submission"]>;
export type SubmissionError = Submission["errors"][0];

type Props = {
  submissionId: number | undefined;
};

export const useGetSubmissionErrors = ({ submissionId }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetSubmissionErrors", `submissionId=${submissionId}`],
    query: graphql(`
      query GetSubmissionErrors($where: SubmissionWhereUniqueInput!) {
        submission(where: $where) {
          errors {
            id
            message
            status
            action
          }
          vendorId
          type {
            type
          }
        }
      }
    `),
    variables: {
      where: {
        id: submissionId,
      },
    },
  });
};
