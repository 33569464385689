import { useState } from "react";

import { HeaderMapping } from "@/components/header-mapping/HeaderMapping";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { ColumnMapping } from "./Column/ColumnMapping";
import { useGetVendorMapping } from "./useGetVendorMapping";

enum MappingView {
  Nodes = "Nodes",
  Columns = "Columns",
}

export function SubmissionMappingPage() {
  const { submissionType } = useGetVendorMapping();
  const [currView, setCurrView] = useState<MappingView>(MappingView.Nodes);

  return (
    <Card className="flex flex-grow flex-col gap-5 border-none bg-transparent">
      <CardHeader className="flex w-full flex-row justify-between px-0 py-0">
        <div className="space-y-1">
          <CardTitle className="text-xl">
            {submissionType && submissionType.name} Column Matching
          </CardTitle>
          <CardDescription>
            Match the columns submitted by the vendor to the columns in the
            system
          </CardDescription>
        </div>
        <Tabs value={currView} defaultValue={MappingView.Nodes}>
          <TabsList>
            <TabsTrigger
              onClick={() => setCurrView(MappingView.Nodes)}
              value={MappingView.Nodes}
            >
              {MappingView.Nodes}
            </TabsTrigger>
            <TabsTrigger
              onClick={() => setCurrView(MappingView.Columns)}
              value={MappingView.Columns}
            >
              {MappingView.Columns}
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </CardHeader>

      {currView === MappingView.Nodes && <HeaderMapping />}
      {currView === MappingView.Columns && <ColumnMapping />}
    </Card>
  );
}
