import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useUpdateNormalizeMapping = () => {
  return useGraphqlMutation({
    mutation: graphql(`
      mutation UpdateNormalizeMapping(
        $data: NormalizeMappingUpdateInput!
        $where: NormalizeMappingWhereUniqueInput!
      ) {
        updateOneNormalizeMapping(data: $data, where: $where) {
          id
        }
      }
    `),
  });
};
