import React from "react";

import { IconType } from "@/components/Icons";
import { Skeleton, SkeletonText } from "@/components/ui/skeleton";

interface FilterProps extends React.HTMLAttributes<HTMLDivElement> {
  FilterIcon: IconType;
  label: string;
  value?: string | number;
}

export const FilterPopoverFilter = ({
  FilterIcon,
  label,
  value,
  ...props
}: FilterProps) => {
  return (
    <div
      className="flex items-center gap-2 font-semibold tabular-nums"
      {...props}
    >
      <FilterIcon className="mb-[1.5px] h-5 w-5" aria-hidden="true" />
      <span className="text-base">{value}</span>
      <span className="text-xs text-muted-foreground">{label}</span>
    </div>
  );
};

export const SkeletonFilterPopoverFilter = () => {
  return (
    <div className="flex items-center gap-2 font-medium tabular-nums">
      <Skeleton className="h-5 w-5" />
      <SkeletonText className="text-base" />
      <SkeletonText className="text-xs" />
    </div>
  );
};
