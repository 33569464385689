import { Suspense } from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "@/components/Spinner";

import { TransformationPredictionsTable } from "../corrections/:transformationId/(transformation-table)/TransformationPredictionsTable";
import { SubmissionErrors } from "./errors/SubmissionErrors";
import { useGetSubmissionErrors } from "./useGetSubmissionErrors";

export function SubmissionCorrectionsView() {
  const { submissionId } = useParams();

  const { data } = useGetSubmissionErrors({
    submissionId: Number(submissionId),
  });
  const submission = data?.submission;
  const errors = submission?.errors ?? [];
  const vendorId = submission?.vendorId;
  const submissionType = submission?.type?.type;

  return (
    <Suspense fallback={<Spinner />}>
      {errors.length > 0 ? (
        <SubmissionErrors
          vendorId={vendorId}
          submissionType={submissionType}
          errors={errors}
        />
      ) : (
        <TransformationPredictionsTable />
      )}
    </Suspense>
  );
}
