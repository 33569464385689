import { create } from "zustand";

import { Icons } from "@/components/Icons";

import { benefitEnrollmentFields } from "./schemas/benefit-enrollment-fields";
import { billOfLadingFields } from "./schemas/bill-of-lading-fields";
import { invoiceFields } from "./schemas/invoice-fields";
import { productCertificateFields } from "./schemas/product-certificate-fields";
import receiptFields from "./schemas/receipt-fields";
import { DocumentExtractResponse } from "./use-document-extract-mutation";
import { Field } from "./use-gen-custom-fields";

export const useCases = [
  {
    id: 1,
    type: "invoice",
    title: "Invoice",
    description:
      "Extract key data such as invoice numbers, dates, line items, and totals from your invoices.",
    icon: Icons.invoice,
    fields: invoiceFields,
  },
  {
    id: 2,
    type: "receipt",
    title: "Receipt",
    description:
      "Extract essential receipt details like purchase date, store information, item descriptions, and prices.",
    icon: Icons.receipt,
    fields: receiptFields,
  },
  {
    id: 3,
    type: "bill-of-lading",
    title: "Bill of Lading",
    description:
      "Extract critical shipping information including consignee details, shipment items, and transport details.",
    icon: Icons.billOfLading,
    fields: billOfLadingFields,
  },
  {
    id: 4,
    type: "product-certificate",
    title: "Product Certificate",
    description:
      "Extract critical shipping information including Contractor Company, Contractor Loyalty Number, Total Value, Certificate Number, Date Issues, Date of Expiration",
    icon: Icons.calendar,
    fields: productCertificateFields,
  },
  {
    id: 5,
    type: "benefit-enrollment",
    title: "Benefit Enrollments",
    description:
      "Extract critical enrollment information detailing with medical and dental plans are selected by the employee.",
    icon: Icons.user,
    fields: benefitEnrollmentFields,
  },
  {
    id: 6,
    type: "component-datasheet",
    title: "Component Datasheet",
    description:
      "Extract and display key specifications and features of this electrical component, including resistance range, power rating, temperature coefficients, and environmental characteristics.",
    icon: Icons.action,
    fields: benefitEnrollmentFields,
  },
  {
    id: 7,
    type: "custom",
    title: "Custom Document",
    description: "Define your own data schema and extract from any document!",
    icon: Icons.aiStars,
    fields: undefined,
  },
] as const;

type DocumentExtractState = {
  useCases: typeof useCases;
  uploadedFile: File | undefined;
  jsonSchema: any;
  recommendedFields: Field[];
  results: DocumentExtractResponse["data"][0];
};

type DocumentExtractActions = {
  setUseCases: (useCases: DocumentExtractState["useCases"]) => void;
  setUploadedFile: (file: DocumentExtractState["uploadedFile"]) => void;
  setJsonSchema: (jsonSchema: DocumentExtractState["jsonSchema"]) => void;
  setRecommendedFields: (
    fields: DocumentExtractState["recommendedFields"],
  ) => void;
  setResults: (results: DocumentExtractState["results"]) => void;
  getCurrentUseCase: (type?: string) => (typeof useCases)[number] | undefined;
};

export const useDocumentExtractStore = create<
  DocumentExtractState & DocumentExtractActions
>((set, get) => {
  return {
    useCases,
    uploadedFile: undefined,
    jsonSchema: {},
    recommendedFields: [],
    results: {},
    // sets
    setUseCases: (useCases) => set({ useCases }),
    setUploadedFile: (uploadedFile) => set({ uploadedFile }),
    setJsonSchema: (jsonSchema) => set({ jsonSchema }),
    setRecommendedFields: (recommendedFields) => set({ recommendedFields }),
    setResults: (results) => set({ results }),
    // gets
    getCurrentUseCase: (type?: string) => {
      if (!type) return;
      const currentUseCase = get().useCases.find(
        (useCase) => useCase.type === type,
      );
      return currentUseCase;
    },
  };
});
