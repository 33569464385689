import { useParams } from "react-router-dom";

import { SubmissionsList } from "@/app/dashboard/home/(submissions-list)/SubmissionsList";

export const VendorSummaryContent = () => {
  const { vendorId } = useParams();

  const headerHeight = 89;
  const outerPadding = 24;
  const typesHeight = 90;
  const gridGap = 16;

  const maxHeight = `calc(100vh - ${
    outerPadding * 2
  }px - ${headerHeight}px - ${typesHeight}px - ${gridGap}px)`;

  return (
    <SubmissionsList
      initVendorId={vendorId}
      maxHeight={maxHeight}
      className=""
      tableTitle="Recent Submissions"
      colsShown={{
        Id: false,
        Type: true,
        Partner: false,
        Uploaded: true,
        Status: true,
        "Accuracy %": true,
        Rows: true,
      }}
    />
  );
};
