import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { DialogFooter } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SupportedDialects } from "@/gql/graphql";
import { useCurrentOrgSubmissionTypesQuery } from "@/lib/hooks/queries/SubmissionTypes";
import { toSnakeCase } from "@/lib/string";

import {
  ExportDB,
  getOrgExportSettingsQueryOptions,
} from "./getOrgExportSettings";
import { useCreateOrgExportDatabase } from "./useCreateOrgExportDatabase";
import { useUpdateOrgExportSettings } from "./useUpdateOrgExportDatabase";

type UpdateMutationExportMappingModifiersType = {
  delete: { id: number }[];
  create: { submissionType: { connect: { id: number } } }[];
  update: {
    data: {
      submissionType: {
        connect: {
          id: number;
        };
      };
    };
    where: {
      id: number;
    };
  }[];
};

const TableSchema = z.object({
  submissionType: z
    .string({
      required_error: "Submission Type is required",
    })
    .min(1, "Submission Type cannot be empty"),
});

const ExportSchema = z.object({
  PSQLConnectionString: z
    .string({
      required_error: "PSQL Connection String is required",
    })
    .min(1, "PSQL Connection String cannot be empty"), // Explicitly ensuring the string is not empty
  exportName: z
    .string({
      required_error: "DB Export Name is required",
    })
    .min(1, "Table Name cannot be empty"), // Explicitly ensuring the string is not empty
  dialect: z
    .string({
      required_error: "Dialect is required",
    })
    .min(1, "Dialect cannot be empty"), // Explicitly ensuring the string is not empty
  tables: z.array(TableSchema).min(1, "At least one table is required"),
});

type Props = {
  exportDB?: ExportDB;
  setOpen: (open: boolean) => void;
};

export const ExportForm = ({ exportDB, setOpen }: Props) => {
  const { organizationId } = useParams();
  const queryClient = useQueryClient();
  const queryOpts = getOrgExportSettingsQueryOptions(organizationId as string);

  const { queryKey } = queryOpts;
  const { data: submissionTypesData } = useCurrentOrgSubmissionTypesQuery(); // TODO: Change to vendor submission types query
  const { submissionTypes } = submissionTypesData;

  const { mutate: createMutation } = useCreateOrgExportDatabase();
  const { mutate: updateMutation } = useUpdateOrgExportSettings();

  const form = useForm<z.infer<typeof ExportSchema>>({
    resolver: zodResolver(ExportSchema),
    defaultValues: {
      PSQLConnectionString: exportDB?.connectionString,
      dialect: exportDB?.dialect || SupportedDialects.Postgresql,
      exportName: exportDB?.name,
      tables:
        exportDB?.exportMappings.map((mapping) => ({
          tableName: toSnakeCase(mapping.submissionType.name),
          submissionType: mapping.submissionType.type,
        })) || [],
    },
  });

  // Use useFieldArray to manage the tables array
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "tables",
  });

  const onSubmit = (values: z.infer<typeof ExportSchema>) => {
    const { PSQLConnectionString, dialect, exportName, tables } = values;

    if (
      !organizationId ||
      !PSQLConnectionString ||
      !dialect ||
      !exportName ||
      tables.length === 0
    ) {
      return;
    }

    const onSuccessfulSubmit = () => {
      setOpen(false);
      toast("Success", {
        description: exportDB
          ? `Organization export settings updated.`
          : `Organization export settings created.`,
        duration: 2000,
      });
      queryClient.invalidateQueries({
        queryKey,
      });
    };

    // when updating the exportDB, we need to update the exportMappings
    // if the table is already in the exportDB, update it
    // if the table is in the exportDB but not in the tables, delete it
    // if the table is not in the exportDB, create it
    // give this back as an object with the fields that need to be updated
    const updateMutationExportMappingModifiers: UpdateMutationExportMappingModifiersType =
      {
        delete: [],
        create: [],
        update: [],
      };

    exportDB?.exportMappings.forEach((mapping) => {
      const existingMapping = tables.find(
        (table) => table.submissionType === mapping.submissionType.type,
      );

      if (existingMapping) {
        updateMutationExportMappingModifiers.update.push({
          data: {
            submissionType: {
              connect: {
                id: Number(mapping.submissionType.id),
              },
            },
          },
          where: {
            id: mapping.id,
          },
        });
      } else {
        updateMutationExportMappingModifiers.delete.push({ id: mapping.id });
      }
    });

    tables.forEach((table) => {
      const existingMapping = exportDB?.exportMappings.find(
        (mapping) => mapping.submissionType.type === table.submissionType,
      );

      if (!existingMapping) {
        updateMutationExportMappingModifiers.create.push({
          submissionType: {
            connect: {
              id: submissionTypes.find(
                (type) => type.type === table.submissionType,
              )?.id as number,
            },
          },
        });
      }
    });

    exportDB
      ? updateMutation(
          {
            data: {
              connectionString: {
                set: PSQLConnectionString,
              },
              dialect: {
                set: dialect as SupportedDialects,
              },
              name: {
                set: exportName,
              },
              organization: {
                connect: {
                  id: Number(organizationId),
                },
              },
              exportMappings: { ...updateMutationExportMappingModifiers },
            },
            where: {
              id: exportDB.id,
            },
          },
          {
            onSuccess: onSuccessfulSubmit,
          },
        )
      : createMutation(
          {
            data: {
              connectionString: PSQLConnectionString,
              dialect: dialect as SupportedDialects,
              exportMappings: {
                create: tables.map((table) => ({
                  submissionType: {
                    connect: {
                      id: submissionTypes.find(
                        (type) => type.type === table.submissionType,
                      )?.id,
                    },
                  },
                })),
              },
              name: exportName,
              organization: {
                connect: {
                  id: Number(organizationId),
                },
              },
            },
          },
          {
            onSuccess: onSuccessfulSubmit,
          },
        );
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="PSQLConnectionString"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Database Connection String</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="exportName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Export Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="dialect"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Database Dialect</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a database dialect" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.keys(SupportedDialects).map((dialect, i) => (
                        <SelectItem key={i} value={dialect.toUpperCase()}>
                          {dialect.toUpperCase()}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {/* Dynamically generated fields for tables */}
          {fields.map((field, index) => {
            return (
              <div
                key={field.id}
                className="flex items-end justify-between gap-4"
              >
                <div>
                  <Controller
                    name={`tables.${index}.submissionType`}
                    control={form.control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormItem ref={ref}>
                        <FormLabel>Submission Type</FormLabel>
                        <Select onValueChange={onChange} defaultValue={value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a submission type" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {submissionTypes.map((type, i) => (
                              <SelectItem key={i} value={type.type}>
                                {type.type}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormItem>
                    )}
                  />
                </div>

                <Button variant={"ghost"} size={"icon"}>
                  <Icons.trash
                    className="text-foreground/80"
                    onClick={() => remove(index)}
                  />
                </Button>
              </div>
            );
          })}
          <div>
            <Button
              disabled={
                form.getValues().tables.length >= submissionTypes.length
              }
              variant={"outline"}
              onClick={() => append({ submissionType: "" })}
            >
              {form.getValues().tables.length >= submissionTypes.length
                ? "All Tables Used"
                : "Add Table"}
            </Button>
          </div>
          <div>
            {form.formState.errors.tables && (
              <FormMessage>
                {form.formState.errors.tables.root?.message}
              </FormMessage>
            )}
            {form.formState.errors.tables && (
              <FormMessage>Table Submission Type is required</FormMessage>
            )}
          </div>
          <DialogFooter>
            <Button variant="outline">
              {exportDB ? "Save Changes" : "Submit"}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </>
  );
};
