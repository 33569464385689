import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";

type Props = {
  onClick: () => void;
};

export const PredictionTableClearFiltersButton = ({ onClick }: Props) => {
  return (
    <Button
      onClick={onClick}
      size="sm"
      variant="outline"
      className="gap-2 whitespace-nowrap"
    >
      {"Clear Filters"}
      <Icons.remove className="h-3.5 w-3.5" />
    </Button>
  );
};
