import { DashboardScreen } from "@/app/dashboard/DashboardScreen";

import { MemberInviteForm } from "./MemberInviteForm";
import { MemberTable } from "./MemberTable";

export function MembersPage() {
  return (
    <DashboardScreen className="flex flex-col gap-6" scrollable>
      <MemberInviteForm />
      <MemberTable />
    </DashboardScreen>
  );
}
