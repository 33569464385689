import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";

type Props = {
  filterName: string;
  onRemove: () => void;
};

export const FilterLabelButton = ({ filterName, onRemove }: Props) => {
  return (
    <Button size="sm" variant="outline" className="gap-2 whitespace-nowrap">
      {filterName}
      <Icons.remove onClick={onRemove} className="h-3.5 w-3.5" />
    </Button>
  );
};
