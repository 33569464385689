import { graphql } from "@/gql";
import { createQueryOptions } from "@/lib/hooks/graphql";

const predictionRowsQuery = graphql(`
  query PredictionRows(
    $where: PredictionWhereInput
    $feedbackOrderBy: [FeedbackOrderByWithRelationAndSearchRelevanceInput!] = {
      createdAt: desc
    }
    $feedbackTake: Int = 1
  ) {
    predictions(where: $where) {
      transformation {
        type {
          category
        }
      }
      id
      createdAt
      metadata {
        key
        value
        updatedAt
      }
      labels {
        id
        value
        name
        confidence
        updatedAt
        feedback(orderBy: $feedbackOrderBy, take: $feedbackTake) {
          id
          upvote
          content
        }
      }
    }
  }
`);

export const getTransformationPredictionsTableQueryOptions = (
  transformationId: number,
) => {
  return createQueryOptions({
    query: predictionRowsQuery,
    variables: {
      where: {
        transformationId: { equals: transformationId },
      },
    },
  });
};
