import { SubmissionTypesSection } from "./SubmissionTypesSection";
import { VendorSummaryContent } from "./VendorSummaryContent";

export const VendorSummary = () => {
  return (
    <div className="grid grid-rows-[auto_1fr] gap-4">
      <SubmissionTypesSection />
      <VendorSummaryContent />
    </div>
  );
};
