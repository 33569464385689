import { createContext, useContext, useRef } from "react";
import { DateRange } from "react-day-picker";
import { createStore, StoreApi, useStore } from "zustand";

import { SubmissionStatus } from "@/gql/graphql";

export enum SubmissionType {
  INV = "INV",
  POS = "POS",
}

type SubmissionsListState = {
  vendorId?: string;
  vendorName?: string;
  dateRange?: DateRange;
  status?: SubmissionStatus;
  submissionType?: SubmissionType;
};

type SubmissionsListActions = {
  setVendorId: (arg0: string) => void;
  setVendorName: (arg0: string) => void;
  setDateRange: (arg0?: DateRange) => void;
  setStatus: (arg0: SubmissionStatus) => void;
  setSubmissionType: (arg0: SubmissionType) => void;
};

const StoreContext = createContext(
  {} as StoreApi<SubmissionsListState & SubmissionsListActions>,
);

export const SubmissionsListStoreProvider = ({
  children,
  initValues,
}: {
  children: React.ReactNode;
  initValues?: SubmissionsListState;
}) => {
  const storeRef =
    useRef<StoreApi<SubmissionsListState & SubmissionsListActions>>();
  if (!storeRef.current) {
    storeRef.current = createStore<
      SubmissionsListState & SubmissionsListActions
    >((set) => ({
      ...initValues,
      setVendorId: (vendorId) => set({ vendorId }),
      setVendorName: (vendorName) => set({ vendorName }),
      setDateRange: (dateRange) => set({ dateRange }),
      setStatus: (status) => set({ status }),
      setSubmissionType: (submissionType) => set({ submissionType }),
    }));
  }
  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );
};

export const useSubmissionsListStoreInContext = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error("Missing StoreProvider");
  }
  return useStore(store, (state) => state);
};
