import { useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";

import { useGetSubmissions } from "@/app/dashboard/home/(submissions-list)/useGetSubmissions";
import { SubmissionStatus } from "@/gql/graphql";

import { getSubmissionsTableHeadersAndRows } from "./getSubmissionsTableHeadersAndRows";
import { useGetSubmissionsTableColDefs } from "./useGetSubmissionsTableColDefs";

type Props = {
  validStatuses: SubmissionStatus[];
};

export const SubmissionsTable = ({ validStatuses }: Props) => {
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const dateRange = undefined;
  const submissionType = undefined;

  //   const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
  const { data } = useGetSubmissions({
    vendorId,
    dateRange,
    status: validStatuses,
    submissionType,
  });

  const submissions = useMemo(() => {
    return data?.pages?.flatMap((page) => page.submissions) ?? [];
  }, [data]);

  const { rows } = getSubmissionsTableHeadersAndRows({ submissions });
  const [_gridApi, setGridApi] = useState<AgGridReact["api"]>();

  const colDefs = useGetSubmissionsTableColDefs({ submissions });

  return (
    <div
      id="transformation-table"
      className="ag-theme-custom cell-error h-[600px]"
    >
      <AgGridReact
        onRowClicked={(params) => {
          //   @ts-ignore type error comes from not using ag-grid-community types so far
          const submissionId = params.data.Id;
          navigate(
            `/vendor-submit/${vendorId}/submission/${submissionId}/normalize`,
          );
        }}
        onGridReady={(params) => {
          setGridApi(params.api);
        }}
        rowSelection="multiple"
        pagination={true}
        rowData={rows}
        columnDefs={colDefs}
        // isExternalFilterPresent={() => externalFilters.length > 0}
        // doesExternalFilterPass={(node) =>
        //   doesExternalFilterPass(node, externalFilters)
        // }
      />
    </div>
  );
};
