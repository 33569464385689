import { DashboardScreen } from "@/app/dashboard/DashboardScreen";

import { Spinner } from "./Spinner";

export function DashboardScreenFallback() {
  return (
    <DashboardScreen
      title="Loading..."
      className="flex h-display w-full items-center justify-center"
    >
      <Spinner size="2xl" />
    </DashboardScreen>
  );
}
