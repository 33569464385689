import { ColumnDef } from "@tanstack/react-table";
import { FileClock } from "lucide-react";
import { match, P } from "ts-pattern";

import { Icons } from "@/components/Icons";
import {
  getBadgeStatusFromSubmissionStatus,
  StatusBadge,
} from "@/components/StatusBadge";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  GetSubmissionsInfiniteQuery,
  SubmissionStatus,
  Vendor,
} from "@/gql/graphql";
import { cn } from "@/lib/cn";
import { formatEnumLabel } from "@/lib/formatEnumLabel";
import { usePrepareFileDownload } from "@/lib/hooks/queries/usePrepareFileDownload";
import { onDownloadFileToComputer } from "@/lib/onDownloadFileToComputer";
import { convertDecimaltoPercentageString } from "@/lib/string";

export type SubmissionRowType = GetSubmissionsInfiniteQuery["submissions"][0];

export type ColType = ColumnDef<SubmissionRowType>;

const SubmissionListColumns: ColType[] = [
  {
    header: "Id",
    accessorFn: (submission) => submission.id,
    cell: (ctx) => {
      const id = ctx.getValue() as string;
      return <span className="">{id}</span>;
    },
  },
  {
    header: "Partner",
    accessorFn: (submission) => {
      return submission.vendor.name;
    },
    cell: (ctx) => {
      const vendorName = ctx.getValue() as Vendor["name"];
      return <div className="w-72 truncate">{vendorName}</div>;
    },
  },
  {
    header: "Type",
    accessorFn: (submission) => submission?.type?.type ?? "Unknown",
    cell: (ctx) => {
      const type = ctx.getValue() as string;
      return <div className="">{type}</div>;
    },
  },
  {
    header: "Uploaded",
    accessorFn: (submission) => submission.createdAt,
    cell: (ctx) => {
      const date = new Date(ctx.getValue() as string);
      return (
        <div className="tabular-nums">
          {date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
          })}
        </div>
      );
    },
  },

  {
    header: "Status",
    accessorFn: (submission) => submission.status,
    cell: (ctx) => {
      const status = ctx.getValue() as SubmissionStatus;
      return (
        <StatusBadge
          className="w-[100px]"
          status={getBadgeStatusFromSubmissionStatus(status)}
        >
          {formatEnumLabel(status)}
        </StatusBadge>
      );
    },
  },

  {
    header: "Time to Complete",
    accessorFn: (submission) => {
      // return a random number between 5-10 minutes
      return Math.floor(Math.random() * (10 - 5 + 1) + 5);
    },
    cell: (ctx) => {
      const time = ctx.getValue() as number;
      return <span className="">{time} minutes</span>;
    },
  },
  {
    header: "Accuracy %",
    accessorFn: (submission) => {
      const metadata = submission.metadata;
      const accuracy = metadata?.find(
        (m) => m.key === "initial_estimated_accuracy",
      )?.value;
      return Number(accuracy);
    },
    cell: (ctx) => {
      const value = ctx.getValue();

      return match(value)
        .with(P.number.between(0, 1), (decimal) => {
          const percentage = convertDecimaltoPercentageString(decimal, 0);
          return <span className="font-medium tabular-nums">{percentage}</span>;
        })
        .otherwise(() => <span className="text-muted">Unknown</span>);
    },
  },
  {
    header: "Rows",
    accessorFn: (submission) => {
      const metadata = submission.metadata;
      const numRows = metadata?.find((m) => m.key === "file_rows")?.value;
      return Number(numRows);
    },
    cell: (ctx) => {
      const value = ctx.getValue();

      return match(value)
        .with(P.number.int().gte(0), (numRows) => (
          <span className="font-medium tabular-nums">{numRows}</span>
        ))
        .otherwise(() => <span className="text-muted">Unknown</span>);
    },
  },
  {
    header: "Input File",
    accessorFn: (submission) => submission.file.name,
    cell: (ctx) => {
      const status = ctx.getValue() as string;
      return (
        <div className="w-64 truncate font-mono text-xs text-muted-foreground">
          {formatEnumLabel(status)}
        </div>
      );
    },
  },
  {
    id: "Download",
    header: () => <Icons.submission className="h-5 w-5" />,
    accessorFn: (submission) => submission.outputFile,
    cell: (ctx) => {
      const outputFile = ctx.getValue() as SubmissionRowType["outputFile"];
      const row = ctx.row.getAllCells();
      const fileDownload = usePrepareFileDownload({
        onDownload: onDownloadFileToComputer,
      });

      const rowStatus = row
        .find((cell) => cell.column.id === "Status")
        ?.getValue();
      const isProcessed = rowStatus === SubmissionStatus.Processed;

      return isProcessed && outputFile ? (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              onClick={(e) => {
                fileDownload(outputFile.id, outputFile.name);
                e.stopPropagation();
              }}
              variant={"ghost"}
              className="group h-fit p-0"
            >
              <Icons.download
                className={cn(
                  "h-5 w-5 text-foreground/80 transition-colors duration-150 ease-in-out group-hover:text-foreground",
                )}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Download Output File</TooltipContent>
        </Tooltip>
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>
            <FileClock className="h-5 w-5 text-muted" />
          </TooltipTrigger>
          <TooltipContent>Output File pending</TooltipContent>
        </Tooltip>
      );
    },
  },
];

export default SubmissionListColumns;
