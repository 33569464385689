import { Table } from "@tanstack/react-table";
import { create } from "zustand";

import { FeedbackCreateManyInput } from "@/gql/graphql";

import { PredictionRowType } from "./PredictionTableColumns";

export type PredictionsTableState = {
  table?: Table<PredictionRowType>;
  onSubmitFeedback?: (feedback: FeedbackCreateManyInput[]) => void;
  isAltPressed: boolean;
};

export type PredictionsTableActions = {
  setTable: (table: PredictionsTableState["table"]) => void;
  setOnSubmitFeedback: (
    callback: PredictionsTableState["onSubmitFeedback"],
  ) => void;
  setIsAltPressed: (isAltPressed: boolean) => void;
  getRows: () => ReturnType<Table<PredictionRowType>["getRowModel"]>["rows"];
  getSelectedRows: () => ReturnType<
    Table<PredictionRowType>["getSelectedRowModel"]
  >["rows"];
  unselectAllRows: () => void;
};

export const usePredictionsTableStore = create<
  PredictionsTableState & PredictionsTableActions
>((set, get) => {
  return {
    table: undefined,
    onSubmitFeedback: undefined,
    isAltPressed: false,
    setTable: (table) => set({ table }),
    setOnSubmitFeedback: (callback) => set({ onSubmitFeedback: callback }),
    setIsAltPressed: (isAltPressed) => set({ isAltPressed }),
    getRows: () => get().table?.getRowModel().rows ?? [],
    getSelectedRows: () => get().table?.getSelectedRowModel().rows ?? [],
    unselectAllRows: () => get().table?.toggleAllRowsSelected(false),
  };
});
