import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";

type Props = {
  hideFilterText: boolean;
  onClick: () => void;
};

export const PredictionTableAddFilterButton = ({
  hideFilterText,
  onClick,
}: Props) => {
  return (
    <Button
      onClick={onClick}
      size="sm"
      variant="outline"
      className="gap-2 whitespace-nowrap"
    >
      {hideFilterText && "Add Filter"}
      <Icons.plus className="h-3.5 w-3.5" />
    </Button>
  );
};
