import { useParams } from "react-router-dom";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { BackButton } from "@/components/BackButton";
import { useUserQuery } from "@/lib/hooks/queries/User";

export const MemberEditPage: React.FC = () => {
  const { userId } = useParams();

  const { data } = useUserQuery({ id: Number(userId) });

  const user = data.user;

  if (!user) {
    throw new Error("User not accessible.");
  }

  return (
    <DashboardScreen title={`${user?.firstName} ${user?.lastName}`}>
      <div className="max-w-4xl bg-background   ">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-full border border-border text-xl font-medium">
              {user.firstName[0]}
            </div>
            <div>
              <h1 className="text-lg font-semibold">
                {user.firstName} {user.lastName}
              </h1>
              <p className="text-sm text-muted-foreground">{user.email}</p>
            </div>
          </div>

          <BackButton />
        </div>
      </div>
    </DashboardScreen>
  );
};
