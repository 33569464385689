import { Card, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { SubmissionListHeaderSkeleton } from "./SubmissionListHeaderSkeleton";

type SkeletonRowProps = {
  columnsCount: number;
};

// Skeleton component for a single row
const SkeletonRow = ({ columnsCount }: SkeletonRowProps) => (
  <TableRow>
    {Array.from({ length: columnsCount }, (_, index) => (
      <TableCell key={index} className="h-12 animate-pulse">
        {/* Empty div with width and height for the skeleton */}
        <div style={{ width: "100%", height: "1em" }}></div>
      </TableCell>
    ))}
  </TableRow>
);

type Props = { tableTitle: string; columnsCount: number; maxHeight?: string };

export function SubmissionsListTableSkeleton({
  columnsCount,
  tableTitle,
  maxHeight,
}: Props) {
  return (
    <Card
      className="flex h-full flex-col"
      style={{
        maxHeight: maxHeight,
      }}
    >
      <SubmissionListHeaderSkeleton tableTitle={tableTitle} />
      <CardContent className="mx-6 mb-6 flex flex-grow flex-col overflow-auto rounded-lg p-0 scrollbar-hide">
        <Table className="scrollbar-hide">
          <TableHeader className="sticky top-0 bg-muted/20 shadow-sm">
            <TableRow className="rounded-lg">
              {Array.from({ length: columnsCount }, (_, index) => (
                <TableHead
                  key={index}
                  className="roundedshadow h-12 animate-pulse bg-muted/20"
                >
                  {/* Empty div with width and height for the skeleton header */}
                  <div style={{ height: "1em" }}></div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody className="overflow-auto scrollbar-hide">
            {Array.from({ length: 10 }).map((_, index) => (
              <SkeletonRow key={index} columnsCount={columnsCount} />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
