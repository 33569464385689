import { Table } from "@tanstack/react-table";

import { TableFilterButton } from "@/components/TableFilterButton";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle } from "@/components/ui/card";

import { useGetVendorTableFilters } from "./useGetVendorTableFilters";
import { Vendor } from "./VendorTable";

type Props = {
  table: Table<Vendor>;
};

export const VendorTableHeader = ({ table }: Props) => {
  const { columnFilterOptions } = useGetVendorTableFilters();
  return (
    <CardHeader className="flex flex-row items-center justify-between">
      <CardTitle className="">Partners</CardTitle>
      <div className="space-x-2">
        <TableFilterButton columnFilterOptions={columnFilterOptions} />
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </CardHeader>
  );
};
