import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import zodToJsonSchema from "zod-to-json-schema";

import { Progress } from "@/components/ui/progress";

import { DocumentExtractFileUpload } from "./(stuff)/document-extract-file-upload";
import { generateZodSchemaFromFields } from "./(stuff)/gen-zod-schema-from-fields";
import { DocumentExtractScreen } from "./document-extract-screen";
import { useDocumentExtractStore } from "./document-extract-store";
import { MultiStepLoader } from "./loading/layout";
import { useDocumentExtract } from "./use-document-extract-mutation";
import { useGenCustomFields } from "./use-gen-custom-fields";

export const UploadPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const {
    setUploadedFile,
    getCurrentUseCase,
    setRecommendedFields,
    setResults,
  } = useDocumentExtractStore();
  const currentUseCase = getCurrentUseCase(type ?? undefined);

  const {
    mutate: genCustomFields,
    isPending,
    isError: isGenCustomFieldsError,
    error: genCustomFieldsError,
  } = useGenCustomFields();

  const {
    mutate: documentExtract,
    isPending: isUploadPending,
    isError: isUploadError,
    error: uploadError,
  } = useDocumentExtract();

  const [fileToUpload, setFileToUpload] = useState<File>();
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<number>();

  useEffect(() => {
    if (progress === 100 && intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [progress, intervalId]);

  useEffect(() => {
    // if there is no type redirect to home
    if (type == null || currentUseCase == null) {
      console.log("No type or current use case");
      navigate("/document-extract");
    }
  }, [type, currentUseCase]);

  const onUpload = (file: File) => {
    setUploadedFile(file);

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 5;
      });
    }, 30);

    setIntervalId(interval);
    // on upload if it is not custom I want to send it directly to document extract with the json schema
    // on upload redirect to your results page
    if (type !== "custom" && currentUseCase?.fields != null) {
      const fields = currentUseCase.fields;
      // get the correct jsonSchema
      const zodSchema = generateZodSchemaFromFields(fields);
      // create JSON Schema from fields
      const jsonSchema = JSON.stringify(zodToJsonSchema(zodSchema));

      // send to document extract
      documentExtract(
        { file, jsonSchema },
        {
          onSuccess: (response) => {
            // set results
            if (response) {
              setResults(response);
              navigate(`../results?type=${type}`);
            }
          },
          onError: (error) => {
            console.error("Error:", error);
          },
        },
      );
    }

    // if it is custom I want to send it to GPT4 to get suggested fields for the json schema
    // before the response from GPT redirect to the create custom schema page
    else {
      // call GPT4
      // need to handle errors
      genCustomFields(file, {
        onSuccess: (response) => {
          if (!response) return;
          const { fields } = response;
          setRecommendedFields(fields);
          navigate("../custom?type=${type}");
        },
        onError: (error) => {
          console.error("Error:", error);
        },
      });
    }
  };

  const showLoader = (isPending || isUploadPending) && progress === 100;

  if (showLoader) {
    return <MultiStepLoader loading={showLoader} />;
  } else if (isUploadError) {
    console.error(uploadError.message);
    return <div>Upload Error: {uploadError.message}</div>;
  } else if (isGenCustomFieldsError) {
    console.error(genCustomFieldsError.message);
    return <div>Failed to generate fields: {genCustomFieldsError.message}</div>;
  }

  return (
    <DocumentExtractScreen className="px-2">
      <div className="mx-auto max-w-prose pt-16">
        <div className="flex flex-col items-center gap-1 sm:gap-2">
          <h2 className="text-xl font-bold text-muted-foreground sm:text-2xl">
            Step 1:
          </h2>
          <h1 className="text-center text-3xl font-bold tracking-normal sm:text-5xl">
            Upload {currentUseCase?.title}
          </h1>
        </div>

        <div className="pt-8 sm:pt-16">
          <DocumentExtractFileUpload
            setFileToUpload={setFileToUpload}
            file={fileToUpload}
            onUpload={onUpload}
            isUploading={false}
          />
        </div>

        {progress > 0 && (
          <Progress value={progress} className=" mx-auto mt-6 w-[60%]" />
        )}
        <p className="pt-4 text-center text-xs text-primary/60">
          Data extraction may take up to 30 seconds depending on the file.
        </p>
      </div>
    </DocumentExtractScreen>
  );
};
