import { create } from "zustand";

type ValidationError = {
  header: string;
  value: any;
  validationStatus: string;
  rowIndex: number;
};

type ValidationStore = {
  validationErrors: ValidationError[];
};

type ValidationActions = {
  setValidationErrors: (
    validationErrors: ValidationStore["validationErrors"],
  ) => void;
};

export const useValidationStore = create<ValidationStore & ValidationActions>(
  (set) => {
    return {
      validationErrors: [],
      setValidationErrors: (validationErrors) => set({ validationErrors }),
    };
  },
);
