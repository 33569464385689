import { NavLink } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { SubmissionTypesQuery } from "@/gql/graphql";

type Props = {
  submissionType: SubmissionTypesQuery["submissionTypes"][number];
};

export const SubmissionTypeItem = ({ submissionType }: Props) => {
  const { id, type } = submissionType;
  const href = `mapping/${type}`;

  return (
    <NavLink to={href}>
      <li
        key={id}
        className="col-span-1 flex w-56 cursor-pointer rounded-md ring-white hover:ring-1"
      >
        <div className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md border text-sm font-medium ">
          <Icons.action className="h-5 w-5" aria-hidden="true" />
        </div>
        <div className="relative flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t">
          <div className="flex-1 truncate px-4 py-2 text-sm">
            <p>{type}</p>
            {/* <p className="text-primary/60">{description} </p> */}
          </div>
          <div className="flex-shrink-0 pr-2">
            <Button
              size="icon"
              variant="ghost"
              className="absolute right-2 top-2 h-5 w-5"
            >
              <span className="sr-only">Open options</span>
              <Icons.information className="h-5 w-5" aria-hidden="true" />
            </Button>
          </div>
        </div>
      </li>
    </NavLink>
  );
};
