import React from "react";

// import { Icons } from "@/components/Icons";

type Props = {
  i: number;
  labelName: string;
  setShowFilterState: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
};

export const ColHeader = ({
  labelName,
  setShowFilterState,
  i,
  ...props
}: Props) => {
  return (
    <div key={i} {...props} className="">
      {labelName}
      {/* <Button
        size={"icon"}
        variant={"ghost"}
        onClick={() => {
          setShowFilterState((old) => {
            return {
              ...old,
              [labelName]: !old[labelName],
            };
          });
        }}
      >
        <Icons.search className="h-4 w-4" />
      </Button> */}
    </div>
  );
};
