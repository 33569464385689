import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Outlet } from "react-router-dom";

import { useCurrentUserQuery } from "@/lib/hooks/queries/User";
import { usePathRedirect } from "@/lib/hooks/routing";
import { easeSnappy } from "@/lib/motion";

import { DashboardScreen } from "../DashboardScreen";
import { SettingsNav } from "./SettingsNav";

export function SettingsLayout() {
  const [content] = useAutoAnimate({
    duration: 200,
    easing: easeSnappy,
  });
  const { data } = useCurrentUserQuery();
  const user = data?.user;

  usePathRedirect({
    matchPath: "dashboard/settings",
    to: () => {
      if (!user) return;

      // ideally we'd check for authorization here, but we don't have that yet
      if (user.organization.id) {
        return `org/${user.organization.id}`;
      }
      return `account/${user.id}`;
    },
  });

  return (
    <DashboardScreen className="grid h-display grid-cols-[auto_1fr] p-0">
      <SettingsNav />

      <main ref={content} className="h-display">
        <Outlet />
      </main>
    </DashboardScreen>
  );
}
