import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { graphql } from "@/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";
import { useDebouncedState } from "@/lib/hooks/useDebouncedState";

import { MemberRow, MemberRowSkeleton } from "./MemberRow";

export const MemberTable = () => {
  const { organizationId } = useParams();
  const [search, setSearch] = useDebouncedState("", 500);
  const [parent] = useAutoAnimate();

  const { data, isLoading } = useGraphqlQuery({
    queryKey: ["users", `organizationId=${organizationId}`, `search=${search}`],
    query: graphql(`
      query OrgUsersTable($where: UserWhereInput) {
        users(where: $where) {
          id
          firstName
          lastName
          email
          role
        }
      }
    `),
    variables: {
      where: {
        organizationId: {
          equals: Number(organizationId),
        },
        OR: [
          {
            firstName: {
              contains: search,
            },
          },
          {
            lastName: {
              contains: search,
            },
          },
          {
            email: {
              contains: search,
            },
          },
        ],
      },
    },
  });

  return (
    <div>
      <h2 className="mb-4 flex items-center text-2xl font-semibold">
        <div className="mr-4 rounded-full border border-border p-3">
          <Icons.users />
        </div>
        Members
      </h2>
      <Input
        className="mb-4 w-64"
        placeholder="Search"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <Card className="max-w-3xl">
        <CardContent className="px-0 py-0" ref={parent}>
          {isLoading ? (
            <>
              <MemberRowSkeleton key="loader-1" />
              <MemberRowSkeleton key="loader-2" />
              <MemberRowSkeleton key="loader-3" />
              <MemberRowSkeleton key="loader-4" />
            </>
          ) : data && data?.users?.length > 0 ? (
            data.users.map((user, i) => (
              <MemberRow key={`row-userId=${user.id}`} user={user} />
            ))
          ) : (
            <Card className="text-md max-w-3xl font-medium text-muted-foreground">
              <CardContent className="flex justify-center pt-6">
                No Members found
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
