import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useCreateOneFileMutation = () => {
  return useGraphqlMutation({
    mutationKey: ["createOneFile"],
    mutation: graphql(`
      mutation CreateOneFileMutation($data: FileCreateInput!) {
        createOneFile(data: $data) {
          id
        }
      }
    `),
  });
};
