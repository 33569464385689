import { useState } from "react";
import { format, parseISO } from "date-fns";

import { Card, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

import { DatabaseExportDialog } from "./DatabaseExportDialog";
import { ExportDB } from "./getOrgExportSettings";

type Props = {
  exportDB: ExportDB;
};

export const ExportDatabaseRow = ({ exportDB }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const readableDate = format(
    parseISO(exportDB.createdAt),
    "MMMM d, yyyy, h:mm a",
  );

  return (
    <Card
      className="max-w-3xl"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <CardContent className="pt-4">
        <section className="flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180">
          <div>
            <h2 className="text text-base font-semibold">{exportDB.name}</h2>

            <p className="mt-1 text-sm text-muted-foreground">
              {exportDB.connectionString}
            </p>
          </div>
          <div className="flex items-center gap-3">
            <p className="text-sm font-medium text-muted-foreground">
              {readableDate}
            </p>
            <DatabaseExportDialog exportDB={exportDB} />
          </div>
        </section>
        {expanded && (
          <>
            <Separator className="mb-4" />
            <h2 className="text text-base font-semibold">Export Mappings</h2>
            {exportDB.exportMappings.map((mapping, i) => {
              return (
                <p key={i} className="mt-1 text-sm text-muted-foreground">
                  {mapping.submissionType.name}
                </p>
              );
            })}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export const ExportDatabaseRowSkeleton: React.FC = () => {
  return (
    <Card className="max-w-3xl animate-pulse">
      <CardContent className="px-5 py-4">
        <div className="flex items-start justify-between">
          <div className="flex gap-6">
            <div className="h-12 w-12 rounded-full bg-muted" />
            <div className="flex flex-col gap-1">
              <div className="h-4 w-32 rounded bg-muted" />
              <div className="h-3 w-20 rounded bg-muted" />
              <div className="h-3 w-24 rounded bg-muted" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-4 w-16 rounded bg-muted" />
            <div className="h-10 w-10 rounded-full bg-muted" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
