import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Column,
  ColumnFilter,
  ColumnFiltersState,
  Table,
} from "@tanstack/react-table";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/cn";

import { PredictionRowType } from "../PredictionTableColumns";

type Props = {
  table: Table<PredictionRowType>;
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  setShowFilterSelection: React.Dispatch<React.SetStateAction<boolean>>;
  initFilter?: ColumnFilter;
};

export function FilterSelectionMenu({
  table,
  setColumnFilters,
  setShowFilterSelection,
  initFilter,
}: Props) {
  const cols = table.getAllLeafColumns().filter((col) => col.getIsVisible());
  const initCol = cols.find((col) => col.id === initFilter?.id) ?? null;
  const initFilterValue =
    typeof initFilter?.value !== "number" ? initFilter?.value : null;

  const [open, setOpen] = useState(true);
  const [filterValueOpen, setFilterValueOpen] = useState(false);
  const [selectedCol, setSelectedCol] = useState<Column<any> | null>(initCol);
  const [selectedFilterValue, setSelectedFilterValue] = useState<string | null>(
    initFilterValue as string,
  );

  const firstValue =
    selectedCol != null
      ? table
          .getPreFilteredRowModel()
          .flatRows[0]?.getValue(selectedCol.id as string)
      : null;

  const sortedUniqueValues = useMemo(() => {
    if (
      firstValue != null &&
      selectedCol != null &&
      typeof firstValue !== "number"
    ) {
      return Array.from(
        selectedCol.getFacetedUniqueValues().keys(),
      ).sort() as string[];
    }
  }, [selectedCol?.getFacetedUniqueValues()]);

  const popoverRef = useRef<HTMLDivElement>(null); // Reference to the popover

  // Function to handle outside click
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setShowFilterSelection(false);
    }
  };

  //   Use effect to add/remove event listener
  useEffect(() => {
    // Add when mounted
    document.addEventListener("mousedown", handleOutsideClick);
    // Remove when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="flex items-center gap-0.5">
      {/* <div className="flex items-center gap-0.5"> */}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            size={"sm"}
            variant="outline"
            className={cn(
              "justify-start whitespace-nowrap rounded-2xl",
              "rounded-r-none",
            )}
          >
            {selectedCol ? `${selectedCol.id}` : "Select Column +"}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="p-0"
          side="bottom"
          align="start"
          ref={popoverRef}
        >
          <Command>
            <CommandInput />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {cols.map((col) => {
                  if (col.id) {
                    return (
                      <CommandItem
                        key={col.id}
                        value={col.id}
                        onSelect={(value) => {
                          setSelectedCol(col);
                          setOpen(false);
                          setFilterValueOpen(true);
                        }}
                      >
                        {col.id}
                      </CommandItem>
                    );
                  }
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {selectedCol && sortedUniqueValues && (
        <Popover open={filterValueOpen} onOpenChange={setFilterValueOpen}>
          <PopoverTrigger asChild>
            <Button
              size={"sm"}
              variant="outline"
              className={cn(
                "justify-start whitespace-nowrap rounded-2xl",
                "rounded-l-none font-mono",
              )}
            >
              {selectedFilterValue ? selectedFilterValue : "Set Filter Value +"}
              <Icons.remove
                className="ml-2 h-3.5 w-3.5"
                onClick={() => {
                  setColumnFilters((filters) =>
                    filters.filter((filter) => filter.id !== selectedCol?.id),
                  );
                  setSelectedCol(null);
                  setSelectedFilterValue(null);
                  setFilterValueOpen(false);
                  setShowFilterSelection(false);
                }}
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0" side="bottom" align="start">
            <Command>
              <CommandInput />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  {sortedUniqueValues.map((val) => {
                    if (val) {
                      return (
                        <CommandItem
                          key={val}
                          value={val}
                          onSelect={(value) => {
                            setSelectedFilterValue(val);
                            setFilterValueOpen(false);
                            setColumnFilters((filters) => [
                              ...filters,
                              {
                                id: selectedCol.id,
                                value: value,
                              },
                            ]);
                            setShowFilterSelection(false);
                          }}
                        >
                          {val}
                        </CommandItem>
                      );
                    }
                  })}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
}
