import { useSuspenseQuery } from "@tanstack/react-query";
import { DateRange } from "react-day-picker";

import { Icons } from "@/components/Icons";
import { AnalyticsCard } from "@/components/KPMCard";
import { graphql } from "@/gql";
import { getDateRangesForQuery } from "@/lib/getDateRangesForQuery";
import { createQueryOptions } from "@/lib/hooks/graphql";
import { useGetIsDemoUser } from "@/lib/hooks/useGetIsDemoUser";

import { TimePeriod } from "../dashHomeStore";

const query = graphql(`
  query GetTotalSubmissionsCardData(
    $currWhere: SubmissionWhereInput
    $prevWhere: SubmissionWhereInput
  ) {
    curr: aggregateSubmission(where: $currWhere) {
      _count {
        id
      }
    }
    prev: aggregateSubmission(where: $prevWhere) {
      _count {
        id
      }
    }
  }
`);

export const getQueryOptions = ({
  dateRange,
  dateRangeType,
}: {
  dateRange: DateRange;
  dateRangeType: TimePeriod;
}) => {
  const { currWhere, prevWhere } = getDateRangesForQuery({
    dateRange,
    dateRangeType,
  });

  return createQueryOptions({
    query,
    variables: {
      currWhere,
      prevWhere,
    },
  });
};

export interface KPMProps {
  dateRange: DateRange;
  dateRangeType: TimePeriod;
}

export const KPMCardTimeSaved = ({ dateRange, dateRangeType }: KPMProps) => {
  const showDemoData = useGetIsDemoUser();
  const options = getQueryOptions({ dateRange, dateRangeType });

  const { data, isLoading } = useSuspenseQuery(options);

  const curr = showDemoData ? 36 : data.curr._count?.id ?? 0;
  const prev = showDemoData ? 28 : data.prev._count?.id ?? 0;

  return (
    <AnalyticsCard
      isLoading={isLoading}
      name={"Time Saved"}
      icon={<Icons.time className="h-5 w-5" />}
      label={`${curr} Hours`}
      value={curr}
      prevValue={prev}
      timePeriod={dateRangeType}
    />
  );
};
