import { useMemo } from "react";
import { toast } from "sonner";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import { GetSubmissionsInfiniteQuery } from "@/gql/graphql";
import { usePrepareFileDownload } from "@/lib/hooks/queries/usePrepareFileDownload";
import { onDownloadFileToComputer } from "@/lib/onDownloadFileToComputer";

import { submissionsTableHeaders } from "./getSubmissionsTableHeadersAndRows";

type Props = { submissions: GetSubmissionsInfiniteQuery["submissions"] };

export const useGetSubmissionsTableColDefs = ({ submissions }: Props) => {
  const fileDownload = usePrepareFileDownload({
    onDownload: onDownloadFileToComputer,
  });
  return useMemo(() => {
    return submissionsTableHeaders.map((header) => {
      const standardColDef = {
        headerName: header,
        field: header,
        colId: header,
        filter: true,
        editable: false,
        resizable: true,
        flex: 0.7,
      };

      if (header === "Id") {
        return {
          ...standardColDef,
          checkboxSelection: true,
        };
      }

      if (header === "Download") {
        return {
          ...standardColDef,
          cellRenderer: ({ value }: { value: string }) => {
            return (
              <Button
                size={"icon"}
                variant={"ghost"}
                onClick={() => {
                  // find the submission with the id
                  const submission = submissions.find(
                    (submission) => submission.file.id === Number(value),
                  );
                  if (submission?.outputFile) {
                    fileDownload(
                      submission.outputFile.id,
                      submission.outputFile.name,
                    );
                  } else {
                    toast.error("No file found");
                  }
                }}
              >
                <Icons.download className="h-6 w-6" />
              </Button>
            );
          },
        };
      }

      return standardColDef;
    });
  }, []);
};
