import { format } from "date-fns";

import { GetSubmissionsInfiniteQuery } from "@/gql/graphql";

type Props = {
  submissions: GetSubmissionsInfiniteQuery["submissions"];
};

export const submissionsTableHeaders = [
  "Id",
  "Type",
  "Uploaded",
  "Status",
  "Submission Period",
  "Download",
];

export const getSubmissionsTableHeadersAndRows = ({ submissions }: Props) => {
  const rows: { [key: string]: string }[] = [];
  //   to get the rows iterate over submissions
  submissions.forEach((submission) => {
    const row: { [key: string]: string } = {};
    const { type, status, createdAt, file } = submission;
    // format the dates
    const uploaded = format(new Date(createdAt), "MM/dd/yyyy");
    // get the week of the year that createdAt is in
    const week = format(new Date(createdAt), "w");
    row["Type"] = type?.type ?? "";
    row["Uploaded"] = uploaded ?? "";
    row["Status"] = status ?? "";
    row["Submission Period"] = `W${week}` ?? "";
    row["Download"] = String(file.id) ?? "";
    row["Id"] = String(submission.id) ?? "";
    rows.push(row);
  });

  return { headers: submissionsTableHeaders, rows };
};
