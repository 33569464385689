import { isSameDay } from "date-fns";
import { create } from "zustand";

type TodayState = {
  today: Date;
};

type TodayActions = {
  updateToday: () => void;
  hasDateChanged: () => boolean;
  checkAndSetToday: () => void;
};

export const useTodayStore = create<TodayState & TodayActions>((set, get) => {
  return {
    today: new Date(),
    updateToday: () => set({ today: new Date() }),
    hasDateChanged: () => {
      const old = get().today;
      const now = new Date();

      return !isSameDay(old, now);
    },
    checkAndSetToday: () => {
      if (get().hasDateChanged()) {
        get().updateToday();
      }
    },
  };
});

const MINUTE = 60_000;

setInterval(() => {
  useTodayStore.getState().checkAndSetToday();
}, MINUTE);
