import { graphql } from "@/gql";
import { GetOrgExportSettingsQuery } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

type Org = NonNullable<GetOrgExportSettingsQuery["organization"]>;
export type ExportDB = NonNullable<Org["exportDatabases"]>[0];

const query = graphql(`
  query GetOrgExportSettings($where: OrganizationWhereUniqueInput!) {
    organization(where: $where) {
      exportDatabases {
        id
        name
        dialect
        createdAt
        connectionString
        exportMappings {
          id
          submissionType {
            type
            name
            id
          }
        }
      }
    }
  }
`);

export const getOrgExportSettingsQueryOptions = (organizationId: string) => {
  return createQueryOptions({
    query,
    variables: {
      where: {
        id: Number(organizationId),
      },
    },
  });
};
