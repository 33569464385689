import { format } from "date-fns";

import { Icons } from "@/components/Icons.tsx";
import {
  ColumnFilterOption,
  GlobalFilterOption,
  SortOption,
} from "@/components/TableFilterButton.tsx";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Input } from "@/components/ui/input.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { SubmissionStatus } from "@/gql/graphql.ts";
import { cn } from "@/lib/cn.ts";

import {
  SubmissionType,
  useSubmissionsListStoreInContext,
} from "../SubmissionsListContext.tsx";

export const useGetSubmissionListHeaderFilters = () => {
  const {
    vendorName,
    setVendorName,
    submissionType,
    setSubmissionType,
    status,
    setStatus,
    dateRange,
    setDateRange,
  } = useSubmissionsListStoreInContext();

  const globalFilterOptions: GlobalFilterOption[] = [
    // {
    //   name: "Low Confidence",
    //   count: submissions.filter(
    //     // @ts-ignore TODO: fix this when accuracy comes from API
    //     (submission) => submission.accuracy < 0.9,
    //   ).length,
    //   colId: "Accuracy %",
    //   onSelect: () => {
    //     setColumnFilters([
    //       ...columnFilters,
    //       {
    //         id: "Accuracy %",
    //         value: [0, 90],
    //       },
    //     ]);
    //   },
    //   onUnselect: () => {
    //     setColumnFilters((prevFilters) => {
    //       return prevFilters.filter((filter) => filter.id !== "Accuracy %");
    //     });
    //   },
    // },
  ];

  const columnFilterOptions: ColumnFilterOption[] = [
    {
      name: "Partner Name",
      colId: "Partner",
      width: "col-span-4",
      inputNode: (
        <Input
          value={vendorName}
          placeholder="Search Partners..."
          onChange={(event) => setVendorName(event.target.value)}
          className="max-w-sm"
        />
      ),
    },
    {
      name: "Type",
      colId: "Type",
      width: "col-span-4",

      inputNode: (
        <Select
          value={submissionType}
          onValueChange={(value) => setSubmissionType(value as SubmissionType)}
        >
          <SelectTrigger className="">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value={SubmissionType.INV}>
                {SubmissionType.INV}
              </SelectItem>
              <SelectItem value={SubmissionType.POS}>
                {SubmissionType.POS}
              </SelectItem>
              {/* @ts-ignore - this is technically correct to setState back to neither option */}
              <SelectItem value={undefined}>{"All"}</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      ),
    },
    {
      name: "Status",
      colId: "Status",
      width: "col-span-4",

      inputNode: (
        <Select
          value={status}
          onValueChange={(value) => setStatus(value as SubmissionStatus)}
        >
          <SelectTrigger className="">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {Object.values(SubmissionStatus).map((status) => (
                <SelectItem value={status} key={status}>
                  {status}
                </SelectItem>
              ))}
              {/* @ts-ignore - this is technically correct to setState back to neither option */}
              <SelectItem value={undefined}>{"All"}</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      ),
    },
    {
      name: "Date Range",
      colId: "Uploaded",
      width: "col-span-4",

      inputNode: (
        <div className={cn("grid gap-2")}>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="date"
                variant={"outline"}
                className={cn(
                  "w-[300px] justify-start text-left font-normal",
                  !dateRange && "text-muted-foreground",
                )}
              >
                <Icons.calendar className="mr-2 h-4 w-4" />
                {dateRange?.from ? (
                  dateRange.to ? (
                    <>
                      {format(dateRange.from, "LLL dd, y")} -{" "}
                      {format(dateRange.to, "LLL dd, y")}
                    </>
                  ) : (
                    format(dateRange.from, "LLL dd, y")
                  )
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={dateRange?.from}
                selected={dateRange}
                onSelect={(date) => setDateRange(date)}
                numberOfMonths={2}
              />
            </PopoverContent>
          </Popover>
        </div>
      ),
    },
  ];

  const sortOptions: SortOption[] = [
    // {
    //   name: "Most Recent",
    //   onSelect: () => {
    //     setColumnSorts([
    //       ...columnSorts,
    //       {
    //         id: "Uploaded",
    //         desc: true,
    //       },
    //     ]);
    //   },
    //   onUnselect: () => {
    //     setColumnSorts((prevSorts) => {
    //       return prevSorts.filter((sort) => sort.id !== "Uploaded");
    //     });
    //   },
    // },
    // {
    //   name: "Accuracy",
    //   onSelect: () => {
    //     setColumnSorts([
    //       ...columnSorts,
    //       {
    //         id: "Accuracy %",
    //         desc: false,
    //       },
    //     ]);
    //   },
    //   onUnselect: () => {
    //     setColumnSorts((prevSorts) => {
    //       return prevSorts.filter((sort) => sort.id !== "Accuracy %");
    //     });
    //   },
    // },
    // {
    //   name: "Default",
    //   onSelect: () => {
    //     setColumnSorts([]);
    //   },
    //   onUnselect: () => {
    //     setColumnSorts([]);
    //   },
    // },
  ];
  return { globalFilterOptions, columnFilterOptions, sortOptions };
};
