import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { getSubmissionTypeQueryOptions } from "../../../submission/:submissionId/confirm-submission-type/getSubmissionQueryOptions";

export enum TypedOutputColumnTypes {
  STRING = "string",
  SKU = "sku",
  TEXTAREA = "textarea",
  DATE = "date",
  NUMBER = "number",
}

export type TypedOutputColumn = {
  name: string;
  type: TypedOutputColumnTypes;
};

type Props = {
  submissionId: number;
  vendorId: number;
};

export const useGetTypedOutputColumns = ({ submissionId, vendorId }: Props) => {
  const {
    data: currSubmissionData,
    isLoading,
    isFetched,
  } = useQuery(getSubmissionTypeQueryOptions(submissionId, vendorId));
  const outputColumns =
    currSubmissionData?.submission?.type?.outputColumns ?? [];

  const typedOutputColumns = useMemo(() => {
    return outputColumns.map((col) => {
      let type = TypedOutputColumnTypes.STRING;
      if (col === "Product Manufacturer SKU") {
        type = TypedOutputColumnTypes.SKU;
      } else if (col === "Product Description") {
        type = TypedOutputColumnTypes.TEXTAREA;
      } else if (col === "Date of Inventory Update") {
        type = TypedOutputColumnTypes.DATE;
      } else if (col === "Quantity on Hand" || col === "Quantity on Order") {
        type = TypedOutputColumnTypes.NUMBER;
      }
      return {
        name: col,
        type,
      };
    });
  }, [outputColumns]);
  return { typedOutputColumns, isLoading, isFetched };
};
