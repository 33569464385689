import { BackButton } from "@/components/BackButton";
import LabelBadge from "@/components/LabelBadge";
import { CardHeader } from "@/components/ui/card";

export const SubmissionHeaderSkeleton = () => {
  return (
    <CardHeader className="flex flex-row justify-between space-y-0">
      <div className="flex flex-col justify-center gap-4">
        <div className="roundedshadow flex animate-pulse gap-4">
          <div className="h-8 w-96 rounded bg-muted"></div>
          <div className=" flex gap-4">
            <LabelBadge
              align="start"
              side="bottom"
              text={"Status"}
              className="bg-muted text-sm"
            ></LabelBadge>
          </div>
        </div>
      </div>

      <BackButton />
    </CardHeader>
  );
};
