import React, { useCallback, useState } from "react";

import { ProductTypeahead } from "@/app/dashboard/home/(submissions-list)/create-submission/ProductTypeahead";
import { Icons } from "@/components/Icons";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/cn";
import { CONFIDENCE_INTERVALS } from "@/lib/constants/confidenceIntervals";
import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

// import { LabelEnumsCombobox } from "./(context-menu)/LabelEnumsCombobox";
import { PredictionRowType } from "./PredictionTableColumns";
import { usePredictionsTableStore } from "./predictionTableStore";

type label = PredictionRowType["labels"][number];

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  labelName: label["name"];
  labelValue: label["value"];
  confidence: label["confidence"];
  labelId: label["id"];
  hasFeedback: boolean;
  transformationCategory: string;
}

const _PredictionTableLabel = ({
  labelName,
  labelValue,
  confidence,
  labelId,
  hasFeedback,
  transformationCategory,
}: Props) => {
  const [mode, setMode] = useState<keyof typeof MODES>("VIEW");

  const { data: userData } = useCurrentUserQuery();
  const user = userData?.user;
  // const { feedback } = label;
  const { onSubmitFeedback } = usePredictionsTableStore();

  if (!user || !onSubmitFeedback) {
    return null;
  }

  const value = labelValue;

  // if there's feedback, then it can't have low confidence
  const isLowConfidence = hasFeedback
    ? false
    : (confidence ?? 0) < CONFIDENCE_INTERVALS.Low[1];

  const confirmLabel = useCallback(() => {
    onSubmitFeedback([
      {
        labelId,
        content: labelValue,
        upvote: true,
        userId: user.id,
      },
    ]);
  }, [labelId, labelValue, onSubmitFeedback, user.id]);

  const handleViewClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        if (e.altKey) {
          confirmLabel();
        } else {
          setMode("EDIT");
        }
      },
      [confirmLabel, setMode],
    );

  const handleSubmitFeedback = useCallback(
    (content: string) => {
      onSubmitFeedback([
        {
          labelId,
          content: content.trim() === "" ? "NULL" : content,
          upvote: false,
          userId: user.id,
        },
      ]);
    },
    [labelId, onSubmitFeedback, user.id],
  );

  const MODES = {
    VIEW: (
      // container
      <div
        id={`label-${labelId}-${labelValue}`}
        className={"group relative flex h-full w-full flex-1 @container"}
      >
        {/* cell */}
        <button
          onClick={handleViewClick}
          className={cn(
            "duration-400 flex h-full w-full max-w-prose cursor-text items-start justify-start rounded-none px-2 py-2.5 text-start text-sm tabular-nums transition-colors",
            isLowConfidence
              ? "bg-status-uncertain text-status-uncertain-foreground"
              : "bg-status-success text-foreground/70",
          )}
        >
          {value}
        </button>
        {isLowConfidence && (
          <button
            className="absolute bottom-0 right-0 cursor-pointer p-[3px]"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              confirmLabel();
            }}
          >
            <div
              className={cn(
                buttonVariants({ size: "xs" }),
                "hidden gap-1 rounded-sm px-2 group-focus-within:flex group-hover:flex group-focus:flex",
              )}
            >
              <Icons.confirm className="h-4 w-4" />
            </div>
          </button>
        )}
      </div>
    ),
    EDIT: (
      <ProductTypeahead
        transformationCategory={transformationCategory}
        labelName={labelName.split(" ")[1].toLowerCase()}
        isLowConfidence={isLowConfidence}
        currValue={value}
        setMode={setMode}
        handleSubmitFeedback={handleSubmitFeedback}
      />
    ),
    // EDIT: (
    // <Input
    //   placeholder={value}
    //   autoFocus
    //   className="h-full w-full rounded-none px-2 py-2.5 text-sm"
    //   onBlur={(e) => {
    //     const content = e.currentTarget.value;
    //     if (content) {
    //       handleSubmitFeedback(content);
    //     }

    //     setMode("VIEW");
    //   }}
    //   onKeyDown={(e) => {
    //     if (e.key === "Escape") {
    //       setMode("VIEW");
    //     }
    //   }}
    // />
    // ),
  };

  return MODES[mode];
};

export const PredictionTableLabel = React.memo(_PredictionTableLabel);
