import { useParams } from "react-router-dom";

import { useCurrentOrgSubmissionTypesQuery } from "@/lib/hooks/queries/SubmissionTypes";
import { useVendorSubmissionTypesQuery } from "@/lib/hooks/queries/Vendor";

export const useGetVendorMapping = () => {
  const { vendorId, submissionType: submissionTypeParam } = useParams() as {
    vendorId: string;
    submissionType: string;
  };

  const { data } = useVendorSubmissionTypesQuery({
    id: Number(vendorId),
  });

  const { data: submissionTypeData } = useCurrentOrgSubmissionTypesQuery();
  const submissionTypes = submissionTypeData.submissionTypes;
  const currSubmissionType = submissionTypes.find(
    (type) => type.type === submissionTypeParam,
  );

  const vendor = data?.vendor;
  const normalizeMappings = vendor?.normalizeMappings || [];
  const {
    submissionType,
    mapping,
    examples,
    id: normalizeMappingId,
  } = normalizeMappings.find(({ submissionType }) => {
    return submissionType.id === currSubmissionType?.id;
  }) ?? {};

  return {
    submissionType,
    mapping,
    examples,
    normalizeMappingId,
  };
};
