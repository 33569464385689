import React from "react";
import {
  eachQuarterOfInterval,
  endOfQuarter,
  startOfQuarter,
  subDays,
  subYears,
} from "date-fns";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/cn";
import { useTodayStore } from "@/lib/stores/date";

import { TimePeriod, useDashHomeStore } from "../dashHomeStore";
import DashboardChart, { DateObj } from "./DashboardChart";
import { useGetChartSubmissionsData } from "./useGetChartSubmissionsData";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export function DashboardSubmissionsChart({ className, ...props }: Props) {
  const { today } = useTodayStore();
  const { dateRangeType, dateRange } = useDashHomeStore();

  const { data } = useGetChartSubmissionsData({ dateRange });
  const submissions = data?.submissions ?? [];

  const submissionsByTimePeriod: DateObj[] = [];

  if (dateRangeType === TimePeriod.WEEK) {
    for (let i = 6; i >= 0; i--) {
      const dateString = subDays(today, i).toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      });
      submissionsByTimePeriod.push({
        name: dateString,
        submissions: 0,
      });
    }
  } else if (dateRangeType === TimePeriod.MONTH) {
    // calculate this by the number of weeks in the month
    const numWeeks = 4;
    for (let i = 1; i <= numWeeks; i++) {
      submissionsByTimePeriod.push({
        name: `W${i}`,
        submissions: 0,
      });
    }
  } else if (dateRangeType === TimePeriod.YEAR) {
    // Calculate the start of the current quarter
    const startCurrentQuarter = startOfQuarter(today);

    // Define the interval from one year ago to the current date
    const interval = {
      start: subYears(startCurrentQuarter, 1),
      end: today,
    };

    // Get the start of each quarter in the interval
    const quarters = eachQuarterOfInterval(interval);

    // Optionally, get the start and end dates of each quarter
    const detailedQuarters = quarters.map((quarter) => {
      const shortYear = quarter.getFullYear().toString().slice(-2);
      return {
        name: `Q${Math.floor(quarter.getMonth() / 3) + 1} ${shortYear}`,
        start: startOfQuarter(quarter),
        end: endOfQuarter(quarter),
      };
    });

    detailedQuarters.forEach((quarter) => {
      submissionsByTimePeriod.push({
        name: quarter.name,
        submissions: 0,
      });
    });
  }

  submissions.forEach((submission) => {
    const date = new Date(submission.createdAt);
    if (dateRangeType === TimePeriod.WEEK) {
      const dateString = date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      });
      const index = submissionsByTimePeriod.findIndex(
        (d) => d.name === dateString,
      );
      if (index !== -1) {
        submissionsByTimePeriod[index].submissions += 1;
      }
      return;
    } else if (dateRangeType === TimePeriod.MONTH) {
      const week = Math.floor(date.getDate() / 7);
      const index = submissionsByTimePeriod.findIndex(
        (d) => d.name === `W${week}`,
      );
      if (index !== -1) {
        submissionsByTimePeriod[index].submissions += 1;
      }
      return;
    } else if (dateRangeType === TimePeriod.YEAR) {
      const quarter = Math.floor(date.getMonth() / 3);
      const shortYear = date.getFullYear().toString().slice(-2);
      const index = submissionsByTimePeriod.findIndex(
        (d) => d.name === `Q${quarter + 1} ${shortYear}`,
      );
      if (index !== -1) {
        submissionsByTimePeriod[index].submissions += 1;
      }
      return;
    }
  });

  const fromString = dateRange?.from?.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
  });
  const toString = dateRange?.to?.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
  });

  return (
    <Card
      className={cn("grid min-h-[500px] grid-rows-[auto_1fr]", className)}
      {...props}
    >
      <CardHeader>
        <div className="flex flex-row gap-4">
          <CardTitle className=" font-semibold">Last {dateRangeType}</CardTitle>
          <CardTitle className="mb-1 text-xl font-medium text-muted-foreground">
            {fromString} - {toString}
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="w-full">
        <DashboardChart submissionsByTimePeriod={submissionsByTimePeriod} />
      </CardContent>
    </Card>
  );
}
