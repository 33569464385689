import { useCallback, useEffect } from "react";
import ReactFlow, {
  Background,
  Controls,
  Edge,
  EdgeMouseHandler,
  Node,
} from "reactflow";

import "reactflow/dist/style.css";

import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { useGetVendorMapping } from "@/app/dashboard/vendors/summary/submission/[submissionTypeId]/useGetVendorMapping";
import { useUpdateNormalizeMapping } from "@/app/dashboard/vendors/summary/submission/[submissionTypeId]/useUpdateNormalizeMapping";

import { CardContent } from "../ui/card";
import {
  InputNode,
  OutputNode,
  useHeaderMappingStore,
} from "./HeaderMappingStore";
import {
  INPUT_HEADER_NODE_TYPE,
  InputHeaderNode,
} from "./nodes/InputHeaderNode";
import {
  OUTPUT_HEADER_NODE_TYPE,
  OutputHeaderNode,
} from "./nodes/OutputHeaderNode";

const nodeTypes = {
  [INPUT_HEADER_NODE_TYPE]: InputHeaderNode,
  [OUTPUT_HEADER_NODE_TYPE]: OutputHeaderNode,
};

export function HeaderMapping() {
  const {
    edges,
    inputNodes,
    outputNodes,
    setEdges,
    setInputNodes,
    setOutputNodes,
  } = useHeaderMappingStore();
  const { submissionType, mapping, examples, normalizeMappingId } =
    useGetVendorMapping();

  const { vendorId, submissionType: submissionTypeParam } = useParams();
  if (!vendorId || !submissionTypeParam) {
    throw new Error("Missing vendorId or submissionType");
  }

  useEffect(() => {
    const inputHeaders = Object.keys(mapping || {});

    const initInputNodes: InputNode[] = inputHeaders.map((header, index) => ({
      id: `input-${index}`,
      position: { x: index * 150, y: 0 },
      data: { label: header },
      type: "inputHeader",
      example: examples[header],
    }));

    const outputHeaders = submissionType?.outputColumns || [];

    const offset =
      (inputHeaders?.length * 150) / 2 - (outputHeaders?.length * 150) / 2;

    const initOutputNodes: OutputNode[] = outputHeaders.map(
      (header, index) => ({
        id: `output-${index}`,
        position: { x: index * 150 + offset, y: 250 },
        data: { label: header },
        type: "outputHeader",
      }),
    );

    const initEdges: Edge[] = Object.entries(mapping || []).map(
      ([key, value]) => ({
        id: `${key}`,
        source: `input-${inputHeaders.indexOf(key)}`,
        target: `output-${outputHeaders.findIndex(
          (header) => header === value,
        )}`,
        animated: true,
      }),
    );
    setEdges(initEdges);
    setInputNodes(initInputNodes);
    setOutputNodes(initOutputNodes);
  }, [setEdges, setInputNodes, setOutputNodes, mapping]);

  const { mutate } = useUpdateNormalizeMapping();
  const queryClient = useQueryClient();

  const handleEdgeDoubleClick = useCallback<EdgeMouseHandler>(
    (e, edgeToDelete) => {
      const newEdges = edges.filter((edge) => {
        return edge.id !== edgeToDelete.id;
      });
      setEdges(newEdges);

      const newMapping = {
        ...mapping,
        [edgeToDelete.id]: null,
      };

      mutate(
        {
          where: {
            id: normalizeMappingId,
          },
          data: {
            mapping: newMapping,
          },
        },
        {
          onSuccess: () => {
            toast("Success", {
              description: `Mapping removed successfully.`,
              duration: 2000,
            });
            queryClient.invalidateQueries({
              queryKey: ["VendorSubmissionTypes", `vendorId=${vendorId}`],
            });
          },
        },
      );
    },
    [edges, setEdges],
  );

  return (
    <CardContent
      style={{
        width: `calc(100vw - 8rem)`,
        height: `calc(100vh - 18rem)`,
      }}
      className="relative overflow-hidden rounded-md border-[1.5px] border-card bg-[#19191A] bg-opacity-40 p-0"
    >
      <ReactFlow
        nodes={[...inputNodes, ...outputNodes] as Node[]}
        nodeTypes={nodeTypes}
        edges={edges}
        onEdgeDoubleClick={handleEdgeDoubleClick}
        fitView={inputNodes.length > 0 && outputNodes.length > 0}
        fitViewOptions={{
          padding: 0.03,
          nodes: [...inputNodes, ...outputNodes].map((node) => ({
            id: node.id as string,
          })),
          duration: 600,
        }}
        proOptions={{
          hideAttribution: true,
        }}
        panOnScroll
        className=" ease-in-out"
      >
        <Background />
        <Controls className="rounded-xs"></Controls>
      </ReactFlow>
    </CardContent>
  );
}
