import { addDays } from "date-fns";
import { DateRange } from "react-day-picker";
import { create } from "zustand";

export enum TimePeriod {
  WEEK = "Week",
  MONTH = "Month",
  YEAR = "Year",
  CUSTOM = "Custom Period",
}

type DashHomeState = {
  dateRange: DateRange;
  dateRangeType: TimePeriod;
};

type DashHomeActions = {
  setDateRange: (dateRange: DateRange) => void;
  setDateRangeType: (dateRangeType: TimePeriod) => void;
};

// const initEndDate = startOfToday();
const initEndDate = new Date();
const initStartDate = addDays(initEndDate, -7);

export const useDashHomeStore = create<DashHomeState & DashHomeActions>(
  (set) => {
    return {
      dateRange: { from: initStartDate, to: initEndDate },
      dateRangeType: TimePeriod.WEEK,
      setDateRange: (dateRange) => set({ dateRange }),
      setDateRangeType: (dateRangeType) => set({ dateRangeType }),
    };
  },
);
