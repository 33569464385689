import { DashboardScreen } from "@/app/dashboard/DashboardScreen";

import { TransformationTypeList } from "./TransformationTypeList";
import { ValidationTypeList } from "./ValidationTypeList";

export function OrgDataActionPage() {
  return (
    <DashboardScreen className="h-display" scrollable>
      <Component />
    </DashboardScreen>
  );
}

export default function Component() {
  return (
    <div className="flex max-w-3xl flex-col gap-8 pb-6 transition-all">
      <TransformationTypeList />
      <ValidationTypeList />
    </div>
  );
}
