import { useSortable } from "@dnd-kit/sortable";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";

import { Input } from "./InputColumnItem";

type Props = {
  input: Input;
  onRemove: () => void;
};

export const AssociatedInputItem = ({ input, onRemove }: Props) => {
  const { setActivatorNodeRef, listeners } = useSortable({ id: input.id });

  return (
    <div className="flex h-full w-full cursor-auto items-center justify-between gap-2 py-4">
      <div className="flex items-center gap-4">
        <Button variant={"ghost"} size={"icon"}>
          <Icons.arrowLeft
            className="h-4 w-4 shrink-0 cursor-pointer transition-transform duration-200"
            onClick={onRemove}
          />
        </Button>
        <div>
          <p>
            Header:
            <span className="ml-2 rounded bg-muted px-1 py-1 font-mono tabular-nums text-muted-foreground">
              {input.id}
            </span>
          </p>
          <p>
            Example:
            <span className="ml-2 rounded bg-muted px-1 py-1 font-mono tabular-nums text-muted-foreground">
              {input.example}
            </span>
          </p>
        </div>
      </div>
      <div ref={setActivatorNodeRef} {...listeners}>
        <Button variant={"ghost"} size={"icon"}>
          <Icons.gripVertical className="h-6 w-6 shrink-0 transition-transform duration-200 " />
        </Button>
      </div>
    </div>
  );
};
