import { Suspense, useState } from "react";

import { Icons } from "@/components/Icons";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/cn";

import {
  FilterPopoverFilter,
  SkeletonFilterPopoverFilter,
} from "../app/dashboard/home/(submissions-list)/filter-popover/FilterPopoverFilter";
import { FilterPopoverToggleButton } from "../app/dashboard/home/(submissions-list)/filter-popover/FilterPopoverToggleButton";
import { Label } from "./ui/label";
import { Separator } from "./ui/separator";

export type GlobalFilterOption = {
  name: string;
  colId: string;
  count?: number;
  onSelect: (value?: string) => void;
  onUnselect: (value?: string) => void;
};

export type ColumnFilterOption = {
  name: string;
  colId: string;
  inputNode: React.ReactNode;
  width: string;
};

export type SortOption = {
  name: string;
  onSelect: () => void;
  onUnselect: () => void;
};

type Props = {
  customIcon?: React.ReactNode;
  text?: string;
  globalFilterOptions?: GlobalFilterOption[];
  columnFilterOptions?: ColumnFilterOption[];
  sortOptions?: SortOption[];
  defaultActiveFilter?: GlobalFilterOption;
};

export const TableFilterButton = ({
  customIcon,
  text,
  sortOptions,
  globalFilterOptions,
  columnFilterOptions,
  defaultActiveFilter,
}: Props) => {
  const [activeFilter, setActiveFilter] = useState<GlobalFilterOption | void>(
    defaultActiveFilter,
  );
  const [activeSort, setActiveSort] = useState<SortOption>();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn("", "gap-2 whitespace-nowrap text-primary")}
          variant={text ? "secondary" : "outline"}
          size="sm"
        >
          {customIcon ? customIcon : <Icons.filter className="h-3.5 w-3.5" />}
          {text}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit min-w-[400px]" align="end">
        {globalFilterOptions && globalFilterOptions.length > 0 && (
          <div>
            <h3 className="mb-2 font-semibold text-foreground/90">
              Global Filters
            </h3>
            <div className="grid grid-cols-2 gap-2">
              {globalFilterOptions.map((option) => {
                const onClick = () => {
                  if (activeFilter?.name === option.name) {
                    setActiveFilter(undefined);
                    option.onUnselect();
                  } else {
                    setActiveFilter(option);
                    option.onSelect();
                  }
                };
                return (
                  <FilterPopoverToggleButton
                    onClick={onClick}
                    active={activeFilter?.name === option.name}
                    className="w-fit"
                    key={option.name}
                  >
                    <Suspense fallback={<SkeletonFilterPopoverFilter />}>
                      <div className="w-[180px]">
                        <FilterPopoverFilter
                          FilterIcon={Icons.notice}
                          label={option.name}
                          value={option?.count}
                        />
                      </div>
                    </Suspense>
                  </FilterPopoverToggleButton>
                );
              })}
            </div>
          </div>
        )}

        {columnFilterOptions && columnFilterOptions?.length > 0 && (
          <div>
            <h3 className="mb-2 font-semibold text-foreground/90">
              Column Filters
            </h3>
            <Separator className="mb-2" />
            <div className="grid grid-cols-4 gap-4">
              {columnFilterOptions.map((option) => {
                const { name, inputNode, width } = option;
                return (
                  <div
                    className={cn("flex flex-col gap-2", width)}
                    key={option.name}
                  >
                    <Label>{name}:</Label>
                    {inputNode}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {sortOptions && sortOptions.length > 0 && (
          <div>
            <h3 className="mb-2 font-semibold text-foreground/90">Sorting</h3>
            <Select
              onValueChange={(value) => {
                const option = sortOptions.find((option) => {
                  return option.name === value;
                });
                if (!option) return;
                if (activeSort?.name === option.name) {
                  setActiveSort(undefined);
                  option.onUnselect();
                } else {
                  setActiveSort(option);
                  option.onSelect();
                }
              }}
            >
              <SelectTrigger className=" bg-card">
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Sort Options</SelectLabel>
                  {sortOptions.map((option) => (
                    <SelectItem
                      key={option.name}
                      value={option.name}
                      className="text-sm"
                    >
                      {option.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
