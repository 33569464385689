import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { graphql } from "@/gql";
import { SortOrder, TransformationWhereInput } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";
import { easeSnappy } from "@/lib/motion";

import { TransformationsNavLink } from "./TransformationsNavLink";

export const query = graphql(`
  query TransformationsNav(
    $where: TransformationWhereInput
    $orderBy: [TransformationOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    transformations(where: $where, orderBy: $orderBy) {
      id
      name
      processed
      updatedAt
      outputFile {
        name
        id
      }
    }
  }
`);

export const getQueryOptions = (
  submissionId: NonNullable<TransformationWhereInput["submissionId"]>["equals"],
) => {
  return createQueryOptions({
    query,
    variables: {
      where: {
        submissionId: { equals: submissionId },
        type: {
          isNot: {
            template: {
              is: {
                name: {
                  equals: "Normalize Columns",
                },
              },
            },
          },
        },
      },
      orderBy: [{ transformationTypeId: SortOrder.Asc }],
    },
  });
};

export function TransformationsNav() {
  const { submissionId } = useParams();
  const options = getQueryOptions(Number(submissionId));
  const { data } = useSuspenseQuery(options);

  const transformations = data.transformations;

  const [parent] = useAutoAnimate<HTMLUListElement>({
    duration: 150,
    easing: easeSnappy,
  });

  return (
    <div className="flex w-fit items-start gap-6 rounded-md bg-muted/80 px-3 py-1.5">
      <h3 className="mt-1 flex items-center text-base font-medium">
        <div className="mr-2 rounded-full border bg-background p-2">
          <Icons.transformation className="h-4 w-4" />
        </div>
        Transformations
      </h3>

      {transformations.length > 0 ? (
        <ul ref={parent} role="list" className="flex gap-2">
          {transformations.map((transformation) => {
            return (
              <TransformationsNavLink
                key={transformation.id}
                transformationId={transformation.id}
                transformationName={transformation.name}
                transformationProcessed={transformation.processed}
                outputFile={transformation.outputFile}
                replace
              />
            );
          })}
        </ul>
      ) : (
        <div className="pl-12 text-sm font-medium text-muted-foreground">
          No transformations
        </div>
      )}
    </div>
  );
}
