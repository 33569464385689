import { graphql } from "@/gql/gql";
import { SortOrder } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  submissionId?: number | undefined;
};

export const useGetTransformationsStackListData = ({ submissionId }: Props) => {
  return useGraphqlQuery({
    queryKey: [
      "GetTransformationsStackListData",
      `submissionId=${submissionId}`,
    ],
    query: graphql(`
      query GetTransformationsStackListData(
        $where: SubmissionWhereUniqueInput!
        $transformationsWhere2: TransformationWhereInput
        $orderBy: [TransformationOrderByWithRelationAndSearchRelevanceInput!]
      ) {
        submission(where: $where) {
          vendor {
            name
          }
          transformations(where: $transformationsWhere2, orderBy: $orderBy) {
            name
            id
            processed
            updatedAt
          }
        }
      }
    `),
    variables: {
      where: { id: submissionId },
      transformationsWhere2: {
        type: {
          isNot: {
            template: {
              is: {
                name: {
                  equals: "Normalize Columns",
                },
              },
            },
          },
        },
      },
      orderBy: [{ id: SortOrder.Asc }],
    },
  });
};
