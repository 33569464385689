import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/cn";

import { Icons } from "./Icons";

interface NewProps extends React.HTMLAttributes<HTMLDivElement> {}

export const StatCardSkeleton = ({ className, ...props }: NewProps) => {
  return (
    <Card {...props} className={cn("w-full", className)}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="line-clamp-1 w-fit animate-pulse rounded-md bg-muted text-sm font-medium leading-6 text-muted">
          Fake Title
        </CardTitle>
        <div className="animate-pulse rounded-full bg-muted text-muted">
          <Icons.action className="h-5 w-5" />
        </div>
      </CardHeader>
      <CardContent>
        <div className="w-fit animate-pulse rounded-md bg-muted pb-2 text-2xl font-semibold tracking-tight text-muted">
          Fake lol
        </div>

        <span className="mt-1 line-clamp-2 w-fit rounded-md bg-muted text-xs text-muted">
          More fake text lmao
        </span>
      </CardContent>
    </Card>
  );
};
