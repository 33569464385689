import Excel from "exceljs";

export function getHeaderValue(header: Excel.CellValue) {
  if (header === null || header === undefined) {
    return "UndefinedHeader"; // Default for null or undefined
  } else if (
    typeof header === "string" ||
    typeof header === "number" ||
    header === true
  ) {
    return String(header); // Direct conversion for string, number, or boolean true
  } else if (typeof header === "boolean") {
    return header ? "True" : "False"; // Convert boolean to "True" or "False"
  } else if (header instanceof Date) {
    return header.toISOString(); // Convert Date to ISO string
  } else if ("text" in header) {
    // This covers CellRichTextValue, CellHyperlinkValue if they have 'text'
    return header.text;
  } else if ("formula" in header) {
    // This covers CellFormulaValue and CellSharedFormulaValue if they have 'formula'
    // Consider how to best handle showing formulas. You might show just the result or indicate it's a formula.
    return `Formula_${header.result}`;
  } else if ("error" in header) {
    // This covers CellErrorValue if it has 'error'
    return `Error_${header.error}`;
  } else {
    return "UnknownType"; // Fallback for any other type
  }
}
