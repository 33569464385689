import { Table } from "@tanstack/react-table";

import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/ui/button";

type Props = {
  table: Table<any>;
  hasNext?: boolean;
  isFetchingNextPage?: boolean;

  onClickNext?: () => void;
  onClickPrev?: () => void;
};

export const TablePaginationButtons = ({
  table,
  hasNext,
  isFetchingNextPage,
  onClickNext,
  onClickPrev,
}: Props) => {
  const pageCount = table.getPageCount();
  const pageIndex = table.getState().pagination.pageIndex;
  const hasPagination = onClickNext != null && onClickPrev != null;

  return (
    <>
      <Button
        onClick={onClickPrev ?? (() => table.previousPage())}
        disabled={!hasPagination || pageIndex === 0}
        variant="outline"
        size="sm"
      >
        Prev
      </Button>
      <Button
        onClick={onClickNext ?? (() => table.nextPage())}
        disabled={!hasPagination || (pageIndex === pageCount - 1 && !hasNext)}
        variant="outline"
        size="sm"
      >
        {"Next"}
        {isFetchingNextPage && <Spinner className="ml-1" size="sm" />}
      </Button>
    </>
  );
};
