import { useEffect, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/cn";

import { AssociatedInputItem } from "./AssociatedInputItem";
import { Droppable } from "./Droppable";
import { SortableItem } from "./SortableItem";

export type Output = {
  id: string;
  associatedInputs: {
    id: string;
    example: string;
  }[];
};

type Props = {
  onRemoveAssociatedInput: (outputId: string, inputId: string) => void;
  output: Output;
  isDragging: boolean;
};

export const OutputColumnItem = ({
  output,
  isDragging,
  onRemoveAssociatedInput,
}: Props) => {
  const initAssociatedInputs = output.associatedInputs.map((input) => input.id);

  useEffect(() => {
    setItems(initAssociatedInputs);
  }, [initAssociatedInputs.length]);

  const [items, setItems] = useState(initAssociatedInputs);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(String(active.id));
        const newIndex = items.indexOf(String(over?.id));

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <Droppable key={output.id} id={output.id} dragging={isDragging}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <Card className={cn("select-none px-4")}>
            <CardHeader>{output.id}</CardHeader>
            <Separator />
            <CardContent className="">
              {items.map((item, i) => {
                const input = output.associatedInputs.find(
                  (input) => input.id === item,
                );
                if (!input) return null;
                return (
                  <SortableItem id={input.id} key={i}>
                    <AssociatedInputItem
                      key={i}
                      input={input}
                      onRemove={() =>
                        onRemoveAssociatedInput(output.id, input.id)
                      }
                    />
                    <Separator />
                  </SortableItem>
                );
              })}
            </CardContent>
          </Card>
        </SortableContext>
      </DndContext>
    </Droppable>
  );
};
