import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Outlet } from "react-router-dom";

import { DashboardScreen } from "@/app/dashboard/DashboardScreen";
import { easeSnappy } from "@/lib/motion";

import { VendorSubmissionFlowTabularHeader } from "../../VendorSubmissionFlowTabularHeader";

export function VendorSubmissionLayout() {
  const [parent] = useAutoAnimate({
    duration: 150,
    easing: easeSnappy,
  });

  return (
    <DashboardScreen className="max-h-screen min-h-screen grid-rows-[auto_1fr]">
      <VendorSubmissionFlowTabularHeader />
      <div ref={parent}>
        <Outlet />
      </div>
    </DashboardScreen>
  );
}
