import { useMutation } from "@tanstack/react-query";

export type Field = {
  name: string;
  type:
    | "text"
    | "number"
    | "date"
    | "address"
    | "thing"
    | "collection"
    | "boolean"
    | "enum"
    | "string";
  description: string;
  required?: boolean;
  fields?: Field[];
  enum?: string[];
};

export type GenCustomFieldsResponse = {
  fields: Field[];
  usage: {
    input_tokens: number;
    output_tokens: number;
    num_pages: number;
    pre_process: false;
    resolution: string;
    price: number;
  };
};

export function useGenCustomFields() {
  return useMutation({
    mutationKey: ["genCustomFields"],
    mutationFn: (file: File) => {
      const formData = new FormData();
      formData.append("file", file); // Append the binary file
      formData.append("resolution", "HIGH");
      formData.append("pre_process", "false");

      const url = "https://documents.teachprotege.ai/infer-schema";
      return fetch(url, {
        headers: {
          authorization: `Bearer five-dollar-footlong`,
        },
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data: GenCustomFieldsResponse) => {
          // if fields are asd_Asd split them into asd Asd to make it more readable
          const fields = data.fields.map((field) => {
            const name = field.name.replace(/_/g, " ");
            const description = field.description.replace(/_/g, " ");
            if (field.type === "string") {
              field.type = "text";
            }
            return { ...field, name, description };
          });
          return { fields, usage: data.usage };
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });
}
