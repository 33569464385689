import { DateRange } from "react-day-picker";

import { graphql } from "@/gql/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  dateRange: DateRange;
};

export const useGetChartSubmissionsData = ({ dateRange }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetChartSubmissionsData"],
    query: graphql(`
      query GetChartSubmissionsData($where: SubmissionWhereInput) {
        submissions(where: $where) {
          id
          createdAt
        }
      }
    `),
    variables: {
      where: {
        createdAt: {
          // get all submissions created in the last week
          gt: dateRange.from?.toISOString(),
          lte: dateRange.to?.toISOString(),
        },
      },
    },
  });
};
