import { graphql } from "@/gql/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  orgId: number | undefined;
};

export const useGetTotalPartnersData = ({ orgId }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetTotalPartnersData", orgId],
    query: graphql(`
      query GetTotalPartnersData($where: OrganizationWhereUniqueInput!) {
        organization(where: $where) {
          _count {
            vendors
          }
        }
      }
    `),
    variables: { where: { id: orgId } },
  });
};
