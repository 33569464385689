import { graphql } from "@/gql/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  orgId: number;
};

export const useGetVendors = ({ orgId }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetVendors", orgId],
    query: graphql(`
      query useGetVendors($where: VendorWhereInput!) {
        vendors(where: $where) {
          id
          name
        }
      }
    `),
    variables: {
      where: {
        organizationId: {
          equals: orgId,
        },
      },
    },
  });
};
