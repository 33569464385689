import { graphql } from "@/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

const query = graphql(`
  query getProcessingSubmissionsCount($where: SubmissionTypeWhereInput) {
    aggregateSubmissionType(where: $where) {
      _count {
        id
      }
    }
  }
`);

export const NoResultsFoundTableCell = () => {
  const { data } = useGraphqlQuery({
    queryKey: ["getProcessingSubmissionsCount"],
    query,
    variables: {
      where: {
        type: {
          equals: "PROCESSING",
        },
      },
    },
  });

  const count = data?.aggregateSubmissionType._count?.id ?? 0;

  const text =
    count > 0
      ? `Processing ${count} submissions - check back later!`
      : "No submissions found";

  return <div>{text} </div>;
};
