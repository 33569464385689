import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Spinner } from "@/components/Spinner";

// a higher order component for wrapping any component with an error boundary and suspense

interface WithSuspenseAndErrorBoundaryProps<
  T extends React.ComponentType<any>,
> {
  Component: T;
  errorFallback?: React.ReactElement | null;
  suspenseFallback?: React.ReactElement | null;
}

export function WithSuspenseAndErrorBoundary<
  T extends React.ComponentType<any>,
>({
  Component,
  errorFallback,
  suspenseFallback,
}: WithSuspenseAndErrorBoundaryProps<T>): React.FC<
  React.ComponentPropsWithRef<T>
> {
  return function (props) {
    return (
      <ErrorBoundary
        fallback={errorFallback || <div>Something went wrong.</div>}
      >
        <Suspense fallback={suspenseFallback || <Spinner size="2xl" />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
}
