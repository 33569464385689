import { Field } from "../use-gen-custom-fields";

export const productCertificateFields: Field[] = [
  {
    name: "contractorCompany",
    type: "text",
    description: "The name of the contractor company.",
  },
  {
    name: "totalValue",
    type: "number",
    description: "The total monetary value of the certificate.",
  },
  {
    name: "dateIssued",
    type: "date",
    description: "The date the certificate was issued.",
  },
  {
    name: "contractorLoyaltyNumber",
    type: "text",
    description: "The loyalty number of the contractor.",
  },
  {
    name: "certificateNumber",
    type: "text",
    description: "The unique number identifying the certificate.",
  },
  {
    name: "dateOfExpiration",
    type: "date",
    description: "The expiration date of the certificate.",
  },
];
