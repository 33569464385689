// MemberRow.tsx
import React from "react";
import { Link } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { buttonVariants } from "@/components/ui/button";
import { OrgUsersTableQuery } from "@/gql/graphql";
import { cn } from "@/lib/cn";

interface MemberRowProps {
  user: OrgUsersTableQuery["users"][number];
}

export const MemberRow: React.FC<MemberRowProps> = ({ user }) => {
  return (
    <div className="flex items-start justify-between border-b px-6 py-4 last:border-b-0">
      <div className="flex gap-6">
        <div className="flex items-start">
          <div className="flex h-12 w-12 items-center justify-center rounded-full border border-border bg-muted text-xl font-medium">
            {user.firstName[0]}
          </div>
        </div>
        <div>
          <h2 className="text text-base font-semibold">
            {user.firstName} {user.lastName}
          </h2>

          <p className="mt-1 text-sm text-muted-foreground">{user.email}</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <p className="text-sm font-medium text-muted-foreground">
          {user.role === "ADMIN" ? "Admin" : "Member"}
        </p>
        <Link
          to={`${user.id}`}
          className={cn(
            buttonVariants({ variant: "outline" }),
            "h-10 w-10 rounded-full p-1 text-foreground/80",
          )}
        >
          <Icons.edit className="h-4" />
        </Link>
      </div>
    </div>
  );
};

export const MemberRowSkeleton: React.FC = () => {
  return (
    <div className="flex items-start justify-between border-b px-6 py-4 last:border-b-0">
      <div className="flex gap-6">
        <div className="flex items-start">
          <div className="flex h-12 w-12 items-center justify-center rounded-full border border-border bg-muted text-xl"></div>
        </div>
        <div>
          <div className="h-4 w-40 animate-pulse rounded-sm bg-border" />
          <div className="mt-1 h-4 w-32 animate-pulse rounded-sm bg-border" />
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="h-4 w-14 animate-pulse rounded-sm bg-border" />
      </div>
    </div>
  );
};
