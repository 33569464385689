import { graphql } from "@/gql";
import { Vendor } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";

export const getVendorSubmissionTypesQueryOptions = (
  vendorId: Vendor["id"],
) => {
  return createQueryOptions({
    query: graphql(`
      query GetVendorSubmissionTypes($where: SubmissionTypeWhereInput) {
        submissionTypes(where: $where) {
          id
          name
          description
        }
      }
    `),
    variables: {
      where: {
        vendors: {
          some: {
            id: {
              equals: vendorId,
            },
          },
        },
      },
    },
  });
};
