import { useEffect, useRef, useState } from "react";
import {
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useKeyPress } from "react-use";

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { cn } from "@/lib/cn";
import { CONFIDENCE_INTERVALS } from "@/lib/constants/confidenceIntervals";

import { PredictionContextMenuContents } from "./(context-menu)/PredictionContextMenuContents";
import { PredictionTableFilters } from "./filter/PredictionTableFilters";
import {
  PredictionColumnDef,
  PredictionRowType,
} from "./PredictionTableColumns";
import {
  PredictionsTableState,
  usePredictionsTableStore,
} from "./predictionTableStore";
import { useGetPredictionTableFilterOptions } from "./useGetPredictionTableFilterOptions";

export interface PredictionsTableProps
  extends React.HTMLAttributes<HTMLDivElement> {
  showFilterState: Record<string, boolean>;
  columnVisibility: VisibilityState;
  columns: PredictionColumnDef[];
  predictions: PredictionRowType[];
  onSubmitFeedback: PredictionsTableState["onSubmitFeedback"];
  onRowClick?: (row: PredictionRowType) => void;
}

const labelConfidenceFilter: FilterFn<PredictionRowType> = (
  row,
  columnId,
  value: [number, number],
) => {
  const rowValues = row.original;
  const confidence = rowValues.labels.find((label) => label.name === columnId)
    ?.confidence;
  if (confidence != null) {
    return confidence >= value[0] && confidence < value[1];
  }
  return false;
};

export function PredictionsTable({
  showFilterState,
  columns,
  predictions,
  columnVisibility,
  onRowClick,
  className,
  onSubmitFeedback,
}: PredictionsTableProps) {
  const [sorting, _setSorting] = useState<SortingState>();
  const [globalFilter, setGlobalFilter] = useState<[number, number] | void>([
    CONFIDENCE_INTERVALS.Low[0],
    CONFIDENCE_INTERVALS.Low[1],
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { setTable, setOnSubmitFeedback, setIsAltPressed } =
    usePredictionsTableStore();
  const table = useReactTable({
    data: predictions,
    columns,
    enableFilters: true,
    enableColumnFilters: true,
    manualFiltering: false,
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: labelConfidenceFilter,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
  });

  // @ts-ignore
  const predicate = (event) => {
    return event.key === "Alt";
  };
  const [isAltPressed] = useKeyPress(predicate);

  useEffect(() => {
    if (table) setTable(table);
  }, [table, setTable]);

  useEffect(() => {
    if (onSubmitFeedback) setOnSubmitFeedback(onSubmitFeedback);
  }, [onSubmitFeedback, setOnSubmitFeedback]);

  useEffect(() => {
    setIsAltPressed(isAltPressed);
  }, [isAltPressed, setIsAltPressed]);

  const rows = table.getRowModel().rows;
  const selectedRows = table.getSelectedRowModel().rows;
  const someRowsSelected = selectedRows.length > 0;

  const globalFilterOptions = useGetPredictionTableFilterOptions({
    table,
    predictions,
    setColumnFilters,
    setGlobalFilter,
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 56, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 20,
  });

  return (
    <div className={cn("grid grid-rows-[38px_1fr] gap-3", className)}>
      <PredictionTableFilters
        table={table}
        globalFilter={globalFilter}
        globalFilterOptions={globalFilterOptions}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
      />

      <div
        ref={tableContainerRef}
        id="TableContainer"
        className={cn(
          "rounded-sm border border-border",
          "relative h-[500px] overflow-auto", // NECESSARY for virtualizer; height is arbitrary, but must be fixed
        )}
      >
        <table
          id="Table"
          className={cn(
            "grid", // NECESSARY for virtualizer
          )}
        >
          <thead
            className={cn(
              "bg-muted shadow-sm ",
              "sticky top-0 z-10 grid", // NECESSARY for virtualizer
            )}
            id="TableHeader"
          >
            {/* this should be changed eventually to get header leaf nodes */}
            {table.getHeaderGroups().map((headerGroup, i) =>
              i === 1 ? (
                <tr
                  key={headerGroup.id}
                  className={cn(
                    "flex w-full", // NECESSARY for virtualizer
                  )}
                  id="TableHeaderRow"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        className={cn(
                          header.column.id === "Select" &&
                            "flex items-center justify-center p-2",
                          "truncate p-2 text-left text-sm font-medium text-card-foreground",
                          header.column.getCanSort() && "cursor-pointer",
                        )}
                        key={header.id}
                        id={`th-${header.id}`}
                        onClick={header.column.getToggleSortingHandler()}
                        style={{
                          width:
                            header.column.id === "Product Description"
                              ? "500px"
                              : `${header.column.getSize()}px`,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </th>
                    );
                  })}
                </tr>
              ) : null,
            )}
          </thead>
          <ContextMenu>
            <ContextMenuTrigger asChild>
              <tbody
                id="TableBody"
                className={cn(
                  "relative grid", // NECESSARY for virtualizer
                )}
                style={{
                  height: `${rowVirtualizer.getTotalSize()}px`, // NECESSARY for virtualizer
                }}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const row = rows[virtualRow.index];

                  return (
                    <tr
                      data-index={virtualRow.index}
                      ref={(node) => rowVirtualizer.measureElement(node)}
                      key={row.id}
                      id={`TableRow-${row.original.id}-${row.id}`}
                      onContextMenu={() => {
                        if (!someRowsSelected) row.toggleSelected(true);
                      }}
                      data-state={row.getIsSelected() && "selected"}
                      className={cn(
                        "border-b hover:bg-muted/30 data-[state=selected]:bg-muted/60",
                        "absolute flex w-full", // NECESSARY for virtualizer
                      )}
                      style={{
                        transform: `translateY(${virtualRow.start}px)`, // NECESSARY for virtualizer
                      }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const isProductDescription =
                          cell.column.id === "Product Description";

                        return (
                          <td
                            key={cell.id}
                            id={`TableCell-${cell.id}`}
                            className={cn(
                              "border-r p-0",
                              "flex", // NECESSARY for virtualizer)
                            )}
                            style={{
                              width: isProductDescription
                                ? "500px"
                                : `${cell.column.getSize()}px`,
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {/* {rows.length > 0 ? (
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )} */}
              </tbody>
            </ContextMenuTrigger>
            <ContextMenuContent>
              <PredictionContextMenuContents />
            </ContextMenuContent>
          </ContextMenu>
        </table>
      </div>
    </div>
  );
}
