import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Card, CardContent } from "@/components/ui/card";

import { DatabaseExportDialog } from "./DatabaseExportDialog";
import {
  ExportDatabaseRow,
  ExportDatabaseRowSkeleton,
} from "./ExportDatabaseRow";
import { getOrgExportSettingsQueryOptions } from "./getOrgExportSettings";

export const ExportDatabaseList = () => {
  const { organizationId } = useParams();

  const [parent] = useAutoAnimate();

  const queryOpts = getOrgExportSettingsQueryOptions(organizationId as string);

  const { data, isLoading } = useQuery(queryOpts);

  const exportDatabases = data?.organization?.exportDatabases ?? [];

  return (
    <div className="max-w-3xl">
      <div className="mb-4 flex items-center justify-between">
        <h2 className=" flex items-center text-2xl font-semibold">
          <div className="mr-4 rounded-full border border-border p-3">
            <Icons.submission />
          </div>
          Database Exports
        </h2>
        <DatabaseExportDialog />
      </div>
      <div className="max-w-3xl space-y-4" ref={parent}>
        {isLoading ? (
          <>
            <ExportDatabaseRowSkeleton key="loader-1" />
            <ExportDatabaseRowSkeleton key="loader-2" />
          </>
        ) : data && exportDatabases.length > 0 ? (
          exportDatabases.map((db, i) => (
            <ExportDatabaseRow key={`${db.id}`} exportDB={db} />
          ))
        ) : (
          <Card className="text-md max-w-3xl font-medium text-muted-foreground">
            <CardContent className="flex justify-center pt-6">
              No Export Databases found
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};
