import { Icons } from "@/components/Icons";

import { DashHomePage } from "./home/DashHomePage";
import { SettingsLayout } from "./settings/SettingsLayout";
import { settingsRoutes } from "./settings/settingsRoutes";
import { SubmissionCorrectionsView } from "./submissions/:submissionId/(predictions)/SubmissionCorrectionsView";
import { SubmissionCorrectionsLayout } from "./submissions/:submissionId/corrections/SubmissionCorrectionsLayout";
import { SubmissionLayout } from "./submissions/:submissionId/SubmissionLayout";
import { SubmissionsPage } from "./submissions/SubmissionsPage";
// import TrainingPage from "./training/TrainingPage";
import { VendorsSelection } from "./vendors/general/VendorsSelection";
import { SubmissionMappingPage } from "./vendors/summary/submission/[submissionTypeId]/SubmissionMappingPage";
import { VendorSummary } from "./vendors/summary/VendorSummary";
import { VendorLayout } from "./vendors/VendorLayout";
import { VendorsPage } from "./vendors/VendorsPage";

export const dashboardRoutes = [
  {
    id: "Home",
    path: "",
    element: <DashHomePage />,
    Icon: Icons.home,
    showInNav: true,
  },
  {
    id: "Submissions",
    path: "submissions",
    element: <SubmissionsPage />,
    Icon: Icons.submission,
    showInNav: true,
  },
  {
    id: "Submission",
    path: "submissions/:submissionId",
    element: <SubmissionLayout />,
    children: [
      {
        id: "Submission Corrections",
        path: "corrections",
        end: true,
        element: <SubmissionCorrectionsLayout />,
        children: [
          {
            id: "Transformation Predictions Table",
            path: "transformations/:transformationId",
            element: <SubmissionCorrectionsView />,
          },
        ],
      },
      {
        id: "Submission Analytics",
        path: "analytics",
        element: <div>Analytics</div>,
      },
    ],
  },
  {
    id: "Partners",
    path: "partner",
    element: <VendorsPage />,
    Icon: Icons.users,
    showInNav: true,
    children: [
      {
        id: "Partner Selection",
        path: "",
        element: <VendorsSelection />,
      },
      {
        id: "Partner",
        path: ":vendorId",
        element: <VendorLayout />,
        Icon: Icons.user,
        children: [
          {
            id: "Partner Summary",
            path: "",
            element: <VendorSummary />,
          },
          {
            id: "Partner Mapping",
            path: "mapping/:submissionType",
            element: <SubmissionMappingPage />,
          },
        ],
      },
    ],
  },
  // {
  //   id: "Training",
  //   path: "training",
  //   element: <TrainingPage />,
  //   Icon: Icons.ai,
  //   showInNav: true,
  // },
  {
    id: "Settings",
    path: "settings",
    element: <SettingsLayout />,
    Icon: Icons.settings,
    showInNav: true,
    children: settingsRoutes,
  },
];
