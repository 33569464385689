import { useEffect, useRef, useState } from "react";
import { Cell, Pie, PieChart } from "recharts";

import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = [
  "#3B82F6", // Blue9 from Radix, suitable for dark mode
  "#22D3EE", // Cyan9 from Radix, can serve as a vibrant greenish-blue
  "#FACC15", // Yellow9 from Radix, a bright yellow
  "#FB7185", // Red9 from Radix, a vivid red
];
export const SubmissionChartCard = () => {
  return (
    <Card className="col-span-1 row-span-1 flex flex-col p-2">
      <Tabs defaultValue="CPU" className="flex w-full  flex-col">
        <TabsList className="w-fit self-end ">
          <TabsTrigger value={"CPU"}>CPU</TabsTrigger>
          <TabsTrigger value="GPU">GPU</TabsTrigger>
        </TabsList>
        <TabsContent value={"CPU"} className="">
          <ResponsivePieChart />
        </TabsContent>
        <TabsContent value={"GPU"}>
          <ResponsivePieChart />
        </TabsContent>
      </Tabs>
    </Card>
  );
};

function ResponsivePieChart() {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 300, height: 300 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].target) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
      resizeObserver.disconnect();
    };
  }, [ref.current]); // Dependency on the ref's current value

  return (
    <div ref={ref} className="flex h-full w-full items-center justify-center">
      <PieChart width={dimensions.width} height={dimensions.height}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={true}
          label={true}
          outerRadius={Math.min(dimensions.width, dimensions.height) / 3}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}
