import { createContext, useContext, useRef } from "react";
import { createStore, StoreApi, useStore } from "zustand";

type VendorTableState = {
  vendorName: string;
};

type VendorTableActions = {
  setVendorName: (vendorName: string) => void;
};

const StoreContext = createContext(
  {} as StoreApi<VendorTableState & VendorTableActions>,
);

export const VendorTableStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const storeRef = useRef<StoreApi<VendorTableState & VendorTableActions>>();
  if (!storeRef.current) {
    storeRef.current = createStore<VendorTableState & VendorTableActions>(
      (set) => ({
        vendorName: "",
        setVendorName: (vendorName) => set({ vendorName }),
      }),
    );
  }
  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );
};

export const useVendorTableStoreInContext = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error("Missing StoreProvider");
  }
  return useStore(store, (state) => state);
};
