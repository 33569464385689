import "../../../../ag-grid-theme-custom.css";
import "../../../../cell-error.css"; // Custom CSS for cell error styling

// import "@ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
// import "@ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import { useMemo, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component

import { useParams } from "react-router-dom";

import { doesExternalFilterPass } from "./doesExternalFilterPass";
import { getTransformationPredictionsTableHeadersAndRows } from "./getTransformationPredictionsTableHeadersAndRows";
import { getTransformationPredictionsTableQueryOptions } from "./getTransformationPredictionsTableQueryOptions";
import {
  ExternalFilter,
  TransformationPredictionsTableFilters,
} from "./TransformationPredictionsTableFilters";
import {
  Label,
  useGetTransformationsPredictionsTableColDefs,
} from "./useGetTransformationsPredictionsTableColDefs";
import { useSubmitFeedback } from "./useSubmitFeedback";

export const TransformationPredictionsTable2 = () => {
  const { transformationId: tId } = useParams();
  const transformationId = Number(tId);
  const options = useMemo(
    () => getTransformationPredictionsTableQueryOptions(transformationId),
    [transformationId],
  );
  const { mutate } = useSubmitFeedback({ queryKey: options.queryKey });
  const { data: predictionRowData } = useSuspenseQuery(options);
  const { headers, rows } = getTransformationPredictionsTableHeadersAndRows({
    predictionRowData,
  });
  const [gridApi, setGridApi] = useState<AgGridReact["api"]>();
  const [externalFilters, setExternalFilters] = useState<ExternalFilter[]>([]);

  const colDefs = useGetTransformationsPredictionsTableColDefs({
    headers,
  });

  const defaultColDef = useMemo(
    () => ({
      filter: true, // Enable filtering on all columns
      editable: true, // Enable editing on all columns
      resizable: true,
      flex: 0.7,
    }),
    [],
  );

  // submit feedback here
  const onCellValueChanged = (event: any) => {
    const { value, data, colDef } = event;

    // the only columns that can be edited are the labels columns, check that the column is a label column
    if (colDef.cellDataType === "label") {
      const { colId } = colDef;
      const label = data[colId] as Label;
      mutate({
        data: [
          {
            labelId: label.id,
            content: value,
            upvote: true,
          },
        ],
      });
    }
  };

  return (
    <>
      <TransformationPredictionsTableFilters
        queryKey={options.queryKey}
        gridApi={gridApi}
        rows={rows}
        setExternalFilters={setExternalFilters}
      />
      <div
        id="transformation-table"
        className="ag-theme-custom cell-error h-[600px]"
      >
        <AgGridReact
          gridOptions={{
            // @ts-ignore type error comes from the Params in valueGetter and not using ag-grid-community types so far
            columnDefs: colDefs,
            defaultColDef,
            dataTypeDefinitions: {
              label: {
                // simple UPPER CASE formatter example
                // { valueFormatter: p => params.value.toUpperCase() }
                baseDataType: "object",
                extendsDataType: "object",
                dataTypeMatcher: (value) => {
                  return typeof value === "object" && value !== null;
                },
              },
            },
          }}
          // debug={true}
          onGridReady={(params) => {
            setGridApi(params.api);
          }}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
          pagination={true}
          // defaultColDef={defaultColDef}
          rowData={rows}
          // columnDefs={colDefs}
          reactiveCustomComponents={true}
          isExternalFilterPresent={() => externalFilters.length > 0}
          doesExternalFilterPass={(node) =>
            doesExternalFilterPass(node, externalFilters)
          }
        />
      </div>
    </>
  );
};
