import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useUpdateSubmission = () => {
  return useGraphqlMutation({
    mutationKey: ["updateSubmission"],
    mutation: graphql(`
      mutation UpdateSubmission(
        $data: SubmissionUpdateInput!
        $where: SubmissionWhereUniqueInput!
      ) {
        updateOneSubmission(data: $data, where: $where) {
          id
          type {
            name
            id
          }
        }
      }
    `),
  });
};
