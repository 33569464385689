import { forwardRef } from "react";

import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/cn";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  scrollable?: boolean;
}

const DashboardScreen = forwardRef(function (
  { children, className, scrollable, ...props }: Props,
  ref: React.Ref<HTMLDivElement>,
) {
  const internals = (
    <div ref={ref} className={cn("px-7 py-6", className)} {...props}>
      {children}
    </div>
  );

  return scrollable ? <ScrollArea>{internals}</ScrollArea> : internals;
});

export { DashboardScreen };
