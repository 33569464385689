import { useCallback, useEffect, useMemo, useState } from "react";
import { VisibilityState } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import { WithSuspenseAndErrorBoundary } from "@/components/WithSuspenseAndErrorBoundary";
import { SubmissionStatus } from "@/gql/graphql";

import SubmissionListColumns, {
  SubmissionRowType,
} from "./SubmissionListColumns";
import {
  SubmissionsListStoreProvider,
  useSubmissionsListStoreInContext,
} from "./SubmissionsListContext";
import { SubmissionsListTableSkeleton } from "./SubmissionsListSkeleton";
import { SubmissionsListTable } from "./SubmissionsListTable";
import { useGetSubmissions } from "./useGetSubmissions";

interface ListProps extends React.HTMLAttributes<HTMLDivElement> {
  tableTitle?: string;
  colsShown: VisibilityState;
  maxHeight?: string;
  maxRows?: number;
  initVendorId?: string;
  submissionStatus?: SubmissionStatus;
  showCreateSubmissionButton?: boolean;
  onRowClick?: (submission: SubmissionRowType) => void;
}

export const SubmissionsList = ({
  initVendorId,
  submissionStatus,
  ...props
}: ListProps) => {
  return (
    <SubmissionsListStoreProvider
      initValues={{ vendorId: initVendorId, status: submissionStatus }}
    >
      <List {...props} />
    </SubmissionsListStoreProvider>
  );
};

const List = WithSuspenseAndErrorBoundary({
  Component: ({
    colsShown,
    tableTitle,
    className,
    maxHeight,
    maxRows,
    showCreateSubmissionButton,
    onRowClick,
  }: ListProps) => {
    const [pageIndex, setPageIndex] = useState(0);
    const navigate = useNavigate();

    const { vendorId, vendorName, dateRange, status, submissionType } =
      useSubmissionsListStoreInContext();

    const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
      useGetSubmissions({
        vendorId,
        vendorName,
        dateRange,
        status: status ? [status] : undefined,
        submissionType,
      });

    useEffect(() => {
      refetch();
    }, [vendorId, vendorName, dateRange, status, submissionType]);

    const submissionData = useMemo(() => {
      return data?.pages?.flatMap((page) => page.submissions) ?? [];
    }, [data]);

    const loadMore = useCallback(() => {
      if (isFetching && !hasNextPage) return;
      fetchNextPage();
    }, [fetchNextPage, isFetching, hasNextPage]);

    const onSubmissionClick = (submission: SubmissionRowType) => {
      const { id } = submission;
      navigate(`/dashboard/submissions/${id}`);
    };

    // start all columns as hidden
    const hiddenCols = SubmissionListColumns.reduce((acc, col) => {
      const header = col.header as string;
      // @ts-ignore
      acc[header] = false;
      return acc;
    }, {});

    const colsVisible = {
      ...hiddenCols,
      ...colsShown,
    };

    if (data === undefined) {
      return (
        <SubmissionsListTableSkeleton
          maxHeight={maxHeight}
          tableTitle={tableTitle ?? "Submissions"}
          columnsCount={3}
        />
      );
    }

    return (
      <SubmissionsListTable
        showCreateSubmissionButton={showCreateSubmissionButton}
        maxRows={maxRows}
        maxHeight={maxHeight}
        tableTitle={tableTitle ?? "Submissions"}
        pageIndexOverride={pageIndex}
        setPageIndexOverride={setPageIndex}
        loadNext={loadMore}
        hasNext={hasNextPage}
        onRowClick={onRowClick ?? onSubmissionClick}
        columns={SubmissionListColumns}
        data={submissionData}
        colsVisible={colsVisible}
        isFetchingNextPage={false}
        className={className}
      />
    );
  },
});
