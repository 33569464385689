import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const usePrepareFileUploadMutation = () => {
  return useGraphqlMutation({
    mutationKey: ["prepareFileUpload"],
    mutation: graphql(`
      mutation prepareFileUploadMutation($fileName: String!) {
        prepareFileUpload(fileName: $fileName) {
          bucket
          key
          region
          signedUrl
        }
      }
    `),
  });
};
