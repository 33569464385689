import { useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";

import { Card, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SubmissionStatus } from "@/gql/graphql.ts";
import { cn } from "@/lib/cn";
import { fuzzyFilter } from "@/lib/fuzzyFilter.ts";

import { useGetSubmissionListHeaderFilters } from "./filter-popover/useGetSubmissionListHeaderFilters.tsx";
import { NoResultsFoundTableCell } from "./NoResultsFoundTableCell.tsx";
import type { SubmissionRowType } from "./SubmissionListColumns.tsx";
import { SubmissionListHeader } from "./SubmissionListHeader.tsx";

interface SubmissionTableProps extends React.HTMLAttributes<HTMLDivElement> {
  tableTitle: string;
  colsVisible: VisibilityState;
  columns: ColumnDef<SubmissionRowType>[];
  data: SubmissionRowType[];
  onRowClick: (submission: SubmissionRowType) => void;
  loadNext: () => void;
  hasNext: boolean;
  setPageIndexOverride: (arg0: number) => void;
  pageIndexOverride: number;
  isFetchingNextPage: boolean;
  maxHeight?: string;
  maxRows?: number;
  showCreateSubmissionButton?: boolean;
}

export const newerThan: FilterFn<number> = (row, columnId, value) => {
  const timeInMS = new Date(row.getValue(columnId)).getTime();
  return timeInMS > value;
};

export function SubmissionsListTable({
  tableTitle,
  columns,
  data,
  onRowClick,
  loadNext,
  colsVisible,
  hasNext,
  setPageIndexOverride,
  pageIndexOverride,
  isFetchingNextPage,
  maxHeight,
  maxRows,
  className,
  showCreateSubmissionButton,
}: SubmissionTableProps) {
  const [columnSorts, setColumnSorts] = useState<SortingState>([]);

  const [columnVisibility] = useState<VisibilityState>(colsVisible);
  const [columnFilters, _setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: maxRows ?? 10,
  });
  const [globalFilter, setGlobalFilter] = useState<string>("");

  useEffect(() => {
    setPagination({
      pageIndex: pageIndexOverride,
      pageSize: maxRows ?? 10,
    });
  }, [pageIndexOverride]);

  const paginationOverride = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );
  const table = useReactTable({
    data,
    columns,
    onSortingChange: setColumnSorts,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    enableFilters: true,
    enableColumnFilters: true,
    manualFiltering: false,
    globalFilterFn: fuzzyFilter,
    state: {
      pagination: paginationOverride,
      globalFilter,
      sorting: columnSorts,
      columnVisibility,
      columnFilters,
    },
    filterFns: {
      newerThan,
    },
  });

  const onClickNextButton = () => {
    setPageIndexOverride(pageIndexOverride + 1);
    // hack for now. the commented out condition was never being reached
    loadNext();

    // if (
    //   table.getState().pagination.pageIndex + 1 ===
    //   table.getPageCount() - 2
    // ) {
    //   debugger;
    // }
  };

  const onClickPrevButton = () => {
    setPageIndexOverride(pageIndexOverride - 1);
  };

  const { globalFilterOptions, sortOptions, columnFilterOptions } =
    useGetSubmissionListHeaderFilters();
  return (
    <Card
      className={cn(className, "flex h-full flex-col")}
      style={{
        height: maxHeight,
        maxHeight: maxHeight,
      }}
    >
      <SubmissionListHeader
        tableTitle={tableTitle}
        table={table}
        onClickNext={onClickNextButton}
        onClickPrev={onClickPrevButton}
        hasNext={hasNext}
        isFetchingNextPage={isFetchingNextPage}
        sortOptions={sortOptions}
        globalFilterOptions={globalFilterOptions}
        columnFilterOptions={columnFilterOptions}
        showCreateSubmissionButton={showCreateSubmissionButton}
      />
      <CardContent className="mx-6 mb-6 flex flex-grow flex-col overflow-auto rounded-lg p-0 scrollbar-hide">
        <Table className="scrollbar-hide">
          <TableHeader className="sticky top-0 shadow-sm">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="rounded-lg">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className={cn(
                        header.column.getCanSort() ? "cursor-pointer" : "",
                      )}
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="overflow-auto scrollbar-hide">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                const status = row.original.status as SubmissionStatus;
                const isDisabled =
                  status !== SubmissionStatus.Processed &&
                  status !== SubmissionStatus.Failed &&
                  status !== SubmissionStatus.Delivered;

                return (
                  <TableRow
                    key={row.id}
                    aria-disabled={isDisabled}
                    onClick={() => {
                      if (isDisabled) return;
                      const submission = row.original;
                      onRowClick(submission);
                    }}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn(
                      isDisabled ? "cursor-auto" : "cursor-pointer",
                      "last:border-b-0",
                    )}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell key={cell.id} className="h-16">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow className="cursor-pointer">
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <NoResultsFoundTableCell />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
