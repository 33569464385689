import { useState } from "react";
import { useDebounce } from "react-use";

export const useDebouncedState = <T extends unknown>(
  initialValue: T,
  delay: number = 500,
): [T, (value: T) => void] => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delay,
    [value],
  );

  return [debouncedValue, setValue];
};
