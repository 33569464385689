import { useInfiniteQuery } from "@tanstack/react-query";
import { DateRange } from "react-day-picker";

import { graphql } from "@/gql";
import { QueryMode, SortOrder, SubmissionStatus } from "@/gql/graphql";
import { getGraphqlClient } from "@/lib/hooks/graphql";

const query = graphql(`
  query GetSubmissionsInfinite(
    $where: SubmissionWhereInput
    $take: Int
    $cursor: SubmissionWhereUniqueInput
    $orderBy: [SubmissionOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    submissions(
      where: $where
      take: $take
      cursor: $cursor
      orderBy: $orderBy
    ) {
      id
      createdAt
      metadata {
        value
        key
      }
      type {
        name
        type
      }
      file {
        id
        name
      }
      outputFile {
        id
        name
      }
      vendor {
        id
        name
      }
      transformations {
        id
      }
      validations {
        id
      }
      status
      validationStatus
    }
  }
`);

type Params = {
  vendorId?: string;
  vendorName?: string;
  dateRange?: DateRange;
  status?: SubmissionStatus[];
  submissionType?: string;
};

export const useGetSubmissions = ({
  vendorId,
  vendorName,
  dateRange,
  status,
  submissionType,
}: Params) => {
  const client = getGraphqlClient();

  return useInfiniteQuery({
    queryKey: ["GetSubmissionsInfinite"],
    queryFn: async (data) => {
      const lastSubmissionId = data.pageParam + 1;

      const cursorObj =
        data.pageParam !== 0
          ? {
              id: lastSubmissionId,
            }
          : undefined;

      const variables = {
        where: {
          createdAt: dateRange
            ? { gte: dateRange.from, lte: dateRange.to }
            : undefined,
          status: status ? { in: status } : undefined,
          vendorId: vendorId ? { equals: Number(vendorId) } : undefined,
          vendor: vendorName
            ? {
                is: {
                  name: {
                    contains: vendorName,
                    mode: QueryMode.Insensitive,
                  },
                },
              }
            : undefined,
          type: submissionType
            ? {
                is: {
                  type: {
                    equals: submissionType,
                  },
                },
              }
            : undefined,
        },
        take: 20,
        cursor: cursorObj,
        orderBy: [
          {
            createdAt: SortOrder.Desc,
          },
        ],
      };

      const submissionData = await client.request(query, variables);
      return submissionData;
    },
    initialPageParam: 0,
    getNextPageParam: (currentQuery, allQueriedData) => {
      const submissions = allQueriedData.flatMap((page) => page.submissions);
      if (submissions.length === 0) {
        return undefined;
      }
      return submissions[submissions.length - 1].id;
    },
  });
};
