import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { zodToJsonSchema } from "zod-to-json-schema";

import { Button } from "@/components/ui/button";

import { EditFieldModal } from "./(stuff)/edit-field-modal";
import FieldList from "./(stuff)/field-list";
import { generateZodSchemaFromFields } from "./(stuff)/gen-zod-schema-from-fields";
import { useDocumentExtractStore } from "./document-extract-store";
import { MultiStepLoader } from "./loading/layout";
import { useDocumentExtract } from "./use-document-extract-mutation";
import { Field } from "./use-gen-custom-fields";

export const CreateCustomSchemaPage = () => {
  const [searchParams] = useSearchParams();
  const schemaType = searchParams.get("type");
  const navigate = useNavigate();
  const { recommendedFields, setResults, uploadedFile } =
    useDocumentExtractStore();
  const {
    mutate: documentExtract,
    isPending,
    isError,
    error,
  } = useDocumentExtract();
  const aiGenFields = recommendedFields.slice(0, 5);

  const [fields, setFields] = useState<Field[]>(aiGenFields);

  if (!uploadedFile) {
    navigate(`/document-extract`);
  }

  const handleSaveField = (
    updatedField: Field,
    mode: "edit" | "add",
    editingField?: Field,
  ) => {
    if (mode === "edit") {
      setFields((prevFields) =>
        prevFields.map((f) =>
          f.name === editingField?.name ? updatedField : f,
        ),
      );
    } else {
      setFields((prevFields) => [...prevFields, updatedField]);
    }
  };

  const onRemoveField = (fieldToRemove: Field) => {
    const removeFieldRecursive = (fields: Field[], field: Field): Field[] => {
      return fields
        .filter((f) => f.name !== field.name)
        .map((f) => ({
          ...f,
          fields: f.fields ? removeFieldRecursive(f.fields, field) : undefined,
        }));
    };

    setFields((prevFields) => removeFieldRecursive(prevFields, fieldToRemove));
  };

  const extract = () => {
    if (!uploadedFile) return;
    const zodSchema = generateZodSchemaFromFields(fields);
    const jsonSchema = JSON.stringify(zodToJsonSchema(zodSchema));

    documentExtract(
      { file: uploadedFile, jsonSchema },
      {
        onSuccess: (response) => {
          if (response) {
            setResults(response);
            navigate(`../results?type=${schemaType}`);
          }
        },
        onError: (error) => {
          console.error("Error:", error);
        },
      },
    );
  };

  if (isPending) {
    return <MultiStepLoader loading={isPending} />;
  } else if (isError && error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="mx-auto max-w-prose">
      <div className="flex flex-col items-center gap-1 sm:gap-2">
        <h2 className="text-xl font-bold text-muted-foreground sm:text-2xl">
          Step 2:
        </h2>
        <h1 className="text-center text-3xl font-bold tracking-normal sm:text-5xl">
          Define Extraction Fields
        </h1>
      </div>

      <article className="space-y-10 pt-8 sm:pt-16">
        <section>
          <FieldList
            onRemoveField={onRemoveField}
            fields={fields}
            handleSaveField={handleSaveField}
          />
        </section>
        <section className="flex gap-4">
          <EditFieldModal onSave={handleSaveField} mode="add" />
          <Button onClick={extract} className="w-full">
            Extract!
          </Button>
        </section>
      </article>
    </div>
  );
};
