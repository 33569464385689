import { cn } from "@/lib/cn";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("animate-pulse rounded-md bg-muted", className)}
      {...props}
    />
  );
}

function SkeletonText({ className, ...props }: Props) {
  return <Skeleton className={cn("w-full", className)} {...props}></Skeleton>;
}

export { Skeleton, SkeletonText };
