import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Link, Outlet } from "react-router-dom";

import { easeSnappy } from "@/lib/motion";

import { DocumentExtractFooter } from "./footer";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const DocumentExtractLayout = ({ className, ...props }: Props) => {
  const [parent] = useAutoAnimate({
    easing: easeSnappy,
    duration: 150,
  });

  return (
    <div
      ref={parent}
      {...props}
      className="grid grid-rows-[auto_1fr_auto] overflow-hidden"
      style={{ height: "100dvh" }}
    >
      <nav
        className="flex w-full items-center justify-between px-4 py-3 sm:py-4 lg:px-8"
        aria-label="Global"
      >
        <Link
          to={"/document-extract"}
          className="-m-1.5 flex items-center p-1.5"
        >
          <img
            className="h-6 w-auto sm:h-7"
            src={"/icon-dark-mode.svg"}
            alt="Company Logo"
          />
          <div className="ml-2 text-lg sm:text-xl">Data Steward AI</div>
        </Link>
      </nav>

      <Outlet />

      <DocumentExtractFooter className="px-4 py-2 sm:py-3 lg:px-8" />

      {/* GRADIENT BLOBS */}
      <>
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-30rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-45rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#79cbfe] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </>
    </div>
  );
};
